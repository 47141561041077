import React, { Fragment, useState, useEffect } from "react";
import StockWrapper from "./StockWrapper";
import Currencies from "../../Utils/Currencies.json";
import SendPurchaseOrderPdf from "./SendPurchaseOrderPdf";
import SendPoPdf from "./SendPoPdf";
import { connect, shallowEqual, useSelector } from "react-redux";
import PrintIcon from "@mui/icons-material/Print";
import EmailIcon from "@mui/icons-material/Email";
import { SecondaryButton } from "../../Utils/ButtonHelper";
import { DateRange } from "react-date-range";
import {
  PDFDownloadLink,
  View,
  Document,
  Page,
  Image,
  StyleSheet,
  Text,
  pdf,
} from "@react-pdf/renderer";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Modal,
  CircularProgress,
  ClickAwayListener,
  Autocomplete,
} from "@mui/material";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import {
  handleSpringDataRequest,
  handleSpringDataMutationRequest,
} from "../../api/index";
import Drawer from "@mui/material/Drawer";
import * as AiIcons from "react-icons/ai";
import moment from "moment";
import { toast } from "material-react-toastify";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import "material-react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";

const styling = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  paddingTop: "10px",
  paddingBottom: "10px",
};

const generateBase64 = async (hotel, selectedSupplier, filteredData) => {
  // const blob = new Blob([doc], { type: "application/pdf" });
  const pdfBlob = await pdf(
    <SendPoPdf
      hotel={hotel}
      supplier={selectedSupplier}
      filteredPOData={filteredData}
    />
  ).toBlob();
  const reader = new FileReader();
  reader.readAsDataURL(pdfBlob);
  reader.onloadend = () => {
    const base64data = reader.result.split(",")[1];
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/purchase-order/send-invoice-email`,
      {
        base64: base64data,
        supplierId: selectedSupplier.supplier_id.toString(),
        invoiceNo: filteredData[0]?.invoice_number,
        logo: hotel?.hotelLogoUrl,
      }
    )
      .then((res) => toast.success("Email Sent Successfully"))
      .catch((err) => console.log(err));
  };
};

const MyComponent = (hotel, selectedSupplier, filteredData) => {
  return (
    <div>
      <PDFDownloadLink
        document={
          <Document>
            <Page size="A4">
              <SendPurchaseOrderPdf
                hotel={hotel}
                supplier={selectedSupplier}
                filteredPOData={filteredData}
              />
            </Page>
          </Document>
        }
        fileName="myDocument.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            console.log("error : ", error)
          ) : (
            <a
              href={url}
              rel={"noreferrer"}
              target="_blank"
              // download={`Folio_${bookingId}`}
            >
              <Tooltip title="Preview pdf">
                <Typography className="editIcon">
                  <VisibilityIcon />
                </Typography>
              </Tooltip>
            </a>
          )
        }
      </PDFDownloadLink>
      {/* <button onClick={() => generateBase64()}>Generate Base64</button> */}
    </div>
  );
};

function PurchaseOrder() {
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { SearchBar } = Search;
  // Check if the operation we are performing is EDIT or DELETE
  const [actionType, setActionType] = useState("create");
  const [supplier, setsupplier] = useState("");
  const [deliveryPercentage, setdeliveryPercentage] = useState(0);
  const [taxNumber, settaxNumber] = useState("");
  const [taxPercentage, settaxPercentage] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentMode, setpaymentMode] = useState("CASH");
  const [isDisabled, setisDisabled] = useState(false);
  const [filteredData, setfilteredData] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const columns = [
    {
      dataField: "id",
      text: `${t("ID")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "hotel_id",
      text: `${t("Hotel Id")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "shop_id",
      text: `${t("Shop ID")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "warehouse_rm_id",
      text: `${t("Warehouse RM ID")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "po_from",
      text: `${t("PO From")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "supplier_id",
      text: `${t("Supplier Id")}`,
      sort: true,
      hidden: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "invoice_date",
      text: `${t("Invoice Date")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "invoice_number",
      text: `${t("Request Number")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "gst_number",
      text: `${t("Tax Number")}`,
      sort: true,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "120px",
        fontSize: "10px",
      },
    },
    {
      dataField: "delivery_charge_included_in_invoice",
      text: `${t("Delivery charge included in invoice")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      formatter: (cell, row) => {
        return parseFloat(cell).toFixed(2);
      },
    },
    {
      dataField: "tax_percentage",
      text: `${t("Tax Percentage")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "discount_type",
      text: `${t("Discount Type")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "discount_value",
      text: `${t("Discount Value")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "payment_type",
      text: `${t("Payment Type")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "rm_name",
      text: `${t("Name")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "rm_quantity",
      text: `${t("Quantity")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "rm_unit",
      text: `${t("Unit")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "rm_price",
      text: `${t("Price")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "rm_amount",
      text: `${t("Base Amount")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "rm_tax",
      text: `${t("Tax")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "total_amount",
      text: `${t("Total Amount")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "description",
      text: `${t("Description")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "status",
      text: `${t("Status")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      isDummyField: true,
      text: `${t("Actions")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => (
        <Stack direction="row" spacing={2}>
          <Tooltip title={t("Edit item")}>
            <Typography
              className="editIcon"
              onClick={() => {
                setdeliveryPercentage(
                  parseFloat(row.delivery_charge_included_in_invoice).toFixed(2)
                );
                setPaymentType(row.payment_type);
                setActionType("update");
                setModifiedPurchaseOrderPayload([row]);
                toggleDrawer(true);
              }}
            >
              <AiIcons.AiFillEdit size="18px" />
            </Typography>
          </Tooltip>
          <Tooltip title={t("Send email")}>
            <Typography
              className="editIcon"
              onClick={() =>
                generateBase64(
                  hotel,
                  selectedSupplier,
                  purchaseOrderData?.filter(
                    (item) => item?.invoice_number === row?.invoice_number
                  )
                )
              }
            >
              <EmailIcon />
            </Typography>
          </Tooltip>
          <div>
            {MyComponent(
              hotel,
              selectedSupplier,
              purchaseOrderData?.filter(
                (item) => item?.invoice_number === row?.invoice_number
              )
            )}
          </div>

          {/* <Tooltip title="Delete item">
            <Typography
              className="deleteIcon"
              onClick={() => {
                setModifiedPurchaseOrderPayload([row]);
                handleOpenConfirmDeleteModal();
              }}
            >
              <AiIcons.AiFillDelete size="18px" />
            </Typography>
          </Tooltip> */}
        </Stack>
      ),
      editable: false,
    },
  ];

  // Get the suppliers
  const [suppliers, setSuppliers] = useState([]);
  function handleGetAllSuppliers() {
    handleSpringDataRequest("core/api/v1/rm-supplier/get-all")
      .then((res) => {
        setSuppliers(res);
        setsupplier(res[0]?.supplier_id);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetAllSuppliers();
  }, []);

  // warehouse rm
  const [purchaseOrderData, setPurchaseOrderData] = useState(null);
  const [copyPurchaseOrderData, setcopyPurchaseOrderData] = useState(null);
  const [invoiceIds, setinvoiceIds] = useState([]);
  const [selectedInvocieIds, setselectedInvocieIds] = useState("");
  function handleGetPurchaseOrder() {
    setPurchaseOrderData(null);
    handleSpringDataRequest(
      `core/api/v1/purchase-order/get-all-for-supplier?supplierId=${supplier}&start=${moment(
        dateRange[0].startDate
      ).format("YYYY-MM-DD 00:00:00")}&end=${moment(
        dateRange[0].endDate
      ).format("YYYY-MM-DD 23:59:59")}`
    )
      .then((res) => {
        setPurchaseOrderData(res);
        setcopyPurchaseOrderData(res);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetPurchaseOrder();
  }, [supplier]);

  function handleGetPurchaseOrderDatas() {
    setPurchaseOrderData(null);
    handleSpringDataRequest(
      `core/api/v1/purchase-order/get-active-invoice-ids?supplierId=${supplier}&start=${moment(
        dateRange[0].startDate
      ).format("YYYY-MM-DD 00:00:00")}&end=${moment(
        dateRange[0].endDate
      ).format("YYYY-MM-DD 23:59:59")}`
    )
      .then((res) => {
        setinvoiceIds(res);
        setselectedInvocieIds(res[0]);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    handleGetPurchaseOrderDatas();
  }, [supplier]);

  useEffect(() => {
    const data = copyPurchaseOrderData?.filter(
      (item) => item?.invoice_number === selectedInvocieIds
    );
    setPurchaseOrderData(data);
  }, [selectedInvocieIds, copyPurchaseOrderData, supplier]);

  // All the categpries
  const [categories, setCategories] = useState([
    "Bakery products",
    "Beverages",
    "Bones",
    "Cereals",
    "Chemicals",
    "Dairy products",
    "Electronic components",
    "Flowers",
    "Flour",
    "Fruits",
    "Grains",
    "Latex",
    "Leather",
    "Meat",
    "Metals",
    "Milk",
    "Non Veg",
    "Nuts",
    "Oils and fats",
    "Others",
    "Packaging materials",
    "Petroleum",
    "Poultry",
    "Proteins products",
    "Resin",
    "Salads",
    "Salts",
    "Sauces",
    "Seafood",
    "Seeds",
    "Silk",
    "Snacks",
    "Soups",
    "Spices",
    "Sweets",
    "Sweeteners",
    "Textiles and fabrics",
    "Veg",
    "Vegetables",
    "Wood",
    "Wood and paper",
    "Wool",
  ]);

  // Map of units of measurement
  const [units, setUnits] = useState([
    "GRAM",
    "KG",
    "LITRE",
    "DISH",
    "PIECE",
    "GLASS",
    "MM",
    "METER",
    "PKTS",
    "NOS",
    "TIN",
    "BOX",
    "CAN",
    "BUN",
    "BAG",
    "QTY",
    "25 KG BAG",
    "BOTTLE",
    "JAR",
    "MILIGRAM",
    "MILLILITER",
  ]);

  //Selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  // row selection for operations
  // selecting the rows
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((selectedRows) => [...selectedRows, row.staff_id]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x !== row.staff_id));
    }
  };

  // selecting all rows at once
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.staff_id);
    if (isSelect) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  // Modify update rm items
  const [modifyUpdateItems, setModifyUpdateItems] = useState(false);
  const toggleDrawer = (action) => {
    setModifyUpdateItems(action);
  };
  //   Payload creation to add / update the warehouse material
  const [warehouseMaterialPayload, setWarehouseMaterialPayload] = useState([
    {
      id: null,
      hotel_id: hotelId,
      shop_id: "-1",
      po_from: "SUPPLIER",
      warehouse_rm_id: "",
      supplier_id: -1,
      invoice_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      invoice_number: "-1",
      gst_number: "",
      delivery_charge_included_in_invoice: 0,
      tax_percentage: 0,
      discount_type: "",
      discount_value: 0,
      payment_type: "",
      rm_name: "",
      rm_quantity: 0,
      rm_unit: "",
      rm_price: 0,
      rm_amount: 0,
      rm_tax: 0,
      description: "",
      status: "ACTIVE",
      kitchen_id: "-1",
      kitchen_rm_id: -1,
      in_hand_stock: "",
      received_quantity: 0,
    },
  ]);

  // Defining the modified payload state for adding and updating
  const [modifiedPurchaseOrderPayload, setModifiedPurchaseOrderPayload] =
    useState(null);
  //   Setting the modified payload to the initial state
  function resetWarehouseModifiedPayload() {
    setModifiedPurchaseOrderPayload([
      {
        id: null,
        hotel_id: hotelId,
        shop_id: "-1",
        po_from: "SUPPLIER",
        warehouse_rm_id: "",
        supplier_id: -1,
        invoice_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        invoice_number: "-1",
        gst_number: "",
        delivery_charge_included_in_invoice: 0,
        tax_percentage: 0,
        discount_type: "",
        discount_value: 0,
        payment_type: "",
        rm_name: "",
        rm_quantity: 0,
        rm_unit: "",
        rm_price: 0,
        rm_amount: 0,
        rm_tax: 0,
        description: "",
        status: "ACTIVE",
        kitchen_id: "-1",
        kitchen_rm_id: -1,
        in_hand_stock: "",
        received_quantity: 0,
      },
    ]);
  }
  useEffect(() => {
    resetWarehouseModifiedPayload();
  }, []);

  //   Function to add or update a field into the warehouse payload
  function handleChangeRMS(e) {
    const { name, value } = e.target;
    setModifiedPurchaseOrderPayload({
      ...modifiedPurchaseOrderPayload,
      [`${name}`]: value,
    });
  }

  // loader
  const [loading, setLoading] = useState(false);
  // Creating and updating the payload
  function handleCreateUpdatePayload() {
    if (
      paymentType === "" ||
      paymentType === null ||
      paymentType === undefined
    ) {
      toast.warning(`${t("Please select payment type")}`);
    } else {
      const resp = modifiedPurchaseOrderPayload?.map(
        (item) => (item.payment_type = paymentType)
      );

      setLoading(true);
      if (actionType === "create") {
        handleSpringDataMutationRequest(
          "POST",
          "core/api/v1/purchase-order/bulk-create",
          modifiedPurchaseOrderPayload
        )
          .catch((err) => toast.error(err))
          .then(() => setLoading(false))
          .then(() => toggleDrawer(false))
          .then(() => {
            handleGetPurchaseOrder();
            handleGetPurchaseOrderDatas();
          })
          .then(() => resetWarehouseModifiedPayload());
      } else {
        handleSpringDataMutationRequest(
          "PUT",
          `core/api/v1/purchase-order/bulk-update?id=${modifiedPurchaseOrderPayload[0].id}`,
          modifiedPurchaseOrderPayload
        )
          .catch((err) => toast.error(err))
          .then(() => setLoading(false))
          .then(() => toggleDrawer(false))
          .then(() => handleGetPurchaseOrder())
          .then(() => resetWarehouseModifiedPayload());
      }
    }
  }

  // Get the warehouse raw materials
  const [warehouseRM, setWarehouseRM] = useState([]);
  function handleGetAllWarehouseRM() {
    handleSpringDataRequest("core/api/v1/warehouse-rm/get-all")
      .then((res) => setWarehouseRM(res))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetAllWarehouseRM();
  }, []);

  // Deleting the menu item
  // Delete confirmation modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  function handleOpenConfirmDeleteModal() {
    setConfirmDeleteModal(true);
  }
  function handleCloseConfirmDeleteModal() {
    setConfirmDeleteModal(false);
  }
  function handleDeleteMenuItem() {
    setLoading(true);
    handleSpringDataMutationRequest(
      "DELETE",
      `core/api/v1/purchase-order/delete?id=${modifiedPurchaseOrderPayload[0]?.id}`
    )
      .then((res) => toast.success(res))
      .catch((err) => toast.error(err))
      .then(() => handleGetPurchaseOrder())
      .then(() => setLoading(false))
      .then(() => handleCloseConfirmDeleteModal());
  }

  // useEffect(() => {
  //   if (
  //     modifiedPurchaseOrderPayload?.warehouse_rm_id !== "" &&
  //     modifiedPurchaseOrderPayload?.warehouse_rm_id !== undefined &&
  //     warehouseRM?.length > 0
  //   ) {
  //     const data = warehouseRM.filter(
  //       (e) =>
  //         Number(e.id) === Number(modifiedPurchaseOrderPayload?.warehouse_rm_id)
  //     );
  //     setModifiedPurchaseOrderPayload({
  //       ...modifiedPurchaseOrderPayload,
  //       [`rm_name`]: data[0]?.name,
  //       [`rm_quantity`]: data[0]?.quantity,
  //       [`rm_unit`]: data[0]?.purchase_unit,
  //       [`rm_price`]: data[0]?.purchase_price,
  //       [`rm_amount`]: data[0]?.quantity * data[0]?.purchase_price,
  //     });
  //   }
  // }, [modifiedPurchaseOrderPayload?.warehouse_rm_id]);
  function bulkUpdatePurchaseOrder() {
    let newRawMaterialPayload = {
      id: null,
      hotel_id: hotelId,
      shop_id: "-1",
      po_from: "SUPPLIER",
      warehouse_rm_id: "",
      supplier_id: -1,
      invoice_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      invoice_number: "",
      gst_number: "",
      delivery_charge_included_in_invoice: 0,
      tax_percentage: 0,
      discount_type: "",
      discount_value: 0,
      payment_type: "",
      rm_name: "",
      rm_quantity: 0,
      rm_unit: "",
      rm_price: 0,
      rm_amount: 0,
      rm_tax: 0,
      description: "",
      status: "ACTIVE",
      kitchen_id: "-1",
      kitchen_rm_id: -1,
      in_hand_stock: "",
      received_quantity: 0,
    };

    let data = [...modifiedPurchaseOrderPayload];
    data.push(newRawMaterialPayload);
    setModifiedPurchaseOrderPayload(data);
  }

  useEffect(() => {
    const data = modifiedPurchaseOrderPayload?.filter((item) => {
      if (item?.warehouse_rm_id === "") {
        setisDisabled(true);
      } else {
        setisDisabled(false);
      }
    });
  }, [modifiedPurchaseOrderPayload]);

  // Function to remove the added raw material
  function removeRawMaterial(idx) {
    let data = [...modifiedPurchaseOrderPayload];
    data.splice(idx, 1);
    setModifiedPurchaseOrderPayload(data);
  }

  function updateRawMaterial(idx, e) {
    const { name, value } = e.target;
    let data = modifiedPurchaseOrderPayload[idx];
    data[name] = value;
    if (name === "warehouse_rm_id") {
      const materialName = warehouseRM.filter(
        (item) => item.id === Number(value)
      );
      data[`rm_name`] = materialName[0]?.name;
      data[`rm_quantity`] = 1;
      data[`rm_unit`] = materialName[0]?.purchase_unit;
      data[`rm_price`] = materialName[0]?.purchase_price;
      data[`rm_amount`] = 1 * materialName[0]?.purchase_price;
      data[`gst_number`] = taxNumber;
      data[`supplier_id`] = supplier;
      data[`delivery_charge_included_in_invoice`] = deliveryPercentage;
      data[`payment_type`] = paymentType;
      data[`tax_percentage`] = materialName[0]?.gst_percentage;
      data[`rm_tax`] =
        (parseFloat(1) *
          parseFloat(materialName[0]?.purchase_price) *
          parseFloat(materialName[0]?.gst_percentage)) /
        100;
      data[`in_hand_stock`] = materialName[0]?.in_hand_stock;
      data[`received_quantity`] = 0;

      // data["purchase_unit"] = materialName[0].rm_unit;
      // data["quantity"] = materialName[0]?.rm_quantity;
      // data["po_id"] = materialName[0]?.id;
    }
    if (name === "rm_quantity" || name === "rm_price") {
      const data_quantity =
        data.rm_quantity === "" ? 0 : parseFloat(data.rm_quantity);
      const data_price = data.rm_price === "" ? 0 : parseFloat(data.rm_price);
      const gst =
        data.tax_percentage === "" ? 0 : parseFloat(data.tax_percentage);
      data[`rm_amount`] = data_quantity * data_price;
      data[`rm_tax`] = (data_quantity * data_price * gst) / 100;
    }

    const updatedBulkEdit = modifiedPurchaseOrderPayload.map(
      (bulkEdit, index) => {
        if (index === idx) {
          return data;
        } else {
          return bulkEdit;
        }
      }
    );
    setModifiedPurchaseOrderPayload(updatedBulkEdit);
  }
  useEffect(() => {
    const taxNum = suppliers?.filter(
      (e) => Number(e.supplier_id) === Number(supplier)
    );
    settaxNumber(taxNum[0]?.gst_number);
  }, [supplier]);

  const [grandTotal, setgrandTotal] = useState(0);
  useEffect(() => {
    let total = 0;
    const data =
      modifiedPurchaseOrderPayload &&
      modifiedPurchaseOrderPayload.map(
        (item) =>
          (total =
            total +
            parseFloat(item?.rm_tax.toFixed(2)) +
            parseFloat(item?.rm_amount.toFixed(2)))
      );

    let deliveryPercent;
    if (
      deliveryPercentage === "" ||
      deliveryPercentage === undefined ||
      deliveryPercentage === null
    ) {
      deliveryPercent = 0;
    } else {
      deliveryPercent = Number(deliveryPercentage);
    }
    total = Number(total) + Number(deliveryPercent);
    setgrandTotal(total);
  }, [modifiedPurchaseOrderPayload, deliveryPercentage]);

  const [selectedSupplier, setselectedSupplier] = useState({});
  useEffect(() => {
    const data = suppliers?.filter(
      (item) => Number(item?.supplier_id) === Number(supplier)
    );
    setselectedSupplier(data[0]);
  }, [supplier, suppliers]);

  useEffect(() => {}, [purchaseOrderData]);
  const list = () => (
    <Box role="presentation" className="addUpdateModalPuchaseOrder">
      <Stack
        className="headerWrapper"
        style={{ justifyContent: "space-between" }}
      >
        <Typography className="heading">
          <strong>{t("Add purchase order")}</strong>
        </Typography>
        <Typography
          className="close"
          onClick={() => {
            toggleDrawer(false);
            resetWarehouseModifiedPayload();
          }}
        >
          <AiIcons.AiOutlineClose size="20px" />
        </Typography>
      </Stack>
      <Divider />

      <p
        // className="text-muted"
        style={{
          fontWeight: "bold",
          marginBottom: "2rem",
          marginLeft: "1rem",
          marginTop: "1rem",
        }}
      >
        {" "}
        {t(
          "Add items for purchase from your chosen vendor, then confirm your selection. Review the PDF to double-check your order before emailing it to the vendor. Make any last-minute changes easily by clicking edit item"
        )}
      </p>

      <Stack className="fields">
        <Box sx={{ width: "15%" }} className="field">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t("Vendor")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={supplier}
              label={t("Vendor")}
              name="supplier_id"
              onChange={(e) => setsupplier(e.target.value)}
            >
              {suppliers?.map((supplier, index) => (
                <MenuItem value={String(supplier.supplier_id)} key={index}>
                  {supplier?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* <TextField
            id="outlined-name"
            label="Invoice number"
            name="invoice_number"
            value={item?.invoice_number}
            onChange={(e) => updateRawMaterial(idx, e)}
            className="field"
            sx={{ width: "20%" }}
          /> */}
        {/* <TextField
          id="outlined-name"
          label="Tax Number"
          name="gst_number"
          value={taxNumber}
          onChange={(e) => settaxNumber(e.target.value)}
          className="field"
          sx={{ width: "15%" }}
          disabled={actionType === "update" && true}
        /> */}

        <TextField
          id="outlined-name"
          label={t("Delivery charge included in invoice (FLAT)")}
          name="delivery_charge_included_in_invoice"
          value={deliveryPercentage}
          onChange={(e) => setdeliveryPercentage(e.target.value)}
          className="field"
          sx={{ width: "18%" }}
          disabled={actionType === "update" && true}
        />

        <Box sx={{ width: "15%" }} className="field">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("Payment Type")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={paymentType}
              label={t("Payment Type")}
              name="payment_type"
              onChange={(e) => setPaymentType(e.target.value)}
            >
              <MenuItem value={"PAID"}>{t("PAID")}</MenuItem>
              <MenuItem value={"UNPAID"}>{t("UNPAID")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/* {paymentType === "PAID" && (
          <Box sx={{ width: "15%" }} className="field">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Payment mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paymentMode}
                label="Payment Mode"
                name="payment_mode"
                onChange={(e) => setpaymentMode(e.target.value)}
              >
                <MenuItem value={"PAID"}>CASH</MenuItem>
                <MenuItem value={"UNPAID"}>CARD</MenuItem>
                <MenuItem value={"UPI"}>UPI</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )} */}

        {/* <TextField
          id="outlined-name"
          label="Payment Type"
          name="payment_type"
          value={paymentType}
          onChange={(e) => setPaymentType(e.target.value)}
          className="field"
          sx={{ width: "15%" }}
          disabled={actionType === "update" && true}
        /> */}
      </Stack>
      {modifiedPurchaseOrderPayload?.map((item, idx) => (
        <Stack className="fields">
          <Box sx={{ width: "10%" }} className="field">
            <Autocomplete
              options={warehouseRM?.filter(
                (wrm) =>
                  !modifiedPurchaseOrderPayload?.some(
                    (prevItem) => prevItem.warehouse_rm_id === wrm.id
                  )
              )}
              getOptionLabel={(option) => option.name}
              value={
                warehouseRM?.find(
                  (option) => option.id === item.warehouse_rm_id
                ) || null
              }
              onChange={(e, newValue) => {
                const newId = newValue ? newValue.id : null;
                updateRawMaterial(idx, {
                  target: { name: "warehouse_rm_id", value: newId },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Raw material name")}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable browser autofill
                  }}
                />
              )}
            />
          </Box>
          <TextField
            id="outlined-name"
            label={t("Quantity")}
            name="rm_quantity"
            value={item?.rm_quantity}
            helperText={`${t("In hand stock")} ${item?.in_hand_stock}`}
            onChange={(e) => {
              if (e.target.value >= 0) updateRawMaterial(idx, e);
            }}
            className="field"
            sx={{ width: "10%" }}
            // disabled={actionType === "update" && true}
          />
          <TextField
            id="outlined-name"
            label={t("Unit")}
            name="rm_unit"
            value={item?.rm_unit}
            disabled={true}
            onChange={(e) => updateRawMaterial(idx, e)}
            className="field"
            sx={{ width: "8%" }}
            // disabled={actionType === "update" && true}
          />
          <TextField
            id="outlined-name"
            label={t("Price Per Unit")}
            name="rm_price"
            value={item?.rm_price}
            onChange={(e) => updateRawMaterial(idx, e)}
            className="field"
            sx={{ width: "10%" }}
            disabled={actionType === "update" && true}
          />
          <TextField
            id="outlined-name"
            label={t("Amount")}
            name="rm_amount"
            value={item?.rm_amount.toFixed(2)}
            onChange={(e) => updateRawMaterial(idx, e)}
            className="field"
            sx={{ width: "10%" }}
            disabled={true}
          />
          <TextField
            id="outlined-name"
            label={t("Tax percentage")}
            name="tax_percentage"
            value={item?.tax_percentage}
            // onChange={(e) => updateRawMaterial(idx, e)}
            className="field"
            sx={{ width: "8%" }}
            disabled={true}
          />
          <TextField
            id="outlined-name"
            label={t("Tax Amount")}
            name="rm_tax"
            value={item?.rm_tax.toFixed(2)}
            onChange={(e) => updateRawMaterial(idx, e)}
            className="field"
            sx={{ width: "10%" }}
            disabled={true}
          />
          <TextField
            id="outlined-name"
            label={t("Total Amount")}
            name="rm_tax"
            value={
              parseFloat(item?.rm_tax.toFixed(2)) +
              parseFloat(item?.rm_amount.toFixed(2))
            }
            // onChange={(e) => updateRawMaterial(idx, e)}
            className="field"
            sx={{ width: "10%" }}
            disabled={true}
          />

          <TextField
            id="outlined-name"
            label={t("Description")}
            name="description"
            value={item?.description}
            onChange={(e) => updateRawMaterial(idx, e)}
            className="field"
            idx
            sx={{ width: "10%" }}
          />
          {idx !== 0 && (
            <Typography
              onClick={() => removeRawMaterial(idx)}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "15px",
                cursor: "pointer",
              }}
            >
              <AiIcons.AiOutlineClose size="20px" />
            </Typography>
          )}
        </Stack>
      ))}
      <Button
        style={{
          width: "fit-content",
          textTransform: "none",
          marginLeft: "20px",
        }}
        disabled={isDisabled}
        onClick={bulkUpdatePurchaseOrder}
        variant="custom-button"
      >
        {t("Add purchase order")}
      </Button>
      <h4 style={{ float: "right", marginRight: "7rem" }}>{`${t(
        "Grand Total"
      )}: ${grandTotal}`}</h4>
      <Stack className="ctas" direction="row">
        <Button
          className="cancelBtn"
          onClick={() => {
            toggleDrawer(false);
            resetWarehouseModifiedPayload();
          }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="custom-button"
          className="successBtn"
          onClick={!loading && handleCreateUpdatePayload}
          disabled={isDisabled ? true : loading & true}
        >
          {!loading ? `${t("Confirm")}` : <CircularProgress size="20px" />}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <>
      <StockWrapper
        backBtn={false}
        hideDrawer={true}
        stockTypeName={t("Purchase order list")}
        contentArea={
          <Stack className="position-relative mt-8">
            {purchaseOrderData ? (
              <ToolkitProvider
                keyField="staff_id"
                data={purchaseOrderData}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <p
                      // className="text-muted"
                      style={{ fontWeight: "bold", marginBottom: "2rem" }}
                    >
                      {t(
                        "This page displays your orders from a specific supplier, along with order dates and payment status. You can track orders using a request number and view items yet to be received. Once received, orders are removed. To receive raw materials, visit Configuration Raw Material Centralized and select Receive Raw Material Vendor."
                      )}
                    </p>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="d-flex justify-content-between"
                    >
                      <div className="d-flex">
                        <Stack className="searchBarWrapper">
                          <SearchBar
                            {...props.searchProps}
                            className="hkSearchBar mr-2"
                            style={{ height: "33.5px" }}
                          />
                        </Stack>

                        <Stack className="ml-3 mr-3">
                          <FormControl style={{ width: "15rem" }}>
                            <InputLabel id="demo-simple-select-label">
                              {t("Vendor")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={supplier}
                              size="small"
                              label={t("Vendor")}
                              name="supplier_id"
                              onChange={(e) => setsupplier(e.target.value)}
                            >
                              {suppliers?.map((supplier, index) => (
                                <MenuItem
                                  value={String(supplier.supplier_id)}
                                  key={index}
                                >
                                  {supplier?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                        <Stack className="ml-3 mr-3">
                          <FormControl style={{ width: "15rem" }}>
                            <InputLabel id="demo-simple-select-label">
                              {t("Request Number")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedInvocieIds}
                              size="small"
                              label={t("Request Number")}
                              name="Invoice ids"
                              onChange={(e) =>
                                setselectedInvocieIds(e.target.value)
                              }
                            >
                              {invoiceIds?.map((item) => (
                                <MenuItem value={String(item)} key={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                        <Stack
                          spacing={4}
                          className="position-relative"
                          style={{ width: "fit-content" }}
                        >
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <Box
                              sx={{
                                position: "relative",
                              }}
                            >
                              <button
                                onClick={handleClick}
                                className="border rounded p-2"
                                style={{
                                  backgroundColor: "transparent",
                                  height: "37.5px",
                                }}
                              >
                                {moment(dateRange[0].startDate).format(
                                  "DD MMM YYYY"
                                )}{" "}
                                {" to "}
                                {moment(dateRange[0].endDate).format(
                                  "DD MMM YYYY"
                                )}
                              </button>
                              {open ? (
                                <Box sx={styling}>
                                  <Stack spacing={3}>
                                    <DateRange
                                      editableDateInputs={true}
                                      onChange={(item) =>
                                        setDateRange([item.selection])
                                      }
                                      moveRangeOnFirstSelection={false}
                                      ranges={dateRange}
                                    />
                                    <Stack
                                      className="position-relative w-100 d-flex justify-content-between"
                                      direction="row"
                                    >
                                      <SecondaryButton
                                        text={t("Cancel")}
                                        onClick={() => setOpen(false)}
                                      />
                                      <Button
                                        variant="custom-button"
                                        onClick={() => {
                                          handleGetPurchaseOrder();
                                          handleGetPurchaseOrderDatas();
                                          handleClickAway();
                                        }}
                                      >
                                        {t("Apply")}
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </Box>
                              ) : null}
                            </Box>
                          </ClickAwayListener>
                        </Stack>
                      </div>
                      <Stack>
                        <Button
                          variant="custom-button"
                          onClick={() => {
                            setActionType("create");
                            resetWarehouseModifiedPayload();
                            toggleDrawer(true);
                          }}
                        >
                          {t("Add purchase order")}
                        </Button>
                      </Stack>
                    </Stack>
                    <BootstrapTable
                      {...props.baseProps}
                      tabIndexCell
                      headerWrapperClasses="foo"
                      bodyClasses="tableBody"
                      bordered={false}
                      //   selectRow={selectRow}
                      pagination={paginationFactory()}
                      limitTags={3}
                    />
                  </>
                )}
              </ToolkitProvider>
            ) : (
              <MockLoaderTable />
            )}
          </Stack>
        }
      />
      <Drawer
        anchor={"right"}
        open={modifyUpdateItems}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {list()}
      </Drawer>
      {/* Modal confirming the deletion of the menu item */}
      <Modal
        open={confirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="deleteModalWrapper">
          <Stack direction="row" className="modalHeaderWrapper">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("Delete")} {modifiedPurchaseOrderPayload?.name}
            </Typography>
            <Typography id="modal-modal-description">
              <AiIcons.AiOutlineClose />
            </Typography>
          </Stack>
          <Divider />
          <Stack className="confirm">
            {t("Are you sure you want to delete")}{" "}
            {modifiedPurchaseOrderPayload?.name}.{" "}
            {t("Once done, this cannot be rolled back.")}
          </Stack>
          <Stack direction="row" className="btns">
            <Button className="cancelBtn">{t("Cancel")}</Button>
            <Button
              className="successBtn"
              variant="custom-button"
              onClick={!loading && handleDeleteMenuItem}
              disabled={loading && true}
            >
              {!loading ? `${t("Confirm")}` : <CircularProgress size="25px" />}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default PurchaseOrder;
