import React, { useState } from "react";
import * as BiIcons from "react-icons/bi";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { checkin, checkout } from "../DashBoardApi";
import Folios from "../Folios/Folios";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Drawer } from "@mui/material";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import * as AiIcons from "react-icons/ai";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { epochTime } from "../../Utils/EpochTime";
import { Modal, TextField, Badge } from "@mui/material";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api/index";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { getUserId } from "../BookingDashboard/apis/BrandingModalApi";
import { channelLogoMapDash } from "../CalendarPage/constants/Calendar-constants";
import moment from "moment";

function SelfCheckinCard({
  item,
  idx,
  toggleChange,
  expanded,
  getDashboardData,
  data,
  hotelId,
}) {
  const envType = process.env.REACT_APP_ENV;

  const getRoomId = (status, id) => {
    if (status === "CANCELLED") {
      return "";
    } else if (status === "NO_SHOW") {
      return "";
    } else if (status === "ON_HOLD") {
    } else {
      return id;
    }
  };

  const getReservationStatus = (status) => {
    if (status === "ADMIN_CONFIRMED") {
      return "CHECKED-IN";
    } else if (status === "DONE") {
      return "CHECKED-OUT";
    } else {
      return status;
    }
  };

  // Handling checkin for the future date

  const todayDate = Number(epochTime(new Date()));
  const formatCheckinDate = Number(epochTime(new Date(item.check_in)));
  const formatCheckoutDate = Number(epochTime(new Date(item?.check_out)));
  const [loading, setloading] = useState(false);
  // Condition to render checkin to the customer
  const hours_start = Math.abs(new Date(item.check_in) - todayDate) / 3600000;
  const hours_end = new Date().getTime() >= new Date(item?.check_out).getTime();

  const checkins = async (id) => {
    // if (todayDate >= formatCheckinDate && todayDate <= formatCheckoutDate) {
    setloading(true);
    const data = await checkin(id);
    setloading(false);
    getDashboardData();
    // } else {
    //   toast.error(
    //     "Checkin not allowed for future dates. Please try again later."
    //   );
    // }
  };

  const checkouts = async (id) => {
    setloading(true);
    const data = await checkout(id);
    setloading(false);
    getDashboardData();
  };

  /* Magic Link */

  // Magic link modal
  const [openMagicLinkModal, setOpenMagicLinkModal] = useState(false);
  const handleOpenMagicLinkModal = () => {
    setOpenMagicLinkModal(true);
  };
  const handleCloseMagicLinkModal = () => {
    setOpenMagicLinkModal(false);
  };

  // Approve self link modal
  const [userDetails, setuserDetails] = useState({
    about_me: "",
    address1: "",
    address2: "",
    city: "",
    contact: "",
    country: "",
    email: "",
    emergency_contact: "",
    fullAddress: "",
    govt_id_type: "",
    govt_id_value: "",
    name: "",
    primary_currency: "",
    signature_base64: "",
    state: "",
    status: "",
    type: "",
    username: "",
    zipcode: "",
  });
  const [openSelfLinkModal, setOpenSelfLinkModal] = useState(false);
  const [userIds, setuserIds] = useState({});
  const [approveLoader, setapproveLoader] = useState(false);
  const handleOpenSelfLinkModal = async (email) => {
    setapproveLoader(true);
    handleSpringDataMutationRequest("POST", "user/getUserDetails", {
      username: email,
    })
      .then((res) => setuserDetails(res))
      .catch((err) => toast.error("Something went wrong"));

    const data = await getUserId(email);
    setuserIds(data);
    setapproveLoader(false);
    setOpenSelfLinkModal(true);
  };
  const handleCloseSelfLinkModal = () => {
    setOpenSelfLinkModal(false);
  };

  // handling booking confirmation email loading
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  // pre populating magic link modal fields
  const [magicLinkMail, setMagicLinkMail] = useState(item?.email);
  const [magicLinkPhone, setMagicLinkPhone] = useState(item?.guestPhone);

  // send magic link
  function handleSendMagicLink() {
    setMagicLinkLoading(true);
    if (magicLinkMail == "" || magicLinkPhone == "") {
      toast.error("Empty email or phone, please enter valid email and phone.");
      setMagicLinkLoading(false);
      return;
    }
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendSelfServiceLinkCust/",
      {
        bookingId: item?.bookingId,
        email: magicLinkMail,
        phoneNumber: magicLinkPhone,
      }
    )
      .then((res) => toast.success("Magic link sent successfully."))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseMagicLinkModal())
      .then((res) => setMagicLinkMail(item?.email))
      .then((res) => setMagicLinkPhone(item?.guestPhone))
      .catch((err) => toast.error(err))
      .catch((err) => setMagicLinkLoading(false));
  }
  const [approveStateLoader, setapproveStateLoader] = useState(false);

  const changeSelfCheckinStatus = (bookingId) => {
    setapproveStateLoader(true);
    handleSpringDataRequest(
      `core/api/v1/reservation/selfCheckinStatus/update?bookingId=${bookingId}`
    )
      .then((res) => {
        toast.success("Successfully changed status");
        setapproveStateLoader(false);
        getDashboardData();
        handleCloseSelfLinkModal();
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setapproveStateLoader(false);
      });
  };

  const getlogo = (otaLogo) => {
    let logo = channelLogoMapDash[otaLogo];
    if (logo === undefined) logo = channelLogoMapDash["STAYFLEXI_OD"];
    return logo;
  };

  return (
    <>
      <Accordion
        sx={{ marginTop: "15px" }}
        expanded={expanded === idx}
        onChange={toggleChange()}
      >
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{ userSelect: "auto" }}
        >
          <Typography sx={{ width: "60%", flexShrink: 0 }}>
            <div className="customerDetailsWrapper">
              <Stack spacing={4} direction="row">
                <div className="personalDetails">
                  <Stack sx={{ width: "125px" }} spacing={2} direction="column">
                    <div className="name">{item.guestName}</div>
                    <div className="phone">{item.guestPhone}</div>
                  </Stack>
                </div>
                <div className="personalBookingDetails">
                  <Stack spacing={2} direction="column">
                    <div className="bookingId">{item.bookingId}</div>
                    <div className="bookingPeriod">
                      {moment(item.checkIn, "DD-MM-YYYY HH:mm:ss").format(
                        "MMM DD, YYYY hh:mm A"
                      )}{" "}
                      -{" "}
                      {moment(item.checkout, "DD-MM-YYYY HH:mm:ss").format(
                        "MMM DD, YYYY hh:mm A"
                      )}
                    </div>
                  </Stack>
                </div>
                {getlogo(item?.booking_source_code).includes(".png") && (
                  <div style={{ marginTop: "1rem" }}>
                    <img
                      src={getlogo(item?.bookingSource)}
                      style={{ height: "2rem" }}
                    />
                  </div>
                )}
              </Stack>
            </div>
          </Typography>

          <Typography sx={{ width: "40%", flexShrink: 0 }}>
            <div className="bookingSummaryWrapper">
              <Stack spacing={2} direction="column">
                <div className="bookingKey">
                  {item.selfServiceType.map((e) => (
                    <Tooltip title={`${e.amount.toFixed(2)}`} className="mr-2">
                      <p className="badge badge-warning">{e.type}</p>
                    </Tooltip>
                  ))}
                  <div
                  // style={{ marginTop: "auto", marginBottom: "auto" }}
                  >
                    <BiIcons.BiKey color="black" size="25px" />
                  </div>
                  <div
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "7.5px",
                      fontSize: "15.5px",
                    }}
                  >
                    {item?.roomIds.join(",")}
                  </div>
                </div>
                <div className="detailsWrapper">
                  <Stack spacing={2} direction="row">
                    {getlogo(item?.booking_source_code).includes(".png") ===
                      false && (
                      <div className="bookingSource">{item.bookingSource}</div>
                    )}
                    <div className="bookingStatues">
                      {/* {item.reservationStatus === "ADMIN_CONFIRMED"
                        ? "Checked-in"
                        : item.reservationStatus} */}
                      {getReservationStatus(item.reservationStatus)}
                    </div>
                    <div className="bookingAmt">
                      {data.hotelCurrency} {item.amount.toFixed(2)}
                    </div>
                  </Stack>
                </div>
              </Stack>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className="subCardWrapper">
              <div className="subCardItems">
                <CardContent className="d-flex">
                  {item.reservationStatus === "ADMIN_CONFIRMED" && (
                    <Stack spacing={2} direction="row">
                      <Link
                        to={{
                          pathname: `/folio/${item?.bookingId}`,
                          search: `?hotelId=${hotelId}`,
                        }}
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          className="outline"
                          style={{ textTransform: "none" }}
                        >
                          View folio
                        </Button>
                      </Link>
                      {JSON.parse(
                        data?.accessControl?.user_feature_map
                          ?.MagicLinkCommunication
                      ).read === "YES" && (
                        <Button
                          variant="outlined"
                          className="outline"
                          onClick={() => handleOpenMagicLinkModal()}
                          style={{ textTransform: "none" }}
                        >
                          Send magic link
                        </Button>
                      )}
                    </Stack>
                  )}
                  {item.reservationStatus === "CONFIRMED" && (
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        className="outline"
                        onClick={() => checkins(item.bookingId)}
                        disabled={loading}
                        style={{ textTransform: "none" }}
                      >
                        {loading && (
                          <Stack
                            sx={{ color: "grey.500" }}
                            spacing={2}
                            direction="row"
                          >
                            <CircularProgress
                              size={20}
                              color="inherit"
                              sx={{ ml: 1.5 }}
                            />
                          </Stack>
                        )}{" "}
                        Check-in
                      </Button>
                      <Link
                        to={{
                          pathname: `/folio/${item?.bookingId}`,
                          search: `?hotelId=${hotelId}`,
                        }}
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          className="outline"
                          style={{ textTransform: "none" }}
                        >
                          View folio
                        </Button>
                      </Link>
                      {JSON.parse(
                        data?.accessControl?.user_feature_map
                          ?.MagicLinkCommunication
                      ).read === "YES" && (
                        <Button
                          variant="outlined"
                          className="outline"
                          onClick={() => handleOpenMagicLinkModal()}
                          style={{ textTransform: "none" }}
                        >
                          Send magic link
                        </Button>
                      )}
                      {/* <Button variant="outlined" className="outline">
                        Send payment link
                      </Button>
                      <Button variant="contained" className="contained">
                        Send check-in link
                      </Button> */}
                    </Stack>
                  )}

                  {item.reservationStatus === "DONE" && (
                    <Stack spacing={2} direction="row">
                      <Link
                        to={{
                          pathname: `/folio/${item?.bookingId}`,
                          search: `?hotelId=${hotelId}`,
                        }}
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          className="outline"
                          style={{ textTransform: "none" }}
                        >
                          View folio
                        </Button>
                      </Link>
                    </Stack>
                  )}
                  {item.reservationStatus === "CANCELLED" && (
                    <Stack spacing={2} direction="row">
                      <Link
                        to={{
                          pathname: `/folio/${item?.bookingId}`,
                          search: `?hotelId=${hotelId}`,
                        }}
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          className="outline"
                          style={{ textTransform: "none" }}
                        >
                          View folio
                        </Button>
                      </Link>
                    </Stack>
                  )}
                  {item.reservationStatus === "ON_HOLD" && (
                    <Stack spacing={2} direction="row">
                      <Link
                        to={{
                          pathname: `/folio/${item?.bookingId}`,
                          search: `?hotelId=${hotelId}`,
                        }}
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          className="outline"
                          style={{ textTransform: "none" }}
                        >
                          View folio
                        </Button>
                      </Link>
                      {JSON.parse(
                        data?.accessControl?.user_feature_map
                          ?.MagicLinkCommunication
                      ).read === "YES" && (
                        <Button
                          variant="outlined"
                          className="outline"
                          onClick={() => handleOpenMagicLinkModal()}
                          style={{ textTransform: "none" }}
                        >
                          Send magic link
                        </Button>
                      )}
                    </Stack>
                  )}
                  {item.reservationStatus === "PENDING" && (
                    <Stack spacing={2} direction="row">
                      <Link
                        to={{
                          pathname: `/folio/${item?.bookingId}`,
                          search: `?hotelId=${hotelId}`,
                        }}
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          className="outline"
                          style={{ textTransform: "none" }}
                        >
                          View folio
                        </Button>
                      </Link>
                      {JSON.parse(
                        data?.accessControl?.user_feature_map
                          ?.MagicLinkCommunication
                      ).read === "YES" && (
                        <Button
                          variant="outlined"
                          className="outline"
                          onClick={() => handleOpenMagicLinkModal()}
                          style={{ textTransform: "none" }}
                        >
                          Send magic link
                        </Button>
                      )}
                    </Stack>
                  )}
                  {item.reservationStatus === "NO_SHOW" && (
                    <Stack spacing={2} direction="row">
                      <Link
                        to={{
                          pathname: `/folio/${item?.bookingId}`,
                          search: `?hotelId=${hotelId}`,
                        }}
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          className="outline"
                          style={{ textTransform: "none" }}
                        >
                          View folio
                        </Button>
                      </Link>
                    </Stack>
                  )}
                  {item?.selfCheckinStatus === "STARTED" && (
                    <Button
                      variant="outlined"
                      className="outline"
                      style={{ textTransform: "none", marginLeft: "4px" }}
                      onClick={() => handleOpenSelfLinkModal(item.email)}
                      disabled={approveLoader}
                    >
                      {approveLoader ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      ) : (
                        "Approve"
                      )}
                    </Button>
                  )}
                </CardContent>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Send magic link modal */}
      <Modal
        open={openMagicLinkModal}
        onClose={handleCloseMagicLinkModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Send magic link</div>
              <div
                className="close"
                onClick={() => handleCloseMagicLinkModal()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div
                className="fieldWrapper"
                style={{
                  position: "relative",
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: 500,
                  backgroundColor: "#C8E5F3",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "black",
                }}
              >
                {envType.toLowerCase() === "prod"
                  ? `https://prod-next.selfservice.stayflexi.com/booking/${item?.bookingId}`
                  : `https://beta-next.selfservice.stayflexi.com/booking/${item?.bookingId}`}
              </div>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label="Email"
                  name="email"
                  helperText={
                    "By default customer's email has been populated. Override if necessary."
                  }
                  value={magicLinkMail}
                  onChange={(e) => setMagicLinkMail(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label="Phone number"
                  name="phone"
                  helperText={
                    "By default customer's contact number has been populated. Override if necessary."
                  }
                  value={magicLinkPhone}
                  onChange={(e) => {
                    /^[\+0-9]*$/.test(e.target.value) &&
                      setMagicLinkPhone(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseMagicLinkModal()}
              >
                Cancel
              </button>
              {magicLinkLoading === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => handleSendMagicLink()}
                >
                  Send Magic Link
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Send magic link modal */}
      <Modal
        open={openSelfLinkModal}
        onClose={handleCloseSelfLinkModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Self Check-in</div>
              <div className="close" onClick={() => handleCloseSelfLinkModal()}>
                X
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex mt-3 ">
              <TextField
                className="w-100 mr-2"
                required
                id="outlined-required"
                label="Name"
                value={userDetails?.name}
              />
              <TextField
                className="w-100"
                required
                id="outlined-required"
                label="Email"
                value={userDetails?.email}
              />
            </div>
            <div className="d-flex mt-3">
              <TextField
                className="w-100 mr-2"
                required
                id="outlined-required"
                label="Contact"
                value={userDetails?.contact}
              />
              <TextField
                className="w-100"
                required
                id="outlined-required"
                label="Address"
                value={userDetails?.address1}
              />
            </div>
            <div className="d-flex mt-3">
              <TextField
                className="w-100 mr-2"
                required
                id="outlined-required"
                label="City"
                value={userDetails?.city}
              />
              <TextField
                className="w-100"
                required
                id="outlined-required"
                label="State"
                value={userDetails?.state}
              />
            </div>
            <div className="d-flex mt-3">
              <TextField
                className="w-100 mr-2"
                required
                id="outlined-required"
                label="Country"
                value={userDetails?.country}
              />
              <TextField
                className="w-100"
                required
                id="outlined-required"
                label="Pincode"
                value={userDetails?.zipcode}
              />
            </div>
          </div>
          <div className="d-flex mt-4">
            {userIds.frontUrl !== "" && (
              <img
                style={{
                  width: "18rem",
                  height: "11rem",
                  marginRight: "1rem",
                }}
                src={userIds.frontUrl}
                alt=" "
              />
            )}
            {userIds.backUrl !== "" && (
              <img
                style={{
                  width: "18rem",
                  height: "11rem",
                }}
                src={userIds.backUrl}
                alt=" "
              />
            )}
          </div>

          <div className="settleDuesSubmitBtns">
            <button
              className="cancelBtn"
              onClick={() => handleCloseSelfLinkModal()}
            >
              Cancel
            </button>
            {approveStateLoader === false ? (
              <button
                className="submit w-auto"
                onClick={() => changeSelfCheckinStatus(item.bookingId)}
              >
                Approve
              </button>
            ) : (
              <button className="submit">
                <CircularProgress size="15px" />
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(SelfCheckinCard);
