import React from "react";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Grid,
  Skeleton,
} from "@mui/material";
import RightContentSectionLoader from "./RightContentSectionLoader";

const RightSectionLoader = () => {
  return (
    <Box sx={{ width: "65%" }}>
      <Card
        sx={{
          flex: 1,
          height: "94vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton variant="text" width={220} height={35} />{" "}
              <Skeleton variant="text" width={220} height={35} />
            </Box>
          }
        />

        <RightContentSectionLoader />

        <CardActions sx={{ marginTop: "auto" }}>
          <Box
            sx={{ borderTop: "1px solid #ccc", width: "100%", paddingTop: 1 }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Skeleton variant="text" width={60} height={20} />
                <Skeleton variant="text" width={60} height={20} />
                <Skeleton variant="text" width={60} height={20} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="text" width={80} height={20} />
                <Skeleton variant="text" width={80} height={20} />
                <Skeleton variant="text" width={80} height={20} />
              </Grid>
            </Grid>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default RightSectionLoader;
