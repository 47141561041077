import React, { Fragment, useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { handleSpringDataRequest } from "../../../../../api";

const borderColor = "#E5e5e5";

// rounding of the digits
const handleDigits = (num) => {
  let formattedNum = parseFloat(num);
  return parseFloat(formattedNum).toFixed(2);
};

const PosDualText = ({ boldText, normalText }) => {
  return (
    <View style={styles.dualText}>
      <Text style={styles.boldText}>{boldText}:</Text>
      <Text style={styles.normalText}>{normalText}</Text>
    </View>
  );
};

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

export const PrintBillDoc = ({
  menuItems,
  // shopName,
  // shopAddress,
  // shopPhone,
  // taxIdentificationNo,
  // customerName,
  // customerPhone,
  // customerEmail,
  // customerGSTNo,
  symbol,
  // subtotal,
  // tax,
  // discount,
  // total,
  hotelLogo,
  // orderStatus,
  // invoiceNumber,
  // checkComplimentaryOrder,
  // orderDate,
  orderPunchedInfo,
  orderPunchedType,
  // serviceCharges,
  // serviceChargeInfo,
  paymentDetails,
  // orderId,
  balDue,
  currentStaffDetails,
  // paxDetails,
  handleGetVal,
  splitPersonId,
  // roundOffError,
  order,
  outletDetails,
  isConverted,
  currencyRate,
}) => {
  const PosTableHeader = () => (
    <View style={styles.container}>
      <Text style={[styles.sno, { fontSize: "10px" }]}>S.No.</Text>
      <Text style={[styles.description, { fontSize: "10px" }]}>Name</Text>
      <Text style={[styles.qty, { fontSize: "10px" }]}>Qty.</Text>
      <Text style={[styles.rate, { fontSize: "10px" }]}>Price</Text>
      <Text style={[styles.rate, { fontSize: "10px" }]}>Total</Text>
    </View>
  );

  const Bill = ({ items, handleGetVal }) => {
    const rows = items.map((item, index) => (
      <View style={styles.row} key={index}>
        <Text style={[styles.sno, { fontSize: "10px" }]}>{index + 1}</Text>
        <View
          style={[
            styles.description,
            {
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              flexWrap: "flex-wrap",
            },
          ]}
        >
          <Text style={[{ marginBottom: 3, fontSize: "10px" }]}>
            {item?.product_name}
          </Text>
        </View>
        <Text style={[styles.qty, { fontSize: "10px", marginBottom: 5 }]}>
          {handleGetVal(item?.product_qty, "qty")}
        </Text>
        <Text style={[styles.rate, { fontSize: "10px" }]}>
          {isConverted
            ? Number(
                (Number(item?.product_price) / Number(item?.product_qty)) *
                  currencyRate
              ).toFixed(2)
            : Number(
                Number(item?.product_price) / Number(item?.product_qty)
              ).toFixed(2)}
        </Text>
        <Text style={[styles.rate, { fontSize: "10px" }]}>
          {handleGetVal(
            Number(
              isConverted
                ? item.product_price * currencyRate
                : item.product_price,
              "price"
            ).toFixed(2)
          )}
        </Text>
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };
  return (
    <View style={[styles.section, { marginTop: 20 }]}>
      <View
        style={[
          styles.dualText,
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
          },
        ]}
      >
        {getExtension(hotelLogo) && (
          <View style={{ width: "100px", height: "100px" }}>
            <Image style={styles.image} src={getExtension(hotelLogo)} />
          </View>
        )}
        <View></View>
      </View>
      <View
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ width: "50%" }}>
          <Text style={{ fontSize: "12px", marginBottom: 3.5 }}>Bill From</Text>
          <Text style={[styles.boldText, { marginBottom: 3 }]}>
            {outletDetails?.shop_name}
          </Text>
          <View
            style={[
              styles.dualText,
              {
                position: "relative",
                width: "75%",
                flexWrap: "wrap",
                wordBreak: "break-all",
              },
            ]}
          >
            <Text
              style={[
                styles.boldText,
                { marginTop: "auto", marginBottom: "auto" },
              ]}
            >
              Address :{" "}
            </Text>
            <Text
              style={[
                styles.boldText,
                { marginTop: "auto", marginBottom: "auto" },
              ]}
            >
              {outletDetails?.shop_address}
            </Text>
          </View>
          {outletDetails?.shop_phone !== undefined &&
            outletDetails?.shop_phone && (
              <View style={styles.dualText}>
                <Text style={styles.boldText}>Phone : </Text>
                <Text style={[styles.boldText, { flexWrap: "wrap" }]}>
                  {outletDetails?.shop_phone}
                </Text>
              </View>
            )}

          <View style={styles.dualText}>
            <Text style={styles.boldText}>Bill no.</Text>
            {order?.complimentary_order === "NO" &&
              ["printed", "running", "inroom", "settled"].includes(
                order?.order_status?.toLowerCase()
              ) && <Text style={styles.boldText}>{order?.invoice_no}</Text>}
            {order?.order_status?.toLowerCase() === "cancelled" && (
              <Text style={styles.boldText}>
                (Cancelled) {order?.invoice_no}
              </Text>
            )}
            {order?.complimentary_order === "YES" &&
              [
                "printed",
                "running",
                "settled",
                "inroom",
                "complimentary",
              ].includes(order?.order_status?.toLowerCase()) && (
                <Text style={styles.boldText}>
                  (Complimentary) {order?.invoice_no}
                </Text>
              )}
          </View>

          <View style={styles.dualText}>
            <Text style={styles.boldText}>Order date : </Text>
            <Text style={[styles.boldText, { flexWrap: "wrap" }]}>
              {order?.order_date
                ? moment(order?.order_date).format("MMM DD 'YY, hh:mm A")
                : moment(order?.order_date).format("MMM DD 'YY, hh:mm A")}
            </Text>
          </View>

          {orderPunchedInfo && orderPunchedType && (
            <View style={styles.dualText}>
              <Text style={[styles.boldText, { flexWrap: "wrap" }]}>
                {orderPunchedInfo}
              </Text>
              <Text style={styles.boldText}>{orderPunchedType} - </Text>
            </View>
          )}

          {currentStaffDetails.name && (
            <View style={styles.dualText}>
              <Text style={styles.boldText}>Staff : </Text>
              <Text style={[styles.boldText, { flexWrap: "wrap" }]}>
                {currentStaffDetails.name}
              </Text>
            </View>
          )}

          {order?.no_of_persons && (
            <View style={styles.dualText}>
              <Text style={styles.boldText}>PAX : </Text>
              <Text style={[styles.boldText, { flexWrap: "wrap" }]}>
                {order?.no_of_persons}
              </Text>
            </View>
          )}

          {![undefined, null, "N/A"].includes(
            outletDetails?.tax_identification_number
          ) && (
            <View style={styles.dualText}>
              <Text style={styles.boldText}>
                {["Rs.", "Rs", "INR", "inr"].includes(String(symbol))
                  ? "Outlet GST NO. : "
                  : "Outlet Tax NO : "}
              </Text>
              {outletDetails?.tax_identification_number !== undefined ? (
                <Text style={styles.boldText}>
                  {outletDetails?.tax_identification_number}
                </Text>
              ) : (
                <Text style={styles.boldText}></Text>
              )}
            </View>
          )}
        </View>
        <View style={{ width: "50%", textAlign: "right" }}>
          {(order?.cust_gst_no?.length > 0 ||
            order?.cust_name?.length > 0 ||
            order?.cust_email?.length > 0 ||
            order?.cust_phone?.length > 0) && (
            <Text
              style={{
                fontSize: "12px",
                paddingRight: "5px",
                marginBottom: 3,
              }}
            >
              Bill to
            </Text>
          )}
          {order?.cust_name?.length > 0 && (
            <View
              style={[
                {
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: 3.5,
                },
                { textAlign: "right" },
              ]}
            >
              <Text
                style={[
                  styles.boldText,
                  {
                    display: "flex",
                    wordBreak: "break-all",
                  },
                ]}
              >
                {order?.cust_name}
              </Text>
            </View>
          )}
          {order?.cust_email?.length > 0 && (
            <View
              style={[
                {
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: 3.5,
                },
                { textAlign: "right" },
              ]}
            >
              <Text
                style={[
                  styles.boldText,
                  {
                    display: "flex",
                    wordBreak: "break-all",
                  },
                ]}
              >
                {order?.cust_email}
              </Text>
            </View>
          )}
          {order?.cust_phone?.length > 0 && (
            <View
              style={[
                {
                  flexDirection: "row",
                  marginBottom: 3.5,
                  justifyContent: "flex-end",
                },
                { textAlign: "right" },
              ]}
            >
              <Text
                style={[
                  styles.boldText,
                  {
                    display: "flex",
                    wordBreak: "break-all",
                  },
                ]}
              >
                {order?.cust_phone}
              </Text>
            </View>
          )}
          {order?.cust_gst_no.length > 0 && (
            <View
              style={[
                {
                  flexDirection: "row",
                  marginBottom: 3.5,
                  justifyContent: "flex-end",
                },
                { textAlign: "right" },
              ]}
            >
              <Text style={styles.boldText}>Tax Identification No.:</Text>
              <Text style={styles.boldText}>{order?.cust_gst_no}</Text>
            </View>
          )}
        </View>
      </View>

      {menuItems && (
        <View style={styles.tableContainer}>
          <PosTableHeader />
          <Bill
            items={menuItems?.filter((item) => Number(item.id) !== -1)}
            symbol={symbol}
            handleGetVal={handleGetVal}
          />
        </View>
      )}

      <View
        style={{
          marginTop: 30,
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <View>
          <PosDualText
            boldText={"Sub total"}
            normalText={
              symbol +
              " " +
              handleGetVal(
                handleDigits(
                  isConverted ? order?.subtotal * currencyRate : order?.subtotal
                ),
                "subtotal"
              )
            }
          />
          {order?.complimentary_order === "NO" &&
            order?.order_status?.toLowerCase() !== "cancelled" && (
              <PosDualText
                boldText={"Discount"}
                normalText={
                  symbol +
                  " " +
                  handleGetVal(
                    handleDigits(
                      isConverted
                        ? order?.total_order_discount * currencyRate
                        : order?.total_order_discount
                    ),
                    "discount"
                  )
                }
              />
            )}

          {symbol !== "INR" && (
            <PosDualText
              boldText={"Tax"}
              normalText={
                symbol +
                " " +
                handleGetVal(
                  handleDigits(
                    isConverted
                      ? order?.tax_percentage * currencyRate
                      : order?.tax_percentage
                  ),
                  "tax"
                )
              }
            />
          )}
          {symbol === "INR" && (
            <>
              <PosDualText
                boldText={"Total tax"}
                normalText={
                  symbol +
                  " " +
                  handleGetVal(
                    handleDigits(
                      isConverted
                        ? order?.tax_percentage * currencyRate
                        : order?.tax_percentage
                    ),
                    "tax"
                  )
                }
              />
              <PosDualText
                boldText={"CGST"}
                normalText={
                  symbol +
                  " " +
                  handleGetVal(
                    parseFloat(
                      handleDigits(
                        isConverted
                          ? order?.tax_percentage * currencyRate
                          : order?.tax_percentage
                      ) / 2
                    ).toFixed(2),
                    "tax_cgst"
                  )
                }
              />
              <PosDualText
                boldText={
                  localStorage.getItem("utgstStatus") === "ENABLED"
                    ? "UTGST"
                    : "SGST"
                }
                normalText={
                  symbol +
                  " " +
                  handleGetVal(
                    parseFloat(
                      handleDigits(
                        isConverted
                          ? order?.tax_percentage * currencyRate
                          : order?.tax_percentage
                      ) / 2
                    ).toFixed(2),
                    "tax_sgst"
                  )
                }
              />
            </>
          )}
          {order?.service_charge > 0 &&
            order?.apply_service_charge_bydefault === "YES" &&
            order?.complimentary_order === "NO" &&
            order?.order_status?.toLowerCase() !== "cancelled" && (
              <PosDualText
                boldText={`Service charge ${
                  order?.is_room_service_charge === "NO"
                    ? outletDetails?.service_charge_type === "PERCENTAGE" &&
                      `@${outletDetails?.service_charge_value}`
                    : outletDetails?.service_charge_type_room ===
                        "PERCENTAGE" &&
                      `@${outletDetails?.service_charge_value_room}`
                }`}
                normalText={
                  symbol +
                  " " +
                  handleGetVal(
                    handleDigits(
                      isConverted
                        ? order?.service_charge * currencyRate
                        : order?.service_charge
                    ),
                    "service_charge"
                  )
                }
              />
            )}
          <PosDualText
            boldText={
              symbol === "INR" && order?.round_off_amount !== 0
                ? "Total"
                : "Grand total"
            }
            normalText={`${symbol} ${
              order?.complimentary_order === "YES"
                ? handleGetVal(
                    isConverted
                      ? order?.subtotal * currencyRate
                      : order?.subtotal,
                    "subtotal"
                  )
                : (
                    handleGetVal(
                      handleDigits(
                        isConverted
                          ? order?.order_amount * currencyRate
                          : order?.order_amount
                      ),
                      "total"
                    ) +
                    handleGetVal(
                      isConverted
                        ? order?.round_off_amount * currencyRate
                        : order?.round_off_amount,
                      "round_off_err"
                    )
                  ).toFixed(2)
            }`}
          />
          {symbol === "INR" && order?.round_off_amount !== 0 && (
            <PosDualText
              boldText={"Grand total"}
              normalText={`${symbol} ${
                order?.complimentary_order === "YES"
                  ? handleGetVal(
                      isConverted
                        ? order?.subtotal * currencyRate
                        : order?.subtotal,
                      "subtotal"
                    )
                  : Math.round(
                      Number(
                        handleGetVal(
                          isConverted
                            ? order?.order_amount * currencyRate
                            : order?.order_amount,
                          "total"
                        )
                      )
                    )
              }`}
            />
          )}
          {symbol === "INR" &&
            order?.round_off_amount !== 0 &&
            (splitPersonId ? (
              <PosDualText
                boldText={"Round off"}
                normalText={`${symbol} ${(
                  Number(
                    Math.round(
                      Number(
                        handleGetVal(
                          isConverted
                            ? order?.order_amount * currencyRate
                            : order?.order_amount,
                          "total"
                        )
                      )
                    )
                  ) -
                  Number(
                    handleGetVal(
                      handleDigits(
                        isConverted
                          ? order?.order_amount * currencyRate
                          : order?.order_amount
                      ),
                      "total"
                    ) +
                      Number(
                        handleGetVal(
                          isConverted
                            ? order?.round_off_amount * currencyRate
                            : order?.round_off_amount,
                          "round_off_err"
                        )
                      )
                  )
                )?.toFixed(2)}`}
              />
            ) : (
              <PosDualText
                boldText={"Round off"}
                normalText={`${symbol} ${handleGetVal(
                  handleDigits(
                    isConverted
                      ? order?.round_off_amount * currencyRate
                      : order?.round_off_amount
                  ),
                  "round_off_err"
                )}`}
              />
            ))}
          {splitPersonId ? (
            <PosDualText
              boldText={"Paid"}
              normalText={
                symbol +
                " " +
                Math.round(
                  handleGetVal(
                    handleDigits(
                      isConverted
                        ? order?.order_amount * currencyRate
                        : order?.order_amount
                    ),
                    "total"
                  )
                )
              }
            />
          ) : (
            paymentDetails
              // ?.filter(
              //   (orderSpecificPayment) =>
              //     String(orderSpecificPayment?.order_id) === String(orderId)
              // )
              ?.map(
                (payment) =>
                  Number(payment?.settled_amount) > 0 && (
                    <PosDualText
                      boldText={`Paid via ${payment?.payment_type}`}
                      normalText={
                        symbol +
                        " " +
                        handleGetVal(
                          handleDigits(
                            isConverted
                              ? payment?.settled_amount * currencyRate
                              : payment?.settled_amount,
                            "settled_amount"
                          )
                        )
                      }
                    />
                  )
              )
          )}
          {Number(balDue) > 0 &&
            order?.complimentary_order === "NO" &&
            order?.order_status?.toLowerCase() !== "cancelled" && (
              <PosDualText
                boldText={`Balance due`}
                normalText={
                  symbol +
                  " " +
                  handleGetVal(
                    Number(
                      balDue !== undefined
                        ? isConverted
                          ? balDue * currencyRate
                          : balDue
                        : 0
                    ),
                    "bal_due"
                  )
                }
              />
            )}
        </View>
      </View>
      {/* Displaying guest and hotel signature */}
      <View style={styles.signature}>
        <View style={styles.signatureData}>
          <Text
            style={{ fontSize: "10px", marginBottom: 5, marginTop: "auto" }}
          >
            Guest signature :
          </Text>
          <Text style={{ marginLeft: 5 }}>_________________________</Text>
        </View>
        <View style={styles.signatureData}>
          <Text
            style={{ fontSize: "10px", marginBottom: 5, marginTop: "auto" }}
          >
            Hotel signature :
          </Text>
          <Text style={{ marginLeft: 5 }}>_________________________</Text>
        </View>
      </View>

      <View style={{ marginTop: 10 }}>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
            marginTop: "auto",
            fontWeight: 500,
            color: "grey",
          }}
        >
          {" "}
          Bill printed on : {moment().format("DD-MM-YYYY hh:mm A")}{" "}
        </Text>
      </View>
      <View>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
            marginTop: "auto",
            fontWeight: 500,
            color: "grey",
          }}
        >
          Thanks for visting!
        </Text>
      </View>
    </View>
  );
};

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    width: "85px",
    height: "auto",
  },
  image: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "10px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "10px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  sno: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 3.5,
    paddingTop: 2,
  },
  description: {
    width: "45%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // textAlign: "center",
    paddingLeft: 3.5,
    paddingTop: 2,
  },
  taxDescription: {
    width: "55%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 3.5,
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 3.5,
  },
  amount: {
    width: "15%",
    paddingLeft: 3.5,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    inlineSize: "auto",
    fontStyle: "bold",
    paddingLeft: 5,
  },
  taxRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 32,
    fontStyle: "bold",
  },
  signature: {
    marginTop: 25,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
    marginTop: "auto",
    marginBottom: "auto",
  },
});
