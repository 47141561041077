import React, { useEffect, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  FormControl,
  Alert,
  IconButton,
  Snackbar,
  Collapse,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "material-react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import moment from "moment";
import { connect } from "react-redux";
import Currencies from "../../Utils/Currencies.json";
import { razorPayKey } from "../CalendarPage/constants/AmplitudeConstants";
import { channelLogoMap } from "../CalendarPage/constants/Calendar-constants";
import FlashManagerReportLoader from "../Reports/ManagerialReports/FlashManagerReport/FlashManagerReportLoader";
import { AgGridReact } from "ag-grid-react";
import { sideBar } from "../../Utils/Utils";
import CustomDateComponent from "../../../app/pages/Reports/customDateComponent.js";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
import ReportsLoader from "../../Utils/ReportsLoader";
import "ag-grid-enterprise";
import { typeOf } from "react-is";
import { BASE_ENDPOINT, BASE_SPRING_ENDPOINT } from "../../api/config";
import axios from "axios";

const Transactions = (props) => {
  // const [hash, setHash] = useState(null);
  const [ledger, setLedger] = useState(null);
  const [accBalance, setAccBalance] = useState(null);
  const [hotelCurr, setHotelCurr] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [rowsData, setRowsData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [authType, setauthType] = useState("netbanking");
  const [beneName, setbeneName] = useState("");
  const [accNumber, setaccNumber] = useState(0);
  const [accountType, setaccountType] = useState("savings");
  const [ifsc, setifsc] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [orderId, setorderId] = useState("");
  const [indPaymentMethod, setindPaymentMethod] = useState(false);
  const [indPaymentDetail, setindPaymentDetail] = useState(null);
  const [isValidHash, setIsValidHash] = useState(false);

  // const [getLedgerData, setGetLedgerData] = useState(null);
  useEffect(() => {
    // console.log("props : ", props);
    let currency =
      props?.res?.account_details?.billing_address_country?.toLowerCase() ===
      "india"
        ? "INR"
        : "USD";
    props && setLedger(props?.ledger);
    props && setRowsData(props?.ledger);
    props && setAccBalance(parseFloat(props?.accBalance).toFixed(2));
    props && setHotelCurr(currency);
    props && setGroupId(props?.groupId);
    props && setAccountInfo(props?.res);
    // props && setHash(props?.hash);
    // props && setGetLedgerData(props?.getLedgerData);
  }, [props]);

  // useEffect(async () => {
  //   if (hash && hash !== "") {
  //     await axios
  //       .post(
  //         `${process.env.REACT_APP_SPRING_API_URL}` +
  //           "core/api/v1/account/validate-hash",
  //         {
  //           hashval: hash.split(" ").join("+"),
  //         }
  //       )
  //       .then((resp) => {
  //         setIsValidHash(true);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [hash]);

  const [currSymbol, setCurrSymbol] = useState(null);
  useEffect(() => {
    let curr = hotelCurr === "INR" ? "Rs." : "$";
    // const datass = Currencies?.filter((item) => {
    //   if (item.code === hotelCurr) {
    //     curr = item.symbol;
    //   }
    // });
    setCurrSymbol(curr);
  }, [hotelCurr]);

  const [depositLoading, setDepositLoading] = useState(false);
  const [depositAmt, setDepositAmt] = useState(0);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // open={openindPay}
  //       onClose={handleindPayClose}

  const [openindPay, setopenindPay] = useState(false);

  const handleindPayOpen = () => setopenindPay(true);
  const handleindPayClose = () => setopenindPay(false);

  const [banneropen, setBannerOpen] = useState(true);

  const handleBannerOpen = () => setBannerOpen(true);
  const handleBannerClose = () => setBannerOpen(false);

  const [pmDetails, setPmDetails] = useState(null);
  const [nextPaymentSchedule, setnextPaymentSchedule] = useState(null);
  const fetchPaymentMethodData = () => {
    handleSpringDataRequest(
      `core/api/v1/account-payment-method/get-active-payment-method?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setPmDetails(res[0]);
        console.log("active payment method : ", res);
      })
      .catch((err) => {
        toast.error("No active payment method is set for the account");
        setDepositAmt(0);
        handleClose();
        console.log("The err is : ", err);
      });
  };

  const fetchPaymentMethodData2 = () => {
    handleSpringDataRequest(
      `core/api/v1/account-payment-method/get-active-payment-method?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        if (res && res.length > 0) {
          setPmDetails(res[0]);
          handleOpen();
        } else {
          toast.error(
            "No active payment method is set for the account, please setup payment method in Payment methods tab."
          );
        }
        // console.log("active payment method : ", res);
      })
      .catch((err) => {
        toast.error("No active payment method is set for the account");
        setDepositAmt(0);
        handleClose();
        console.log("The err is : ", err);
      });
  };

  const [nextPaySchedule, setNextPaySchedule] = useState(null);

  const getNextInvoicingSchedule = () => {
    handleSpringDataRequest(
      `core/api/v1/payment-schedule/get-all-schedule?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        // setPmDetails(res[0]);
        // console.log("next payment schedule : ", res);
        if (res && res !== undefined && res !== "FAILED") {
          setNextPaySchedule(res);
        }
      })
      .catch((err) => {
        // toast.error("No active payment method is set for the account");
        // setDepositAmt(0);
        // handleClose();
        console.log("The err is : ", err);
      });
  };

  const getIndianPaymentMethodDetails = () => {
    handleSpringDataRequest(
      `core/api/v1/account-payment-method/get-account-payment-method-india?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        // console.log("Result is : ", res);
        if (res && res !== undefined && res !== "FAILED") {
          setindPaymentMethod(true);
          setindPaymentDetail(res);
        }
      })
      .catch((err) => {
        console.log("Error is : ", err);
        setindPaymentMethod(false);
      });
  };

  useEffect(() => {
    if (groupId) {
      getIndianPaymentMethodDetails();
      fetchPaymentMethodData();
      getNextInvoicingSchedule();
    }
  }, [groupId]);

  const handleFortisPayment = () => {
    setDepositLoading(true);
    setRowsData(null);
    let mode = pmDetails?.payment_method_type === "account" ? "ach" : "cc";
    // console.log("pmdetails are : ", pmDetails);
    let amt = depositAmt.toString();
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/account-payment-method/fortis_process_transaction/?groupId=${groupId}&hotelId=${groupId}&mode=${mode}&amt=${amt}`,
      pmDetails
    )
      .then((res) => {
        // console.log("The fortis result is : ", res);
        toast.success(res.responseMessageStr);
        setDepositAmt(0);
        handleClose();
        const data = props?.getLedgerData();
        const datas = props?.getAccountBalance();
        setDepositLoading(false);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        toast.error(err);
        setDepositAmt(0);
        handleClose();
        setDepositLoading(false);
      });
  };

  const [razorpayFinalKey, setRazorpayFinalKey] = useState(razorPayKey);
  const [imageUrl, setImageUrl] = useState(channelLogoMap.STAYFLEXI_OM);
  const [tableLoader, setTableLoader] = useState(false);

  const payloadForRazorpay = {
    group_id: groupId,
    "razorpay-pg-id": "",
    amount: 0,
    currency: "INR",
  };

  // Razorpay
  var options = {
    key: razorpayFinalKey,
    amount: "100",
    currency: "INR",
    name: "Stayflexi",
    description: "Recharge account",
    image: imageUrl,
    // order_id: "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: function (response) {
      setTableLoader(true);
      setRowsData(null);
      payloadForRazorpay["razorpay-pg-id"] = response.razorpay_payment_id;
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/payments/razorpay/capture-payment/?groupId=${groupId}&hotelId=${groupId}`,
        payloadForRazorpay
      )
        .then((resp) => {
          toast.success(resp);
          const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          setRazorpayAmount(0);
          setTableLoader(false);
        })
        .catch((err) => {
          toast.error(err);
          setTableLoader(false);
        });
      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
    },
    prefill: {
      name: accountInfo?.account_details?.account_name,
      email: accountInfo?.account_details?.account_billing_email,
      contact: "",
    },
    notes: {
      account_id: groupId,
      module_source: "ACCOUNT_PAYMENT_LINK",
    },
    theme: {
      color: "#1ab394",
      hide_topbar: false,
    },
  };

  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const invokeRazorpayModal = () => {
    payloadForRazorpay["amount"] = options["amount"];
    let rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      setErrorShow(true);
      setErrorMessage(response?.error?.description);
      setTimeout(() => {
        setErrorShow(false);
      }, 4000);
    });
  };
  const [razorpayAmount, setRazorpayAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [openAmountModal, setOpenAmountModal] = useState(
    props.paynow ? true : false
  );

  const handleAmountClose = () => setOpenAmountModal(false);
  const handleAmountOpen = () => setOpenAmountModal(true);

  // open={openEmandateModal}
  // onClose={handleCloseOpenEmandateModal}
  const [openEmandateModal, setOpenEmandateModal] = useState(
    props.showAutoPay ? true : false
  );

  const handleoOpenEmandateModal = () => setOpenEmandateModal(true);
  const handleCloseOpenEmandateModal = () => {
    setname("");
    setemail("");
    setcontact("");
    setauthType("netbanking");
    setbeneName("");
    setaccNumber(0);
    setaccountType("savings");
    setifsc("");
    setOpenEmandateModal(false);
  };

  useEffect(() => {
    if (props && props?.accBalance && props?.accBalance < 0) {
      let amt = props?.accBalance * -1;
      let toSetamt = amt.toString().split(".")[0];
      setRazorpayAmount(toSetamt);
      setDepositAmt(toSetamt);
    }
  }, [props, props?.accBalance]);

  const rowSelectionType = "multiple";

  const onPaginationChange = (pageSize) => {
    gridApi.paginationSetPageSize(pageSize);
    // console.log("");
  };

  // quick search
  const onFilterTextChange = (event) => {
    // console.log(event.target.value)
    setFilterCount((prevCount) => prevCount + 1);
    // console.log("filterCount", filterCount)
    gridApi.setQuickFilter(event.target.value);
  };

  const [allCols, setAllCols] = useState(null);
  const [colmap, setColmap] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setAllCols(params.columnApi.columnController.columnDefs);
    // console.log("defs  : ", params.columnApi.columnController);
    const mp = new Map();
    const dt = params.columnApi.columnController.columnDefs.map((res, idx) => {
      //   console.log("index is : ", idx, "    ", res.field);
      mp.set(res.field, {
        header: res.headerName,
        hidden: false,
        type: "",
        order: idx + 1,
      });
    });
    setColmap(mp);
    // handleDataRequest("https://beta.stayflexi.com/api/v2/reports/getReportData/?hotel_id=12354&report_type=unifiedBookingReport&start_date=2021-01-01&end_date=2021-12-31&date_filter_mode=checkout")
    // .then((res) => setRowsData(res.report_data))
    // // .then((res) => console.log(console.log(res)))
    // .then((res) => params.api.applyTransaction({add:res}))
  };

  const [load, setload] = useState(false);
  // open={openDInvoice}
  //       onClose={handleCloseDInvoice}
  const [currentLedgerId, setcurrentLedgerId] = useState(null);
  const [openDInvoice, setopenDInvoice] = useState(false);

  const handleOpenDInvoice = (id) => {
    setcurrentLedgerId(id);
    setopenDInvoice(true);
  };

  const handleCloseDInvoice = () => setopenDInvoice(false);

  const downloadInvoice = () => {
    setload(true);
    handleSpringDataRequest(
      `core/api/v1/account-ledger/download-invoice/${groupId}/${currentLedgerId}/?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        // console.log(res);
        // toast.success(res);
        console.log("res : ", res);
        setload(false);
        setcurrentLedgerId(null);
        handleCloseDInvoice();
      })
      .catch((err) => {
        console.log(err);
        setload(false);
        setcurrentLedgerId(null);
        handleCloseDInvoice();
      });
  };

  let columnsInr = [
    // {field:"id",headerName: "Id"},
    {
      field: "date",
      headerName: "Transaction date",
      //   cellRendererFramework: (params) => cellRenderer(params),
      checkboxSelection: true,
      headerCheckboxSelection: true,
      valueFormatter: (params) => {
        return moment(params.value).format("lll");
      },
    },
    {
      field: "description",
      headerName: "Description",
      //   valueFormatter: (params) => {
      //     return params.value ? params.value : "-";
      //   },
      hide: false,
    },
    {
      field: "mode",
      headerName: "Mode",
      valueFormatter: (params) => {
        return params?.value?.toUpperCase();
      },
      //   filter: "agDateColumnFilter",
      //   filterParams: filterParams,
      hide: false,
    },
    {
      field: "type",
      headerName: "Type",
      // valueFormatter: (params) => params.value,
    },
    {
      field: "amount",
      headerName: "Amount",
      // valueFormatter: (params) => {
      //   return parseFloat(params.value).toFixed(2);
      // },
      cellRenderer: (params) => {
        // console.log("The params are : ", params.data.type);
        if (params.data.type === "DEBIT") {
          return currSymbol + " " + parseFloat(params.value).toFixed(2);
        } else {
          return currSymbol + " " + parseFloat(params.value).toFixed(2);
        }
        // const updatedBookingId =
        //   "SFBOOKING_" + String(hotelId) + "_" + String(param.value);
        // return (
        //   <Link
        //     to={{
        //       pathname: `/folio/${updatedBookingId}`,
        //       search: `?hotelId=${hotelId}`,
        //     }}
        //     target="_blank"
        //   >
        //     {param.value}
        //   </Link>
        // );
      },
    },
    { field: "status", headerName: "Status" },
  ];

  let columnsUsd = [
    // {field:"id",headerName: "Id"},
    {
      field: "date",
      headerName: "Transaction date",
      //   cellRendererFramework: (params) => cellRenderer(params),
      checkboxSelection: true,
      headerCheckboxSelection: true,
      valueFormatter: (params) => {
        return moment(params.value).format("lll");
      },
    },
    {
      field: "description",
      headerName: "Description",
      //   valueFormatter: (params) => {
      //     return params.value ? params.value : "-";
      //   },
      hide: false,
    },
    {
      field: "mode",
      headerName: "Mode",
      valueFormatter: (params) => {
        return params?.value?.toUpperCase();
      },
      //   filter: "agDateColumnFilter",
      //   filterParams: filterParams,
      hide: false,
    },
    {
      field: "type",
      headerName: "Type",
      // valueFormatter: (params) => params.value,
    },
    {
      field: "amount",
      headerName: "Amount",
      // valueFormatter: (params) => {
      //   return parseFloat(params.value).toFixed(2);
      // },
      cellRenderer: (params) => {
        // console.log("The params are : ", params.data.type);
        if (params.data.type === "DEBIT") {
          return currSymbol + " " + parseFloat(params.value).toFixed(2);
        } else {
          return currSymbol + " " + parseFloat(params.value).toFixed(2);
        }
        // const updatedBookingId =
        //   "SFBOOKING_" + String(hotelId) + "_" + String(param.value);
        // return (
        //   <Link
        //     to={{
        //       pathname: `/folio/${updatedBookingId}`,
        //       search: `?hotelId=${hotelId}`,
        //     }}
        //     target="_blank"
        //   >
        //     {param.value}
        //   </Link>
        // );
      },
    },
    { field: "status", headerName: "Status" },
    // {
    //   field: "id",
    //   headerName: "Download Invoice",
    //   sortable: true,
    //   cellRendererFramework: (params) => {
    //     // console.log("params?.data?.type : ", params?.data?.type);
    //     if (
    //       (params?.data?.mode === "SUBSCRIPTION" ||
    //         params?.data?.mode === "subscription") &&
    //       params?.data?.type === "DEBIT" &&
    //       params?.data?.total_outstanding >= 0
    //     ) {
    //       if (
    //         params?.data?.accounting_version_change_date !== 0 &&
    //         params?.data?.invoice_date_epoc !== 0 &&
    //         params?.data?.invoice_date_epoc >
    //           params?.data?.accounting_version_change_date
    //       ) {
    //         // console.log("Inside if of cellrenderer before return");
    //         return (
    //           <Button
    //             variant="custom-button"
    //             style={{
    //               // fontSize: "16px",
    //               // cursor: "pointer",
    //               // color: "#49D0C9",
    //               // fontWeight: "bold",
    //               height: "2rem",
    //             }}
    //             onClick={() => {
    //               // console.log("Params are : ", params);
    //               // console.log("The id is ", params.value);
    //               handleOpenDInvoice(params.value);
    //               // setCurrentMessage(params.value);
    //               // onOpenMessageModal();
    //             }}
    //           >
    //             Download
    //           </Button>
    //         );
    //       } else {
    //         return "";
    //       }
    //     }
    //     return "";
    //   },
    // },
  ];

  // useEffect(() => {
  //   console.log(
  //     "accBalance : ",
  //     accBalance,
  //     " type of accBalance : ",
  //     typeof accBalance
  //   );

  //   if (accBalance) {
  //     console.log("In if block");
  //     let accBal = parseInt(accBalance);
  //     console.log("new accBal pasing : ", accBal);
  //     // debugger;
  //     if (accBal >= 0) {
  //       let flag = true;
  //       columns.map((columnObj) => {
  //         Object.entries(columnObj).map(([key, value]) => {
  //           if (key === "field" && value === "id") flag = false;
  //         });
  //       });
  //       if (flag) {
  //         columns.push({
  //           field: "id",
  //           headerName: "Download Invoice",
  //           sortable: true,
  //           // checkboxSelection: true,
  //           // headerCheckboxSelection: true,
  //           cellRendererFramework: (params) => {
  //             console.log("params?.data?.type : ", params?.data?.type);
  //             if (params?.data?.type === "DEBIT") {
  //               console.log("Inside if of cellrenderer before return");
  //               return (
  //                 <Button
  //                   variant="custom-button"
  //                   style={{
  //                     // fontSize: "16px",
  //                     // cursor: "pointer",
  //                     // color: "#49D0C9",
  //                     // fontWeight: "bold",
  //                     height: "2rem",
  //                   }}
  //                   onClick={() => {
  //                     console.log("Params are : ", params);
  //                     console.log("The id is ", params.value);
  //                     // setCurrentMessage(params.value);
  //                     // onOpenMessageModal();
  //                   }}
  //                 >
  //                   <u>Download</u>
  //                 </Button>
  //               );
  //             }
  //             return "";
  //           },
  //         });
  //       }
  //     }
  //   }
  // }, [accBalance]);

  const defaultColDefs = {
    sortable: true, //sort the table
    floatingFilter: true, // getting the filter below the columns
    tooltipField: "name",
    filter: "agMultiColumnFilter", // setting the multi column filter for the table
    display: "subMenu", // how the text filter and the set filter will look like
    components: {
      agDateInput: CustomDateComponent,
    },
    resizable: true,
    cellRendererParams: {
      checkbox: true,
    },
    flex: 1,
  };

  function updatePinnedRowonDateChange() {
    let result = [
      {
        date: "Total",
        amount: accBalance,
      },
    ];
    const data = rowsData?.forEach((data) => {
      result[0].amount = accBalance;
    });
    gridApi && gridApi.setPinnedBottomRowData(result);
  }

  const onSelectionChanged = (event) => {
    setSelectedRowsLength(event.api.getSelectedRows().length);
    setSelectedRows(event.api.getSelectedRows());
    const selectedBooking_Id = event.api.getSelectedRows()[0]?.bookingid;
    setSelectedBookingId(selectedBooking_Id);
    // console.log("Selected rows length", event.api.getSelectedRows().length)
  };

  const gridOptions = {
    pagination: true,
    onGridColumnsChanged: (params) => {
      //   console.log(
      //     "params : ",
      //     params.columnApi.columnController.displayedColumns
      //   );
    },
    // generation of the pinned bottom row data
    onFilterChanged: (params) => {
      let result = {
        date: "Total",
        amount: accBalance,
      };
      setTimeout(() => {
        params.api.forEachNodeAfterFilter((i) => {
          result.amount = accBalance;
        });
        params.api.setPinnedBottomRowData([result]);
      }, 0);
    },
    rowSelection: "multiple",
  };

  useEffect(() => {
    rowsData && updatePinnedRowonDateChange();
  });

  const onExportClick = () => {
    selectedRowsLength === 0
      ? gridApi.exportDataAsCsv()
      : gridApi.exportDataAsCsv({ onlySelected: true });
  };

  //  Exporting it in the Excel format
  const onExportExcel = () => {
    selectedRowsLength === 0
      ? gridApi.exportDataAsExcel()
      : gridApi.exportDataAsExcel({ onlySelected: true });
  };

  const getDownloadType = (dType) => {
    // console.log("download type", dType);
    dType === "1" && onExportClick();
    // dType === "2" && onOpenModal();
    dType === "3" && onExportExcel();
    // dType === "4" && onExportTally();
  };

  // Getting the number of enteries
  const [numEnteries, setNumEnteries] = useState(0);
  const [selectedNumEnteries, setSelectedNumEnteries] = useState(0);
  useEffect(() => {
    setSelectedNumEnteries(selectedRowsLength);
    setNumEnteries(rowsData?.length);
  }, [rowsData, selectedRowsLength]);

  // different date type fiters eg : checkin date, check out date, etc.
  const [filterDateType, setFilterDateType] = useState("staythrough");
  const onChangeDateFilter = (filterType) => {
    // console.log("date filter type", filterType);
    setFilterDateType(filterType);
  };

  const handleDate = (selectedDate) => {
    return moment(selectedDate).format("YYYY-MM-DD");
  };

  var date = new Date();
  const [datePicker, setDatePicker] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const filterParams = {
    debounceMs: 500,
    suppressAndOrCondition: true,
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      if (cellValue == null) {
        return 0;
      }
      //   console.log(moment(dateParts).format("DD-MM-YYYY"))
      var dateParts = moment(cellValue).format("DD-MM-YYYY");
      const formattedDateParts = dateParts.split("-");
      var year = Number(formattedDateParts[2]);
      var month = Number(formattedDateParts[1]) - 1;
      var day = Number(formattedDateParts[0]);
      var cellDate = new Date(year, month, day);

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    },
  };
  var recurringOptions = {
    key: razorpayFinalKey,
    // order_id: orderId,
    // customer_id: customerId,
    recurring: "1",
    handler: function (response) {
      // alert(response.razorpay_payment_id);
      // debugger;
      let payload = {
        group_id: groupId,
        "razorpay-pg-id": response.razorpay_payment_id,
        amount: 0,
        currency: "INR",
      };

      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/payments/razorpay/fetch-token-for-recurring-payments/?groupId=${groupId}&hotelId=${groupId}`,
        payload
      )
        .then((res) => {
          // console.log(res);
          toast.success("SuccessFully added recurring payment method");
          const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          getIndianPaymentMethodDetails();
          fetchPaymentMethodData();
          getNextInvoicingSchedule();
          setname("");
          setemail("");
          setcontact("");
          setauthType("netbanking");
          setbeneName("");
          setaccNumber(0);
          setaccountType("savings");
          setifsc("");
          handleCloseOpenEmandateModal();
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            "Error while setting up recurring payment method. Please try again later"
          );
          setname("");
          setemail("");
          setcontact("");
          setauthType("netbanking");
          setbeneName("");
          setaccNumber(0);
          setaccountType("savings");
          setifsc("");
          handleCloseOpenEmandateModal();
          setloading(false);
        });

      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
    },
    notes: {
      account_id: groupId,
    },
    theme: {
      color: "#F37254",
    },
  };

  const invokeRecurringRazorpayModal = () => {
    let rzp1 = new window.Razorpay(recurringOptions);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      setErrorShow(true);
      setErrorMessage(response?.error?.description);
      setTimeout(() => {
        setErrorShow(false);
      }, 4000);
    });
  };
  const [loading, setloading] = useState(false);

  const createCustomerAndOrder = () => {
    setloading(true);
    if (name === null || name === undefined || name === "") {
      toast.error("Please enter the name");
      setloading(false);
      return;
    }

    if (email === null || email === undefined || email === "") {
      toast.error("Please enter the email");
      setloading(false);
      return;
    }

    if (
      contact === null ||
      contact === undefined ||
      contact === "" ||
      contact.length > 10
    ) {
      toast.error("Please enter the valid contact number");
      setloading(false);
      return;
    }

    if (beneName === null || beneName === undefined || beneName === "") {
      toast.error("Please enter the beneficiary name");
      setloading(false);
      return;
    }

    if (accNumber === null || accNumber === undefined || accNumber === 0) {
      toast.error("Please enter the account number");
      setloading(false);
      return;
    }

    if (ifsc === null || ifsc === undefined || ifsc === "") {
      toast.error("Please enter the ifsc code");
      setloading(false);
      return;
    }

    let payload = {
      name: name,
      email: email,
      contact: contact,
      auth_type: "netbanking",
      beneficiary_name: beneName,
      account_number: accNumber,
      account_type: accountType,
      ifsc_code: ifsc,
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/payments/razorpay/create-customer-order-recurring-payments/?groupId=${groupId}&hotelId=${groupId}`,
      payload
    )
      .then((res) => {
        setcustomerId(res?.customer?.id);
        setorderId(res?.order?.id);
        // var recurringOptions = {
        //   key: razorpayFinalKey,
        //   order_id: orderId,
        //   customer_id: customerId,
        recurringOptions["customer_id"] = res?.customer?.id;
        recurringOptions["order_id"] = res?.order?.id;
        // doWork();
        setTimeout(() => {
          invokeRecurringRazorpayModal();
        }, 1000);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error("Please enter the valid details and try again");
        setname("");
        setemail("");
        setcontact("");
        setauthType("netbanking");
        setbeneName("");
        setaccNumber(0);
        setaccountType("savings");
        setifsc("");

        handleCloseOpenEmandateModal();
        setloading(false);
      });
  };

  const [state, setState] = useState({ right: false });

  // console.log("pmDetails : ", pmDetails);
  // console.log("nextPaySchedule : ", nextPaySchedule);
  // console.log("hotelCurr : ", hotelCurr);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  return (
    <div>
      {rowsData === null ? (
        <ReportsLoader />
      ) : (
        <div>
          {nextPaySchedule &&
            hotelCurr === "INR" &&
            indPaymentMethod === false && (
              <div>
                <Collapse in={banneropen}>
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setBannerOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Your next invoice will be automatically generated on{" "}
                    {moment(
                      nextPaySchedule?.next_schedule_date,
                      "DD/MM/YYYY"
                    ).format("Do MMMM, YYYY")}
                    , covering the period from{" "}
                    {moment(
                      nextPaySchedule?.schedule_start,
                      "DD/MM/YYYY"
                    ).format("Do MMMM, YYYY")}{" "}
                    to{" "}
                    {moment(nextPaySchedule?.schedule_end, "DD/MM/YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                    , for a total of {currSymbol} {nextPaySchedule?.amount}.
                    Please click on add payment method button to enroll for
                    autopay.
                  </Alert>
                </Collapse>
              </div>
            )}

          {nextPaySchedule &&
            hotelCurr === "INR" &&
            indPaymentMethod === true && (
              <div>
                <Collapse in={banneropen}>
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setBannerOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Your next invoice will be automatically generated on{" "}
                    {moment(
                      nextPaySchedule?.next_schedule_date,
                      "DD/MM/YYYY"
                    ).format("Do MMMM, YYYY")}
                    , covering the period from{" "}
                    {moment(
                      nextPaySchedule?.schedule_start,
                      "DD/MM/YYYY"
                    ).format("Do MMMM, YYYY")}{" "}
                    to{" "}
                    {moment(nextPaySchedule?.schedule_end, "DD/MM/YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                    , for a total of {currSymbol} {nextPaySchedule?.amount}.
                    <a
                      onClick={() => {
                        handleindPayOpen();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <b>
                        <u>Click here</u>
                      </b>{" "}
                    </a>
                    to view subscribed payment method.
                  </Alert>
                </Collapse>
              </div>
            )}

          {nextPaySchedule &&
            hotelCurr !== "INR" &&
            pmDetails &&
            pmDetails !== undefined && (
              <div>
                <Collapse in={banneropen}>
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setBannerOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Your next invoice will be automatically generated on{" "}
                    {moment(
                      nextPaySchedule?.next_schedule_date,
                      "DD/MM/YYYY"
                    ).format("Do MMMM, YYYY")}
                    , covering the period from{" "}
                    {moment(
                      nextPaySchedule?.schedule_start,
                      "DD/MM/YYYY"
                    ).format("Do MMMM, YYYY")}{" "}
                    to{" "}
                    {moment(nextPaySchedule?.schedule_end, "DD/MM/YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                    , for a total of {currSymbol} {nextPaySchedule?.amount}.
                    Visit Payment Methods page to manage subscribed payment
                    method.
                  </Alert>
                </Collapse>
              </div>
            )}

          {nextPaySchedule &&
            hotelCurr !== "INR" &&
            (pmDetails === null || pmDetails === undefined) && (
              <div>
                <Collapse in={banneropen}>
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setBannerOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Your next invoice will be automatically generated on{" "}
                    {moment(
                      nextPaySchedule?.next_schedule_date,
                      "DD/MM/YYYY"
                    ).format("Do MMMM, YYYY")}
                    , covering the period from{" "}
                    {moment(
                      nextPaySchedule?.schedule_start,
                      "DD/MM/YYYY"
                    ).format("Do MMMM, YYYY")}{" "}
                    to{" "}
                    {moment(nextPaySchedule?.schedule_end, "DD/MM/YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                    , for a total of {currSymbol} {nextPaySchedule?.amount}.
                    Please add a payment method to enroll for autopay.
                  </Alert>
                </Collapse>
              </div>
            )}

          <div style={{ display: "flex", justifyContent: "end" }}>
            {accBalance && (
              <Typography
                variant="h6"
                style={{ fontWeight: "400", marginBottom: "1rem" }}
              >
                Current Outstanding : {currSymbol + " " + accBalance}
              </Typography>
            )}
            {hotelCurr !== "INR" && (
              <Button
                variant="custom-button"
                style={{
                  fontWeight: "400",
                  marginLeft: "2rem",
                  marginBottom: "1rem",
                  height: "2rem",
                }}
                onClick={() => {
                  fetchPaymentMethodData2();
                  // console.log("res is : ", res);
                  // if (res === true) handleOpen();
                  // else
                  //   toast.error(
                }}
              >
                Pay Now
              </Button>
            )}

            {hotelCurr === "INR" && (
              <Button
                variant="custom-button"
                style={{
                  fontWeight: "400",
                  marginLeft: "2rem",
                  marginBottom: "1rem",
                  height: "2rem",
                }}
                onClick={() => {
                  // fetchPaymentMethodData();
                  // handleOpen();
                  handleAmountOpen();
                }}
              >
                Pay Now
              </Button>
            )}

            {/* Commenting out this feature because it requires testing in prod will create hotfix for this after testing */}
            {hotelCurr === "INR" && indPaymentMethod === false && (
              <Button
                variant="custom-button"
                style={{
                  fontWeight: "400",
                  marginLeft: "2rem",
                  marginBottom: "1rem",
                  height: "2rem",
                }}
                onClick={() => {
                  // fetchPaymentMethodData();
                  // handleOpen();
                  // handleAmountOpen();
                  handleoOpenEmandateModal();
                }}
              >
                Setup autopay
              </Button>
            )}
          </div>

          <div>
            <Stack className="agGridWrapr position-relative">
              <Stack
                spacing={2}
                className="agGridTableWrapper position-relative w-100"
              >
                <Stack
                  direction="row"
                  className="position-relative w-100 justify-content-between"
                >
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="outlined-basic"
                      onChange={onFilterTextChange}
                      label="Search"
                      size="small"
                    />
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Pages
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={gridApi?.paginationPageSize}
                          label="Pages"
                          onChange={(e) => onPaginationChange(e.target.value)}
                        >
                          <MenuItem value={"10"}>10</MenuItem>
                          <MenuItem value={"20"}>25</MenuItem>
                          <MenuItem value={"50"}>50</MenuItem>
                          <MenuItem value={"100"}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {/* <ClickAwayListenerHelper
                        clickAwayButtonName={
                          <>
                            <div className="calIcon">
                              <AiIcons.AiOutlineCalendar size="20px" black />
                            </div>
                            <div className="calDate">
                              {moment(datePicker[0].startDate).format(
                                "MMM DD, YYYY"
                              ) +
                                " - " +
                                moment(datePicker[0].endDate).format(
                                  "MMM DD, YYYY"
                                )}
                            </div>
                          </>
                        }
                        // clickAwayButtonName = "Display calendar"
                        btnClassName="displayCalendarBtn"
                        content={
                          <div className="cal position-relative w-100 d-flex flex-column">
                            <DateRangePicker
                              editableDateInputs={true}
                              onChange={(item) =>
                                setDatePicker([item.selection])
                              }
                              moveRangeOnFirstSelection={false}
                              ranges={datePicker}
                              staticRanges={defaultStaticRanges}
                            />
                            <div className="position-relative d-flex w-100 justify-content-end">
                              <SecondaryButton
                                text="Apply"
                                onClick={() => getAllExpensesData()}
                              />
                            </div>
                          </div>
                        }
                      /> */}
                    {/* <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small" size="small">
                        <InputLabel id="demo-simple-select-label">
                          Filters
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterDateType}
                          label="Filters"
                          onChange={(e) => onChangeDateFilter(e.target.value)}
                        >
                          <MenuItem value={"staythrough"}>
                            Stay Through
                          </MenuItem>
                          <MenuItem value={"checkin"}>Checkin Date</MenuItem>
                          <MenuItem value={"checkout"}>Checkout Date</MenuItem>
                          <MenuItem value={"booking_made_on"}>
                            Booking Made Date
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box> */}
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Export as
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={"0"}
                          label="Export as"
                          onChange={(e) => getDownloadType(e.target.value)}
                        >
                          <MenuItem value={"0"}>Select option</MenuItem>
                          <MenuItem value={"1"}>Export as CSV</MenuItem>
                          {/* <MenuItem value={"2"}>Export as PDF</MenuItem> */}
                          {/* <MenuItem value={"3"}>Export as Excel</MenuItem> */}
                          {/* <MenuItem value={"4"}>Export as Tally</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Box>
                  </Stack>
                  {/* <Stack>
                      <Button
                        variant="custom-button"
                        onClick={() => {
                          if (
                            (expanse && expanse.length > 0) ||
                            (topup && topup.length > 0) ||
                            (withdrawal && withdrawal.length > 0)
                          ) {
                            openAddCustomReport();
                          } else {
                            toast.error(
                              "Please create expense types in property configuration for adding new expenses"
                            );
                          }
                        }}
                      >
                        Add expenses
                      </Button>
                    </Stack> */}
                </Stack>
                {rowsData && hotelCurr && hotelCurr === "INR" ? (
                  <Stack className="agTable">
                    <Stack className="ag-theme-material">
                      <AgGridReact
                        className="agGridTable"
                        rowData={rowsData}
                        onGridReady={onGridReady}
                        columnDefs={columnsInr}
                        defaultColDef={defaultColDefs}
                        enableBrowserTooltips={true}
                        onSelectionChanged={onSelectionChanged}
                        enableMultiRowDragging={false}
                        rowMultiSelectWithClick={false}
                        paginationPageSize={50}
                        alwaysShowBothConditions={true}
                        rowSelection={rowSelectionType}
                        suppressPivotMode={true}
                        suppressColumnMove={true}
                        suppressRowGroups={true}
                        sideBar={sideBar()}
                        groupMultiAutoColumn={true}
                        // rowHeight={32.5}
                        statusBar={{
                          statusPanels: [
                            {
                              statusPanel:
                                "agTotalAndFilteredRowCountComponent",
                              align: "left",
                            },
                            {
                              statusPanel: "agTotalRowCountComponent",
                              align: "center",
                            },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" },
                          ],
                        }}
                        gridOptions={gridOptions}
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <Stack className="agTable">
                    <Stack className="ag-theme-material">
                      <AgGridReact
                        className="agGridTable"
                        rowData={rowsData}
                        onGridReady={onGridReady}
                        columnDefs={columnsUsd}
                        defaultColDef={defaultColDefs}
                        enableBrowserTooltips={true}
                        onSelectionChanged={onSelectionChanged}
                        enableMultiRowDragging={false}
                        rowMultiSelectWithClick={false}
                        paginationPageSize={50}
                        alwaysShowBothConditions={true}
                        rowSelection={rowSelectionType}
                        suppressPivotMode={true}
                        suppressColumnMove={true}
                        suppressRowGroups={true}
                        sideBar={sideBar()}
                        groupMultiAutoColumn={true}
                        // rowHeight={32.5}
                        statusBar={{
                          statusPanels: [
                            {
                              statusPanel:
                                "agTotalAndFilteredRowCountComponent",
                              align: "left",
                            },
                            {
                              statusPanel: "agTotalRowCountComponent",
                              align: "center",
                            },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" },
                          ],
                        }}
                        gridOptions={gridOptions}
                      />
                    </Stack>
                  </Stack>
                )}
              </Stack>

              {/* <Modal
              classNames={{ overlay: "customOverlay", modal: "customModal" }}
              open={open}
              onClose={onCloseModal}
              center
            >
              <div className="pdfExportWrapper">
                <PDFExportPanel
                  gridApi={gridApi}
                  columnApi={gridColumnApi}
                  report_Type={reportType}
                  startdate={moment(datePicker[0].startDate).format(
                    "MMM DD YYYY"
                  )}
                  enddate={moment(datePicker[0].endDate).format("MMM DD YYYY")}
                  numEnteries={numEnteries}
                  selectedNumEnteries={selectedNumEnteries}
                  hotelLogo={data.hotelLogoUrl}
                  docName={docName}
                />
              </div>
            </Modal> */}
            </Stack>
            {/* <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "#F5F8FA" }}>
                  <TableRow>
                    <TableCell>Transaction date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Mode</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ledger &&
                    ledger.map((res) => (
                      <TableRow
                        key="xyz"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {moment(res.date).format("LLL")}
                        </TableCell>
                        <TableCell>{res.description}</TableCell>
                        <TableCell>{res.mode}</TableCell>
                        <TableCell>{res.type}</TableCell>
                        <TableCell>
                          {res.type === "DEBIT"
                            ? currSymbol + " -" + res.amount
                            : currSymbol + " " + res.amount}
                        
                        </TableCell>
                      
                        <TableCell>{res.status}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </div>
        </div>
      )}

      <Modal
        open={openindPay}
        onClose={handleindPayClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal">
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Payment method details</div>
              <div className="close" onClick={() => handleindPayClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              {/* <div className="fieldWrapper">
                <TextField
                  required
                  id="outlined-name"
                  label="Enter the amount"
                  name="deposit_amount"
                  value={depositAmt}
                  onChange={(e) =>
                    /^[0-9.]*$/.test(e.target.value)
                      ? setDepositAmt(e.target.value)
                      : setDepositAmt(depositAmt)
                  }
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div> */}

              {indPaymentDetail && (
                <div className="fieldWrapper">
                  <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "1rem" }}>Payment method type</p>
                    <p>-</p>
                    <p style={{ marginLeft: "1rem" }}>Net-Banking</p>
                  </div>

                  <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "1rem" }}>Account name</p>
                    <p>-</p>
                    <p style={{ marginLeft: "1rem" }}>
                      {indPaymentDetail?.payment_method}
                    </p>
                  </div>

                  <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "1rem" }}>Account last 4</p>
                    <p>-</p>
                    <p style={{ marginLeft: "1rem" }}>
                      {indPaymentDetail?.bank_last4}
                    </p>
                  </div>

                  {/* {pmDetails?.payment_method_type === "account" ? ( */}
                </div>
              )}
              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleindPayClose()}
                >
                  Close
                </Button>
                {/* <Button
                  className="submit"
                  variant="custom-button"
                  onClick={handleFortisPayment}
                  disabled={depositLoading || depositAmt <= 0}
                >
                  {depositLoading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button> */}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal">
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Confirm the details</div>
              <div className="close" onClick={() => handleClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  required
                  id="outlined-name"
                  label="Enter the amount"
                  name="deposit_amount"
                  value={depositAmt}
                  onChange={(e) =>
                    /^[0-9.]*$/.test(e.target.value)
                      ? setDepositAmt(e.target.value)
                      : setDepositAmt(depositAmt)
                  }
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>

              {pmDetails && (
                <div className="fieldWrapper">
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "600", marginBottom: "1rem" }}
                  >
                    Payment method details
                  </Typography>
                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Payment method type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>Account</p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Payment method type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_type === "credit"
                          ? "Credit card"
                          : "Debit card"}
                      </p>
                    </div>
                  )}
                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Account last 4</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.ach_account_last4}
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Card last 4</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_last4}
                      </p>
                    </div>
                  )}

                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Account type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.ach_account_type}
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Card issuer</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_issuer.toUpperCase()}
                      </p>
                    </div>
                  )}
                </div>
              )}
              <div className="settleDuesSubmitBtns">
                <Button className="cancelBtn" onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button
                  className="submit"
                  variant="custom-button"
                  onClick={handleFortisPayment}
                  disabled={depositLoading || depositAmt <= 0}
                >
                  {depositLoading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Pay"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDInvoice}
        onClose={handleCloseDInvoice}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal">
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Download invoice</div>
              <div className="close" onClick={() => handleCloseDInvoice()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography>Do you want to download the invoice</Typography>
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleCloseDInvoice()}
                >
                  Cancel
                </Button>
                <a
                  // className="submit"
                  // variant="custom-button"
                  // onClick={downloadInvoice}
                  type="button"
                  disabled={load}
                  href={
                    BASE_SPRING_ENDPOINT +
                    "core/api/v1/account-ledger/download-invoice/" +
                    groupId +
                    "/" +
                    currentLedgerId +
                    "/?groupId=" +
                    groupId +
                    "&hotelId=" +
                    groupId
                  }
                >
                  {load ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Download"
                  )}
                </a>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openAmountModal}
        onClose={handleAmountClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal">
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Confirm the details</div>
              <div className="close" onClick={() => handleAmountClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  required
                  id="outlined-name"
                  label="Enter the amount"
                  name="deposit_amount"
                  disabled={true}
                  value={razorpayAmount}
                  onChange={(e) => {
                    /^[0-9.]*$/.test(e.target.value)
                      ? setRazorpayAmount(e.target.value)
                      : setRazorpayAmount(razorpayAmount);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleAmountClose()}
                >
                  Cancel
                </Button>
                <Button
                  className="submit"
                  variant="custom-button"
                  onClick={() => {
                    handleAmountClose();
                    let amt = razorpayAmount.toString().split(".");
                    let final = amt[0];
                    options["amount"] = final * 100 + "";

                    setRazorpayAmount(final * 100 + "");
                    invokeRazorpayModal();
                  }}
                  disabled={razorpayAmount <= 0}
                >
                  {/* {depositLoading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : ( */}
                  Pay
                  {/* )} */}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openEmandateModal}
        onClose={handleCloseOpenEmandateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal">
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">
                Enter the bank account details
              </div>
              <div
                className="close"
                onClick={() => {
                  setloading(false);
                  handleCloseOpenEmandateModal();
                }}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter your business name"
                  name="deposit_amount"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter email"
                  name="deposit_amount"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter contact number"
                  name="deposit_amount"
                  value={contact}
                  onChange={(e) => {
                    /^[0-9.]*$/.test(e.target.value)
                      ? setcontact(e.target.value)
                      : setcontact(contact);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter beneficiary name"
                  name="deposit_amount"
                  value={beneName}
                  onChange={(e) => {
                    setbeneName(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter account number"
                  name="deposit_amount"
                  value={accNumber}
                  onChange={(e) => {
                    /^[0-9.]*$/.test(e.target.value)
                      ? setaccNumber(e.target.value)
                      : setaccNumber(accNumber);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <FormControl
                  fullWidth
                  sx={{ width: "12rem", marginRight: "0.5rem" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Account type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={accountType}
                    onChange={(e) => {
                      setaccountType(e.target.value);
                    }}
                    label="Account type"
                  >
                    <MenuItem value={"savings"}>Savings</MenuItem>
                    <MenuItem value={"current"}>Current</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter ifsc"
                  name="deposit_amount"
                  value={ifsc}
                  onChange={(e) => {
                    setifsc(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => {
                    setloading(false);
                    handleCloseOpenEmandateModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="submit"
                  variant="custom-button"
                  onClick={() => {
                    createCustomerAndOrder();
                  }}
                  // disabled={razorpayAmount <= 0}
                >
                  {loading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(Transactions);
// export default Transactions;
