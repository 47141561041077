import { Alert, Box, Typography } from "@mui/material";
import React from "react";
import "./freezeStyle.css";
import { getAllImagesAndUrl } from "../pages/BookingDashboard/apis/BrandingModalApi";
import { useEffect } from "react";
import { useState } from "react";

function Freeze() {
  //------------whitelabelled------------------------------
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    userBrandingSatus();
  }, []);
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        // height: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        // p: 4,
        borderRadius: 1,
      }}
    >
      <Box className="freezebody">
        <div class="lock"></div>
        <div class="message">
          <Typography align="center" sx={{ mt: 1, fontSize: "1.6rem" }}>
            Access restricted
          </Typography>
          <Typography align="center" sx={{ mt: 1, fontSize: "1.4rem" }}>
            To proceed with uninterrupted use of the PMS, Please settle the
            outstanding payment. Kindly get in touch with our accounts team
            {whiteLabelled === "DISABLED" && " at "}
            {whiteLabelled === "DISABLED" && (
              <>
                <a
                  href="mailto:teamaccounts@stayflexi.com"
                  style={{ color: "white" }}
                >
                  teamaccounts@stayflexi.com
                </a>{" "}
                or give us a call at{" "}
                <a href="tel:+91 9591409003" style={{ color: "white" }}>
                  {" "}
                  +91 9591409003
                </a>
              </>
            )}{" "}
            to clear the pending payment. Your prompt attention to this matter
            is greatly appreciated.
          </Typography>
        </div>
      </Box>
      {/* <Alert severity="error" sx={{ fontSize: "2rem", p: 4 }}>
        Access Restricted. To proceed with uninterrupted use of the PMS, Please
        settle the outstanding payment. Kindly get in touch with our accounts
        team at{" "}
        <a href="mailto:teamaccounts@stayflexi.com">
          {" "}
          teamaccounts@stayflexi.com
        </a>{" "}
        or give us a call at <a href="tel:+91 84204 82645">
          {" "}
          +91 84204 82645
        </a>{" "}
        to clear the pending payment. Your prompt attention to this matter is
        greatly appreciated.
      </Alert> */}
    </Box>
  );
}

export default Freeze;
