import React from "react";
import {
  CardContent,
  Grid,
  FormControl,
  Skeleton,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
} from "@mui/material";

const RightContentSectionLoader = () => {
  return (
    <CardContent sx={{ overflowY: "auto", flex: 1 }}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs={4} key={index}>
            <FormControl sx={{ maxWidth: 500 }} fullWidth>
              <Skeleton variant="text" width={100} height={24} />
              <Skeleton
                variant="rectangular"
                height={40}
                sx={{ borderRadius: 1 }}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                {Array.from({ length: 5 }).map((_, index) => (
                  <TableCell>
                    <Skeleton
                      key={index}
                      variant="text"
                      width={100}
                      height={24}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: 3 }).map((_, index) => (
                <TableRow key={index}>
                  {Array.from({ length: 5 }).map((_, cellIndex) => (
                    <TableCell>
                      <Skeleton
                        key={cellIndex}
                        variant="rectangular"
                        width={100}
                        height={30}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CardContent>
  );
};

export default RightContentSectionLoader;
