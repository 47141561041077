import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Button,
  Checkbox,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import { getAllRoomTypess } from "../../RevenueManagement/RatePlans/apis/RatePlanApis";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { connect } from "react-redux";
import { getAvailableRoom } from "../../FlexibleSlot/api/FlexibleSlotApi";
import moment from "moment";
import { spliRoomCosts, splitRooms } from "./SplitRoomApi";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import {
  getBookingData,
  handleGetDoorLockDetails,
  invalidateNightAuditReservation,
} from "../api/ReservationApi";
import { getAllNonHiddenRoomTypes } from "../../InventoryCalendar/api/InventoryApi";
import Stack from "@mui/material/Stack";

import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { addNewBookingNote } from "../api/BookingAndCustomerNotesApi";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const MoveRoom = (props) => {
  const {
    closeModal,
    closeBackDrop,
    title,
    buttonText,
    load,
    reservation_id,
    bookingId,
    reservationData,
    reload,
    selectedLanguage,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const classes = useStyles();
  /* Booking Info */
  const [bookingInfo, setBookingInfo] = useState(null);
  // upgrade price loader
  const [upgradeLoader, setUpgradeLoader] = useState(false);
  const [remark, setremark] = useState("");
  //get all avaiable room  ids
  const [roomIds, setRoomIds] = useState([]);

  const [openDirtyModal, setopenDirtyModal] = useState(false);
  const [isRoomDirty, setisRoomDirty] = useState(false);
  const [showButtonOfPricing, setshowButtonOfPricing] = useState("");
  const [selectedRatePlan, setselectedRatePlan] = useState("");
  const [allRatePlans, setallRatePlans] = useState([]);
  const [ratePlanObj, setratePlanObj] = useState({});
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const openDirtysModal = () => setopenDirtyModal(true);
  const closeDirtysModal = () => setopenDirtyModal(false);
  function getBookingInfo() {
    setUpgradeLoader(true);
    djangoHandleDataRequests(
      `invoice/getBookingInfoById?bookingID=${bookingId}`
    )
      .then((res) => {
        setBookingInfo(res);
        setUpgradeLoader(false);
      })
      .catch((err) => {
        toast.error(`${t("Error fetching the data.")}`);
        setUpgradeLoader(false);
      });
  }

  useEffect(() => {
    setUpgradeLoader(true);
    getBookingInfo();
    setUpgradeLoader(false);
  }, []);

  // getting all the room types
  const [roomTypes, setRoomTypes] = useState(null);

  // Handling getting all the room types
  function handleGetAllRoomTypes() {
    setUpgradeLoader(true);
    handleSpringDataRequest("core/api/v1/reservation/getAllNonHiddenRoomTypes")
      .then((res) => {
        setRoomTypes(res);
        setRoomType();
        setUpgradeLoader(false);
        //   Object.keys(res).find(
        //     (key) =>
        //       res[key] ===
        //       invoiceData?.invoice.reservation_room_info_list[0].roomTypeName
        //   )
      })
      .catch((err) => {
        toast.error(`${t("Error getting all the room types.")}`);
        setUpgradeLoader(false);
      });
  }

  useEffect(() => {
    setUpgradeLoader(true);
    handleGetAllRoomTypes();
    setUpgradeLoader(false);
  }, []);

  // choosing room type
  const [roomType, setRoomType] = useState(
    roomTypes ? Object.keys(roomTypes)[0] : ""
  );
  const handleSelectRoomTypeChange = (event) => {
    setRoomType(event.target.value);
  };

  useEffect(() => {
    setUpgradeLoader(true);
    roomTypes && setRoomType(Object.keys(roomTypes)[0]);
    setUpgradeLoader(false);
  }, [roomTypes]);

  // getting the unblocked rooms based on the room type id
  const [allRooms, setAllRooms] = useState(null);
  function handleGetUnblockedRooms() {
    setUpgradeLoader(true);
    handleSpringDataRequest(
      `core/api/v1/room/non-blocked-rooms-with-status/?resId=${reservation_id}&roomTypeId=${
        roomType === "" ? "" : roomType
      }`
    )
      .then((res) => {
        setAllRooms(res);
        setRoomIds(res);
        setSelectedRoom(res[0]?.roomId);
        setUpgradeLoader(false);
      })
      .catch((error) => {
        console.log(`${t("Error in fetching the room ids.")}`);
        setUpgradeLoader(false);
      });
  }

  useEffect(() => {
    setUpgradeLoader(true);
    roomType && bookingInfo && handleGetUnblockedRooms();
    setUpgradeLoader(false);
  }, [roomType, bookingInfo]);

  // getting the selected room
  const [selectedRoom, setSelectedRoom] = useState("");

  // rate types and the rate
  const [rateType, setRateType] = useState("TOTAL_PRETAX");
  const [newRate, setNewRate] = useState(null);

  // handling the room upgrade cost
  // Adding up the loader while calling the upgrade API
  const [upgradeCost, setUpgradeCost] = useState(null);
  function handleGetUpgradeCost(val) {
    setUpgradeLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/getUpgradePricePayload",
      {
        destRoomTypeId: roomType === "null" ? null : roomType,
        hotelId: props.data.selectedHotel.hotelId,
        newRate: parseInt(newRate),
        newRateType: rateType,
        reservationId: bookingInfo?.res_id_list[0],
        destRoomId: selectedRoom,
        destRatePlanId: checked
          ? val === undefined || val === null
            ? selectedRatePlan
            : val
          : null,
      }
    )
      .then((res) => setUpgradeCost(res))
      .then((res) => {
        setUpgradeLoader(false);
        setshowButtonOfPricing("");
      })
      .catch((err) => {
        console.log(`${t("Error getting the prices.")}`);
        setUpgradeLoader(false);
        setshowButtonOfPricing("");
      });
  }

  useEffect(() => {
    if (roomType === "null") {
      handleGetUpgradeCost();
    }
  }, [selectedRoom]);

  useEffect(() => {
    const timer = setTimeout(() => {
      bookingInfo && handleGetUpgradeCost();
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [rateType, newRate, roomType, bookingInfo]);

  useEffect(() => {
    setUpgradeLoader(true);
    if (upgradeCost) {
      setPricePerNight(upgradeCost.perNightPrice.toFixed(2));
      setTotalBeforeTax(upgradeCost.priceBeforeTax.toFixed(2));
      setTotalTax(upgradeCost.taxValue.toFixed(2));
      setTotalWithTax(upgradeCost.priceAfterTax.toFixed(2));
    }
    setUpgradeLoader(false);
  }, [upgradeCost]);

  // price per change
  const [pricePerNight, setPricePerNight] = useState(0);
  const handlePricePerNightChange = (event) => {
    setshowButtonOfPricing("perNightPrice");
    setPricePerNight(event.target.value);
  };

  // Total price before tax
  const [totalBeforeTax, setTotalBeforeTax] = useState(null);
  const handleTotalBeforeTaxChange = (event) => {
    setshowButtonOfPricing("priceBeforeTax");
    setTotalBeforeTax(event.target.value);
  };

  // Total tax value
  const [totalTax, setTotalTax] = useState(null);
  const handleTotalTaxChange = (event) => {
    setTotalTax(event.target.value);
  };

  // Total price after tax
  const [totalWithTax, setTotalWithTax] = useState(null);
  const handleTotalWithTaxChange = (event) => {
    setshowButtonOfPricing("priceAfterTax");
    setTotalWithTax(event.target.value);
  };

  const moveRes = (str) => {
    setUpgradeLoader(true);
    if (str === "YES") {
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-dirty`,
          {
            roomIds: [reservationData?.reservation_rooms],
            staffId: "",
            hotelId: props.data.selectedHotel.hotelId,
          }
        )
        .then((res) => handleUpgradeReservation())
        .catch((err) => handleUpgradeReservation());
    } else {
      handleUpgradeReservation();
    }
  };
  //handle clean  new room
  const handleCleanRoom = (room) => {
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-clean`,
        {
          roomIds: room,
          staffId: "",
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };
  const handleCheckCleanStatus = (roomId) => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/get-room-dirty-status`,
      [roomId]
    ).then((data) => {
      const arr = [];
      Object.keys(data).map((elm) => {
        if (elm !== "UNASSIGNED" && data[elm] === "DIRTY") {
          arr.push(elm);
        }
      });
      arr.length > 0 && handleCleanRoom(arr);
    });
  };
  // Handle upgrade room
  async function handleUpgradeReservation() {
    if (
      JSON.parse(
        props?.data?.selectedHotel?.accessControl?.user_feature_map
          ?.MoveSwapRoomRemark
      ).read === "YES" &&
      remark.length <= 0 &&
      props?.data?.selectedHotel?.accessControl?.ADMINRIGHTS !== "ADMIN" &&
      props?.data?.selectedHotel?.accessControl?.ADMINRIGHTS !== "SUBADMIN"
    ) {
      toast.error(`${t("Please add remark")}`);
    } else {
      setUpgradeLoader(true);
      handleCheckCleanStatus(selectedRoom);
      // handleCleanRoom(selectedRoom);
      try {
        const res = await axios
          .post(
            `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/moveReservation`,
            {
              destRoomTypeId: roomType === "" ? null : roomType,
              hotelId: props.data.selectedHotel.hotelId,
              moveCost: upgradeCost.priceAfterTax,
              physicalRoomId: selectedRoom,
              reservationId: reservation_id,
              remark: remark,
              destRatePlanId: checked ? selectedRatePlan : null,
              destRatePlan: checked ? ratePlanObj[selectedRatePlan] : null,
            }
          )
          .then(() => {
            closeModal();
          });
        await handleGetDoorLockDetails({
          bookingId: bookingId,
          reservationId: reservation_id,
          actionRequired: "recreate",
        });
        await invalidateNightAuditReservation(reservation_id);
        if (remark.length > 0) {
          addBookingNotes();
        }
        await reload();
        toast.success(`${t("Successfully moved/upgraded")} ${bookingId}`);
      } catch (err) {
        toast.error(`${t("Error performing the operation.")}`);
      }
      setUpgradeLoader(false);
      closeDirtysModal();
    }
  }

  const addBookingNotes = async () => {
    const payload = {
      hotelId: props.data.selectedHotel.hotelId,
      bookingID: bookingId,
      note:
        "EMAIL :- " +
        localStorage.getItem("email") +
        "|| MOVE ROOM ||" +
        remark,
      logged_in_user: localStorage.getItem("email"),
    };
    await addNewBookingNote(payload);
  };

  useEffect(() => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/get-room-dirty-status`,
      [reservationData?.reservation_rooms]
    )
      .then((res) => {
        const datas = Object.values(res).filter((e) => e === "DIRTY");
        if (datas?.length > 0) {
          setisRoomDirty(true);
        } else {
          setisRoomDirty(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getRatePlans = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/get-room-id-rate-plan?reservationId=${reservation_id}&physicalRoomId=${selectedRoom}`
    )
      .then((res) => {
        const ratePlanObj = res.reduce((acc, item) => {
          acc[item.id] = item.name;
          return acc;
        }, {});

        setratePlanObj(ratePlanObj); // Store the transformed object

        if (res.length > 0) {
          setallRatePlans(Object.entries(ratePlanObj)); // Keep original array for UI or dropdown list

          // Select the first rate plan's id as default
          setselectedRatePlan(res[0].id);

          // Call handleGetUpgradeCost with the first rate plan's id
          handleGetUpgradeCost(res[0].id);
        }
      })
      .catch((err) => toast.error("Error in getting the prices"));
  };

  useEffect(() => {
    if (
      selectedRoom !== null &&
      selectedRoom !== undefined &&
      selectedRoom !== "" &&
      checked
    ) {
      getRatePlans();
    }
  }, [selectedRoom, checked]);

  useEffect(() => {
    if (!checked) {
      handleGetUpgradeCost();
    }
  }, [checked]);

  return (
    <div className={classes.root}>
      <CommonSlider onBackdropClick={closeBackDrop} open={true}>
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: "right" }} onClick={closeModal}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} className={classes.textField}> */}
        <div className="d-flex mb-4">
          <TextField
            fullWidth
            disabled
            label={t("Previous room id")}
            value={reservationData?.reservation_rooms}
            sx={{ marginLeft: "2rem", width: "24rem" }}
          />
          <FormControl disabled={upgradeLoader ? true : false}>
            <InputLabel
              sx={{ marginLeft: "2rem" }}
              id="demo-simple-select-label"
            >
              {t("Choose room ID")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="outlined-select-currency"
              select
              label={t("Choose room ID")}
              value={selectedRoom}
              onChange={(e) => {
                setSelectedRoom(e.target.value);
              }}
              // helperText="Choose Room Type"
              sx={{ marginLeft: "2rem", width: "24rem" }}
            >
              {roomIds.map((id) => (
                <MenuItem
                  key={id?.roomId}
                  value={id?.roomId}
                  style={{
                    color: `${
                      id?.dirtyStatus === "CLEAN" ? "#1BC5BD" : "#B96E6E"
                    }`,
                    fontWeight: "bold",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    // style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ color: "black" }}>{id.roomId}</Typography>

                    <Typography
                      sx={{
                        color: `${
                          id?.dirtyStatus === "CLEAN" ? "#1BC5BD" : "#B96E6E"
                        }`,
                      }}
                    >
                      {id.dirtyStatus}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="d-flex mb-4">
          <TextField
            fullWidth
            disabled
            label={t("Previous room name")}
            value={reservationData?.room_type_name}
            sx={{ marginLeft: "2rem", width: "24rem" }}
          />
          <FormControl disabled={upgradeLoader ? true : false}>
            <InputLabel
              sx={{ marginLeft: "2rem" }}
              id="demo-simple-select-label"
            >
              {t("Choose room type")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="outlined-select-currency"
              select
              label={t("Choose room type")}
              value={roomType}
              onChange={handleSelectRoomTypeChange}
              // helperText="Choose Room Type"
              sx={{ marginLeft: "2rem", width: "24rem" }}
            >
              <MenuItem value={"null"}> {t("All")} </MenuItem>
              {roomTypes &&
                Object.entries(roomTypes).map(([key, value]) => (
                  <MenuItem value={key}> {value} </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div
          className="d-flex flex-column mb-4"
          style={{ marginLeft: "2rem", marginRight: "5rem" }}
        >
          <div
            className="d-flex"
            style={{ alignItems: "center", marginBottom: "1rem" }}
          >
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <p
              style={{
                marginLeft: "0.5rem",
                marginBottom: "0px",
              }}
            >
              Edit rate plan
            </p>
          </div>
          {checked && (
            <TextField
              label={"Rate plan"}
              name="ratePlan"
              id="ratePlan"
              required
              fullWidth
              select
              onChange={(e) => {
                setselectedRatePlan(e.target.value);
                handleGetUpgradeCost(e.target.value);
              }}
              value={selectedRatePlan}
            >
              {allRatePlans.map((id) => (
                <MenuItem key={id[0]} value={id[0]}>
                  {id[1]}
                </MenuItem>
              ))}
            </TextField>
          )}
        </div>
        <div className="d-flex mb-4">
          <div className="d-flex">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Price per night")}
              multiline
              value={pricePerNight}
              onChange={handlePricePerNightChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ marginLeft: "2rem", width: "24rem" }}
              disabled={
                JSON.parse(
                  props?.data?.selectedHotel?.accessControl?.user_feature_map
                    ?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : upgradeLoader
                  ? true
                  : false
              }
            />

            {showButtonOfPricing === "perNightPrice" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setUpgradeLoader(true);
                  setRateType("PER_NIGHT_PRETAX");
                  setNewRate(pricePerNight);
                }}
                disabled={upgradeLoader}
              >
                {upgradeLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>

          <div className="d-flex">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Total before tax")}
              multiline
              // rows={1}
              value={totalBeforeTax}
              onChange={handleTotalBeforeTaxChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ marginLeft: "2rem", width: "24rem" }}
              disabled={
                JSON.parse(
                  props?.data?.selectedHotel?.accessControl?.user_feature_map
                    ?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : upgradeLoader
                  ? true
                  : false
              }
            />

            {showButtonOfPricing === "priceBeforeTax" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setUpgradeLoader(true);
                  setRateType("TOTAL_PRETAX");
                  setNewRate(totalBeforeTax);
                }}
                disabled={upgradeLoader}
              >
                {upgradeLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex mb-4">
          <TextField
            id="outlined-multiline-flexible"
            label={t("Total tax")}
            multiline
            // rows={1}
            value={totalTax}
            onChange={handleTotalTaxChange}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginLeft: "2rem", width: "24rem" }}
          />

          <div className="d-flex">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Total with tax")}
              multiline
              // rows={1}
              value={totalWithTax}
              onChange={handleTotalWithTaxChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ marginLeft: "2rem", width: "24rem" }}
              disabled={
                JSON.parse(
                  props?.data?.selectedHotel?.accessControl?.user_feature_map
                    ?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : upgradeLoader
                  ? true
                  : false
              }
            />

            {showButtonOfPricing === "priceAfterTax" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setUpgradeLoader(true);
                  setRateType("TOTAL_WITHTAX");
                  setNewRate(totalWithTax);
                }}
                disabled={upgradeLoader}
              >
                {upgradeLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>
        </div>
        <TextField
          onChange={(e) => setremark(e.target.value)}
          style={{ marginLeft: "2rem", width: "47rem", marginBottom: "1rem" }}
          label="Remark"
          value={remark}
        />
        <div>
          {upgradeLoader === false ? (
            roomTypes &&
            Object.entries(roomTypes)?.length > 0 && (
              <Button
                className="float-right mr-4"
                variant="custom-button"
                disabled={showButtonOfPricing !== ""}
                onClick={() => {
                  if (
                    reservationData.booking_status.toLowerCase() ===
                    "admin_confirmed"
                  ) {
                    if (isRoomDirty) {
                      handleUpgradeReservation();
                    } else {
                      openDirtysModal();
                    }
                  } else {
                    handleUpgradeReservation();
                  }
                }}
              >
                {t("Move reservation")}
              </Button>
            )
          ) : (
            <Button variant="custom-button" className="float-right mr-4">
              <CircularProgress size="15px" />
            </Button>
          )}
        </div>
        {/* </Grid> */}
      </CommonSlider>
      <Modal
        show={openDirtyModal}
        onHide={closeDirtysModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h6>{`${t("Do you want to mark room number")} - ${
            reservationData?.reservation_rooms
          } ${t("dirty")} ?`}</h6>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="custom-button"
            onClick={() => moveRes("NO")}
            disabled={upgradeLoader}
          >
            {upgradeLoader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("NO")}`
            )}
          </Button>
          <Button
            variant="custom-button"
            onClick={() => moveRes("YES")}
            disabled={upgradeLoader}
          >
            {upgradeLoader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("YES")}`
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth };
}
export default connect(mapStateToProps)(MoveRoom);
