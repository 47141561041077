import React from "react";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Grid,
  Skeleton,
} from "@mui/material";

const LeftSectionLoader = () => {
  return (
    <Box sx={{ width: "35%" }}>
      <Card
        sx={{
          flex: 1,
          height: "94vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          avatar={<Skeleton variant="circular" width={40} height={40} />}
          title={<Skeleton variant="text" width={120} height={20} />}
          subheader={
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Skeleton variant="text" width={100} height={15} />
              <Skeleton variant="text" width={140} height={15} />
            </Box>
          }
        />

        <CardContent sx={{ overflowY: "auto", flex: 1 }}>
          <Grid container spacing={2}>
            {[...Array(4)].map((_, index) => (
              <Grid item xs={6} key={index}>
                <Skeleton variant="text" width={80} height={20} />
                <Skeleton variant="text" width={100} height={20} />
              </Grid>
            ))}
          </Grid>
        </CardContent>

        <CardActions sx={{ marginTop: "auto" }}>
          <Box
            sx={{ borderTop: "1px solid #ccc", width: "100%", paddingTop: 1 }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Skeleton variant="text" width={60} height={20} />
                <Skeleton variant="text" width={60} height={20} />
                <Skeleton variant="text" width={60} height={20} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="text" width={80} height={20} />
                <Skeleton variant="text" width={80} height={20} />
                <Skeleton variant="text" width={80} height={20} />
              </Grid>
            </Grid>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default LeftSectionLoader;
