import axios from "axios";
import {toast} from "material-react-toastify";

const COMMON_URL = `${process.env.REACT_APP_API_URL}`;
const GETCTA = "cta/getallCTAs/";
const ADDNEWCTAS = "cta/addCTAForHotel/";
const ACTIVATETHECTA = "cta/activateCTA/";
const DEACTIVATETHECTA = "cta/deactivateCTA/";
const GETALLCTARATEPLANS = "cta/getAllRatePlansCta/";
const EDITCTAS = "cta/editCTAForHotel/";
const GETALLCTAINVOICE = "invoice/getHistoricalInvoices/";
const DELETECTA = "cta/deleteCTA";

const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");


export const getAllCTA = async () => {
  return axios
    .get(COMMON_URL + GETCTA+"?balance_data=true")
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteCTA = async (hotelId,ctaEmail, ctaTaxId,id,user) => {
  return axios
    .get(
      `${COMMON_URL}${DELETECTA}?hotel_id=${hotelId}&cta_email=${ctaEmail}&cta_tax_id_no=${ctaTaxId}&id=${id}&user=${user}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllBooking = async (data) => {
  // console.log(data);
  return axios
    .get(
      COMMON_URL +
        GETALLCTAINVOICE +
        "?daterange=" +
        "December 15, 2016 - December 15, 2021" +
        "&ctaUnsettledOnly=" +
        "true" +
        "&booking_source_type=" +
        "CTA" +
        "&cta_email=" +
        "jayeshkeshri70@gmail.com"
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllCTARatePlan = async (id) => {
  return axios
    .get(COMMON_URL + GETALLCTARATEPLANS + "?cta_email=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addNewCTA = async (data) => {
  return axios
    .post(COMMON_URL + ADDNEWCTAS, data)
    .then((resp) => resp.data)
    .catch((err) => {
        console.log(err);
        toast.error(err.response?.data);
    });
};

export const EDITCta = async (data) => {
  return axios
    .post(COMMON_URL + EDITCTAS, data)
    .then((resp) => resp.data)
    .catch((err) => {
        console.log(err);
        toast.error(err.response?.data);
    });
};

export const activiateCTA = async (id) => {
  return axios
    .get(COMMON_URL + ACTIVATETHECTA + "?hotel_id=" + hotelId + "&email=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deactiviateCTA = async (id) => {
  return axios
    .get(
      COMMON_URL + DEACTIVATETHECTA + "?hotel_id=" + hotelId + "&email=" + id
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
