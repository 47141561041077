import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Modal,
  Box,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import PrintBillModal from "../PlaceOrders/Utils/PrintBillModal";
import { ItemViewLoaders } from "../Utils/CardLoaders";
import { handleSpringDataRequest } from "../../../../api";
import { useTranslation } from "react-i18next";

// Modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "fit-content",
  maxHeight: 550,
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

function ViewDetailedItems({
  openDetailedItemList,
  handleOpenDetailedItemList,
  handleCloseDetailedItemList,
  // orderDetailedId,
  orderDetails,
  // subtotal = "",
  // totalTax = "",
  // totalDiscount = "",
  // totalAmt = "",
  invoice = false,
  handleOpenSettlePaymentModal = null,
  // orderStatus = null,
  balanceDue = null,
  // shopName,
  // shopAddress,
  // taxIdentificationNo,
  hotelCurrency,
  hotelLogo,
  // customerName,
  // customerPhone,
  // customerEmail,
  // customerGSTNo,
  handleUpdateBillAfterPrint,
  // bookingId,
  // invoiceNumber = null,
  // orderDate = null,
  orderPunchedInfo = null,
  orderPunchedType = null,
  // serviceCharges,
  // serviceChargeInfo,
  // shopId,
  // staffId,
  // paxDetails,
  // roundOffError,
  // checkComplimentaryOrder,
  // New fields
  order,
}) {
  const { t } = useTranslation();

  /* PRINT BILL */
  const [openBillFormat, setOpenBillFormat] = useState(false);
  const handleOpenBillFormat = () => {
    return setOpenBillFormat(true);
  };
  const handleCloseBillFormat = () => {
    return setOpenBillFormat(false);
  };

  const [outletDetails, setOutletDetails] = useState([]);
  const getShopDetails = () => {
    handleSpringDataRequest(
      `core/api/v1/shop/category/get-all?email=${localStorage.getItem("email")}`
    ).then((res) =>
      res.filter(
        (detail) =>
          String(detail.id) === String(order?.shop_id) &&
          setOutletDetails(detail)
      )
    );
  };

  useEffect(() => {
    if (order) {
      getShopDetails();
    }
  }, [order]);

  return (
    <>
      <Modal
        open={openDetailedItemList}
        onClose={handleCloseDetailedItemList}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop
      >
        <Box sx={style}>
          <Stack className="position-relative w-100">
            <Stack
              direction="row"
              className="position-relative w-100 d-flex justify-content-between p-4"
            >
              <Typography variant="h6" style={{ fontSize: "15px" }}>
                {t("Order details for order")}{" "}
                <Typography
                  className="badge badge-warning text-light"
                  style={{ fontSize: "12px" }}
                >
                  {/* {orderDetailedId} */}
                  {order?.id}
                </Typography>
              </Typography>
              <Typography
                variant="h6"
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => {
                  // setOrderDetails([]);
                  handleCloseDetailedItemList();
                }}
              >
                X
              </Typography>
            </Stack>
            <Divider />
            {orderDetails ? (
              <Stack spacing={2} className="position-relative mt-4 p-4">
                {invoice === true && (
                  <Stack
                    className="badge badge-secondary text-dark"
                    style={{ width: "fit-content" }}
                  >
                    {t("This order is")}{" "}
                    {order?.order_status &&
                    order?.order_status.toLowerCase() === "inroom"
                      ? `${t("PENDING")}`
                      : order?.order_status}
                  </Stack>
                )}
                <Stack
                  direction="row"
                  className="w-100 d-flex position-relative mt-4"
                >
                  <Typography
                    variant="h6"
                    className="position-relative"
                    style={{ width: "33.33%", fontSize: "12.5px" }}
                  >
                    {t("Item name")}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="position-relative"
                    style={{ width: "33.33%", fontSize: "12.5px" }}
                  >
                    {t("Item qty.")}
                  </Typography>

                  <Typography
                    variant="h6"
                    className="position-relative"
                    style={{ width: "33.33%", fontSize: "12.5px" }}
                  >
                    {t("Total amount")}
                  </Typography>
                </Stack>

                {orderDetails?.map((product, index) => (
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      className="w-100 d-flex position-relative"
                    >
                      <Typography
                        className="position-relative"
                        style={{ width: "33.33%" }}
                      >
                        {product?.product_name}
                      </Typography>
                      <Typography
                        className="position-relative"
                        style={{ width: "33.33%" }}
                      >
                        {product?.product_qty}
                      </Typography>
                      <Typography
                        className="position-relative"
                        style={{ width: "33.33%" }}
                      >
                        <Stack spacing={1}>
                          <Typography>{product?.product_price}</Typography>
                          {Number(product?.product_discount) > 0 && (
                            <Typography className="text-danger">
                              - {product?.product_discount}
                            </Typography>
                          )}
                        </Stack>
                      </Typography>
                    </Stack>
                    <Divider />
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Typography
                className="mt-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ItemViewLoaders />
              </Typography>
            )}
            {order ? (
              <Stack className="p-4 position-relative w-100 d-flex" spacing={1}>
                <Stack
                  direction="row"
                  className="position-relative w-100 d-flex justify-content-end"
                >
                  <Typography
                    style={{ width: "100px", fontWeight: 500 }}
                    className="position-realtive text-align-left"
                  >
                    {" "}
                    {t("Subtotal")}{" "}
                  </Typography>
                  <Typography
                    style={{ width: "100px" }}
                    className="position-realtive text-align-left"
                  >
                    {" "}
                    {/* {getSubtotalBill()}{" "} */}
                    {order?.subtotal}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  className="position-relative w-100 d-flex justify-content-end"
                >
                  <Typography
                    style={{ width: "100px", fontWeight: 500 }}
                    className="position-realtive text-align-left"
                  >
                    {" "}
                    {t("Discount")}{" "}
                  </Typography>
                  <Typography
                    style={{ width: "100px" }}
                    className="position-realtive text-align-left"
                  >
                    {/* {getTotalDiscount()} */}
                    {/* {totalDiscount} */}
                    {order?.total_order_discount}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  className="position-relative w-100 d-flex justify-content-end"
                >
                  <Typography
                    style={{ width: "100px", fontWeight: 500 }}
                    className="position-realtive text-align-left"
                  >
                    {" "}
                    {t("Tax")}{" "}
                  </Typography>
                  <Typography
                    style={{ width: "100px" }}
                    className="position-realtive text-align-left"
                  >
                    {" "}
                    {/* {totalTax} */}
                    {order?.tax_percentage}
                  </Typography>
                </Stack>
                {Number(order?.service_charge) > 0 &&
                  order?.apply_service_charge_bydefault === "YES" && (
                    <Stack
                      direction="row"
                      className="position-relative w-100 d-flex justify-content-end"
                    >
                      <Typography
                        style={{ width: "100px", fontWeight: 500 }}
                        className="position-realtive text-align-left"
                      >
                        {t("Service charge")}{" "}
                        {order?.is_room_service_charge === "NO"
                          ? outletDetails?.service_charge_type ===
                              "PERCENTAGE" &&
                            `@${outletDetails?.service_charge_value}`
                          : outletDetails?.service_charge_type_room ===
                              "PERCENTAGE" &&
                            `@${outletDetails?.service_charge_value_room}`}
                      </Typography>
                      <Typography
                        style={{ width: "100px" }}
                        className="position-realtive text-align-left"
                      >
                        {order?.service_charge}
                      </Typography>
                    </Stack>
                  )}
                <Stack
                  direction="row"
                  className="position-relative w-100 d-flex justify-content-end"
                >
                  <Typography
                    style={{ width: "100px", fontWeight: 500 }}
                    className="position-realtive text-align-left"
                  >
                    {t("Total")}
                  </Typography>
                  <Typography
                    style={{ width: "100px" }}
                    className="position-realtive text-align-left"
                  >
                    {Number(order?.order_amount)}
                  </Typography>
                </Stack>
                {hotelCurrency === "INR" && order?.round_off_amount !== 0 && (
                  <Stack
                    direction="row"
                    className="position-relative w-100 d-flex justify-content-end"
                  >
                    <Typography
                      style={{ width: "100px", fontWeight: 500 }}
                      className="position-realtive text-align-left"
                    >
                      {t("Grand total")}
                    </Typography>
                    <Typography
                      style={{ width: "100px" }}
                      className="position-realtive text-align-left"
                    >
                      {" "}
                      {/* {getTotalBill()}{" "} */}
                      {/* {Math.round(totalAmt)} */}
                      {Number(order?.order_amount) +
                        Number(order?.round_off_amount)}
                    </Typography>
                  </Stack>
                )}
                {hotelCurrency === "INR" && order?.round_off_amount !== 0 && (
                  <Stack
                    direction="row"
                    className="position-relative w-100 d-flex justify-content-end"
                  >
                    <Typography
                      style={{ width: "100px", fontWeight: 500 }}
                      className="position-realtive text-align-left"
                    >
                      {t("Round off")}
                    </Typography>
                    <Typography
                      style={{ width: "100px" }}
                      className="position-realtive text-align-left"
                    >
                      {order?.round_off_amount}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            ) : (
              <Stack
                className="p-4 position-relative w-100 d-flex"
                spacing={1}
                sx={{ alignItems: "center" }}
              >
                <CircularProgress size="25px" />
              </Stack>
            )}
            {invoice === true && (
              <Stack
                direction="row"
                className="mt-2 position-relative p-4 w-100 d-flex justify-content-end"
              >
                <Stack>
                  <Button
                    variant="custom-button"
                    onClick={() => {
                      handleOpenBillFormat();
                      handleCloseDetailedItemList();
                    }}
                    className="mr-2"
                  >
                    {t("Reprint")}
                  </Button>
                </Stack>
                {Number(balanceDue) > 0 && (
                  <Stack>
                    <Button
                      variant="custom-button"
                      onClick={() => {
                        handleOpenSettlePaymentModal();
                        handleCloseDetailedItemList();
                      }}
                    >
                      {t("Settle dues")}
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      </Modal>
      {/* Print bill modal */}
      {order?.invoice_no && (
        <PrintBillModal
          openBillFormat={openBillFormat}
          handleOpenBillFormat={handleOpenBillFormat}
          handleCloseBillFormat={handleCloseBillFormat}
          menuItems={orderDetails}
          // shopName={shopName}
          // shopAddress={shopAddress}
          // taxIdentificationNo={taxIdentificationNo}
          hotelCurrency={hotelCurrency}
          // subtotal={subtotal}
          // tax={totalTax}
          // discount={totalDiscount}
          // total={totalAmt}
          hotelLogo={hotelLogo}
          // customerName={customerName}
          // customerPhone={customerPhone}
          // customerEmail={customerEmail}
          // customerGSTNo={customerGSTNo}
          // staffId={staffId}
          handleUpdateBillAfterPrint={handleUpdateBillAfterPrint}
          orderPage={false}
          // invoiceNumber={invoiceNumber && invoiceNumber}
          // orderStatus={orderStatus}
          // orderDate={orderDate}
          orderPunchedInfo={orderPunchedInfo}
          orderPunchedType={orderPunchedType}
          // serviceCharges={serviceCharges}
          // serviceChargeInfo={serviceChargeInfo}
          // shopId={shopId}
          // orderId={orderDetailedId}
          balDue={balanceDue}
          // paxDetails={paxDetails}
          // roundOffError={roundOffError}
          // checkComplimentaryOrder={checkComplimentaryOrder}
          order={order}
        />
      )}
    </>
  );
}

export default ViewDetailedItems;
