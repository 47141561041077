// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Fab,
  Fade,
  FormControl,
  CircularProgress,
  FormHelperText,
  Grid,
  Grow,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Card, Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import { makeStyles, useTheme } from "@mui/styles";
import moment from "moment";
import { weekdays } from "../constants/InventoryConstants";
import {
  editRoomIds,
  getPhysicalRoomIds,
  setSoldOut,
  updatePricesFromOta,
} from "../api/InventoryApi";
import {
  customErrorMessage,
  dateFormat,
} from "../../CalendarPage/constants/Calendar-constants";
import { isNull, isUndefined } from "lodash-es";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useFormik } from "formik";
import * as yup from "yup";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import amplitude from "amplitude-js";
import { ENV } from "../../CalendarPage/constants/AmplitudeConstants";
import { handleSpringDataRequest } from "../../../api";
import "../Views/OtASync.css";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  textDisplay: {
    fontSize: "12px",
    marginRight: 6,
  },
  subText: {
    fontSize: "14px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateRatesView = (props) => {
  const { componentType, refresh, closeFunc, data, toOpenBulkSyncReport } =
    props;
  const { selectedRoom, setLineInvisible, setLineVisible, selectedLanguage } =
    props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const DropdownOptions = [
    { text: `${t("Set Pricing")}`, val: "set-price" },
    { text: `${t("Set Inventory")}`, val: "set-inventory" },
    { text: `${t("Rate Multiplier")}`, val: "rate-multiplier" },
    { text: `${t("Inventory Capping")}`, val: "inventory-cap" },
    { text: `${t("Set Online")}`, val: "set-online" },
    { text: `${t("Set Offline")}`, val: "set-offline" },
    { text: `${t("Block Rooms")}`, val: "block-room" },
    { text: `${t("Unblock Rooms")}`, val: "unblock-room" },
    { text: `${t("Delete Rooms")}`, val: "delete-room" },
    { text: `${t("Restore Deleted Rooms")}`, val: "restore" },
    { text: `${t("Update Rates from OTA")}`, val: "update-ota" },
    { text: `${t("Set Sold Out")}`, val: "sold-out" },
    { text: `${t("Edit Room Ids")}`, val: "edit-room" },
    { text: `${t("Reassign Room Type")}`, val: "reassign" },
    { text: `${t("Close/Open Rate Plan")}`, val: "Close Rate" },
    { text: `${t("Close/Open Room Type")}`, val: "Close Room" },
    { text: `${t("Close/Open On Arrival")}`, val: "Close On Arrival" },
    { text: `${t("Close/Open On Departure")}`, val: "Close On Departure" },
    { text: `${t("Min Length of Stay")}`, val: "Set Min Los" },
    { text: `${t("Max Length of Stay")}`, val: "Set Max Los" },
    { text: `${t("Min Advanced Booking Offset")}`, val: "Set Cutoff" },
    { text: `${t("Max Advanced Booking Offset")}`, val: "Set Max Adv Offset" },
  ];
  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(
        new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
    },
  ]);

  //Amplitude check on Bulk Operations page
  const amplitudeEventProperties = {
    hotel_id: data?.hotelId,
    environment: ENV,
    email: data?.accessControl?.email,
    role: data?.accessControl?.ADMINRIGHTS,
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const [selectDays, setSelectDays] = useState(weekdays);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [channels, setChannels] = useState({
    label: "Stayflexi",
    id: 1,
    val: "stayflexi",
  });
  const channel_names = [
    { label: "Stayflexi", id: 1, val: "stayflexi" },
    { label: "Booking.com", id: 2, val: "booking" },
    { label: "Goibibo", id: 3, val: "goibibo" },
  ];
  const newweekdays = [
    { id: "Sunday", value: `${t("Sunday")}` },
    { id: "Monday", value: `${t("Monday")}` },
    { id: "Tuesday", value: `${t("Tuesday")}` },
    { id: "Wednesday", value: `${t("Wednesday")}` },
    { id: "Thursday", value: `${t("Thursday")}` },
    { id: "Friday", value: `${t("Friday")}` },
    { id: "Saturday", value: `${t("Saturday")}` },
  ];
  const getValue = (val) => {
    const res = newweekdays.filter((item) => item.id === val)[0];
    return res.value;
  };
  const [physicalRoomIds, setPhysicalRoomIds] = useState([]);
  const [changedIds, setChangedIds] = useState({});
  const [isPrev, setIsPrev] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState("Inventory");
  const [filterLogData, setfilterLogData] = useState([]);
  const [show, setShow] = useState(false);
  const [cmData, setcmData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setloader] = useState(false);
  const getAllDatas = () => {
    // setloader(true);
    // handleSpringDataRequest(`core/api/v1/cmLogs/getCmLogs`)
    //   .then((res) => {
    //     setcmData(res);
    //     setloader(false);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     setloader(false);
    //   });
  };
  // useEffect(() => {
  //   if (selectedFilter === "Inventory") {
  //     setfilterLogData(cmData["inventoryLogs"]);
  //   } else if (selectedFilter === "Pricing") {
  //     setfilterLogData(cmData["rateLogs"]);
  //   } else if (selectedFilter === "Restriction") {
  //     setfilterLogData(cmData["restrictionLogs"]);
  //   }
  // }, [selectedFilter, cmData]);

  const validationSchema =
    componentType === "edit-room"
      ? yup.object({
          editedRoom: yup.array().required(`${t("Fill in atleast one field")}`),
        })
      : yup.object({
          currentDateSelection: yup
            .array()
            .required(
              `${t("Select a range and click on apply for atleast one range")}`
            ),
        });

  const formik = useFormik({
    initialValues:
      componentType === "edit-room"
        ? { editedRoom: [] }
        : { currentDateSelection: [] },
    validationSchema: validationSchema,
    onSubmit: () => {
      setShowConfirm(true);
    },
  });

  const reset = () => {
    setSelectDays(weekdays);
    setCurrentSelection([]);
  };

  useEffect(() => {
    try {
      setLineVisible();
      getAllPhysicalRooms();
      setLineInvisible();
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }, [selectedRoom]);

  const getAllPhysicalRooms = async () => {
    if (componentType !== "edit-room") {
      return;
    }
    const resp = await getPhysicalRoomIds();
    setPhysicalRoomIds(resp);
  };

  const getDisplayData = () => {
    switch (componentType) {
      case "sold-out":
        return (
          <Grid container>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Date Ranges")} :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "update-ota":
        return (
          <Grid container>
            <Grid paddingBottom={1} display={"flex"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {" "}
                {t("Channel")} :
              </Typography>
              <Typography className={classes.subText} variant="body">
                {channels.label}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Date Ranges")} :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "edit-room":
        return (
          <Grid container>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Changed Room Id's")} :
              </Typography>
              {Object.keys(changedIds)
                .filter(
                  (key) =>
                    !isUndefined(changedIds[key]) && changedIds[key] !== ""
                )
                .map((filteredKeys) => (
                  <Typography
                    key={filteredKeys}
                    className={classes.subText}
                    variant="body"
                  >{`${filteredKeys} - ${changedIds[filteredKeys]}`}</Typography>
                ))}
            </Grid>{" "}
          </Grid>
        );
    }
  };

  const performAction = async () => {
    setApiLoading(true);
    try {
      switch (componentType) {
        case "sold-out":
          await soldOut().then((res) => {
            // handleShow();
            /* amplitude
              .getInstance()
              .logEvent(
                "USER PERFORMED SET SOLD OUT OPERATION IN BULK OPERATIONS",
                amplitudeEventProperties
              ); */
          });
          break;
        case "update-ota":
          await updateOtaPrices(isPrev).then((res) => {
            /* amplitude
              .getInstance()
              .logEvent(
                "USER PERFORMED UPDATE RATES FROM OTA OPERATION IN BULK OPERATIONS",
                amplitudeEventProperties
              ); */
          });
          break;
        case "edit-room":
          await editRoomFunc().then((res) => {
            toOpenBulkSyncReport();
            // handleShow();
            /* amplitude
              .getInstance()
              .logEvent(
                "USER PERFORMED EDIT ROOM IDS OPERATION IN BULK OPERATIONS",
                amplitudeEventProperties
              ); */
          });
          break;
      }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
    } finally {
      reset();
      setApiLoading(false);
      formik.resetForm();
      setShowConfirm(false);
      setIsPrev(false);
      setChangedIds({});
      getAllPhysicalRooms();
      refresh();
    }
  };

  const editRoomFunc = async () => {
    const newObj = {};
    const tempIds = Object.keys(changedIds).filter(
      (key) => !isUndefined(changedIds[key]) && changedIds[key] !== ""
    );
    tempIds.forEach((key) => {
      newObj[key] = changedIds[key].trim();
    });
    await editRoomIds(newObj).then(() => {
      toast.success(`${t("Successfully editted the room IDs")}`);
    });
    // getAllDatas();
  };

  const updateOtaPrices = async (prev) => {
    await updatePricesFromOta(
      { isPrev: prev ? true : false, channel: channels.val },
      currentSelection
    ).then(() => {
      toast.success(`${t("successfully updated rates from OTA")}`);
    });
  };

  const soldOut = async () => {
    await setSoldOut(currentSelection).then(() => {
      toast.success(`${t("Successfully set the inventory as sold out")}`);
    });
    // getAllDatas();
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 0) {
      return;
    }
    setSelectDays(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const classes = useStyles();
  const theme = useTheme();

  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const handleChipDelete = (val) => {
    const arr = currentSelection.filter((chip) => chip.id !== val.id);
    formik.setFieldValue("currentDateSelection", arr);
    setCurrentSelection(arr);
  };

  return (
    <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
        style={{ width: "inherit" }}
      >
        <Grid container>
          {!loading && (
            <Grid container className={classes.textField} spacing={2}>
              {componentType === "edit-room" && (
                <Grid item container>
                  <ListItemText
                    primary={t("Edit RoomID(s)")}
                    secondary={t(
                      "You can edit the roomID by entering the new roomID in the corresponding place. For empty inputs, no change will be made."
                    )}
                  ></ListItemText>
                  <Grid item xs={12}>
                    <Box
                      id="editedRoom"
                      name="editedRoom"
                      sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}
                    >
                      {physicalRoomIds.length <= 0 && (
                        <Typography>
                          {" "}
                          {t("There are no rooms for editing")}{" "}
                        </Typography>
                      )}
                      {physicalRoomIds.map((value, index) => {
                        return (
                          <Grid item xs={2} key={value}>
                            <InputLabel
                              color={
                                formik.touched.editedRoom &&
                                Boolean(formik.errors.editedRoom)
                                  ? "error"
                                  : ""
                              }
                            >
                              {value}
                            </InputLabel>
                            <TextField
                              hiddenLabel
                              variant="standard"
                              type="text"
                              value={
                                isUndefined(changedIds[value])
                                  ? ""
                                  : changedIds[value]
                              }
                              onChange={(eve) => {
                                changedIds[value] = eve.target.value;
                                let arr = Object.values(changedIds).filter(
                                  (ids) =>
                                    !isUndefined(ids) &&
                                    !isNull(ids) &&
                                    ids !== ""
                                );
                                formik.setFieldValue("editedRoom", arr);
                                setChangedIds({ ...changedIds });
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Box>
                    <FormHelperText
                      sx={{ paddingTop: 1 }}
                      error={
                        formik.touched.editedRoom &&
                        Boolean(formik.errors.editedRoom)
                      }
                    >
                      {formik.touched.editedRoom && formik.errors.editedRoom}
                    </FormHelperText>
                  </Grid>
                </Grid>
              )}
              {componentType === "update-ota" && (
                <Grid item spacing={2} container xs={12}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Autocomplete
                      fullWidth
                      id="combo-box"
                      disableClearable
                      onChange={(eve, val) => {
                        setChannels(val);
                      }}
                      isOptionEqualToValue={(opt, val) => opt.id === val.id}
                      defaultValue={channel_names.find((val) => val.label[0])}
                      options={channel_names}
                      renderInput={(params) => (
                        <TextField {...params} label={t("Source")} />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              {componentType !== "edit-room" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-chip-label">
                      {t("Days")}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selectDays}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label={t("Days")}
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              key={value}
                              label={getValue(value)}
                              style={{
                                fontWeight: "bold",
                                border: "1px solid black",
                                color: "black",
                              }}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {newweekdays.map((name) => (
                        <MenuItem
                          key={name.id}
                          value={name.id}
                          style={getStyles(name, selectDays, theme)}
                        >
                          {name.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {componentType !== "edit-room" && (
                <Grid item xs={12}>
                  <div>
                    <ClickAwayListener
                      onClickAway={() => {
                        setShowDatePicker(false);
                        setAnchorEl(null);
                      }}
                    >
                      <div>
                        <TextField
                          fullWidth
                          focused={showDatePicker}
                          required
                          id="currentDateSelection"
                          name="currentDateSelection"
                          error={
                            formik.touched.currentDateSelection &&
                            Boolean(formik.errors.currentDateSelection)
                          }
                          helperText={
                            formik.touched.currentDateSelection &&
                            formik.errors.currentDateSelection
                          }
                          InputProps={{ readOnly: true }}
                          value={getDateText()}
                          label={t("Date range")}
                          onClick={(eve) => {
                            setAnchorEl(eve.currentTarget);
                            setShowDatePicker(true);
                          }}
                        ></TextField>
                        {showDatePicker && (
                          <Popper
                            style={{ zIndex: 2 }}
                            open={showDatePicker}
                            anchorEl={anchorEl}
                            placement="bottom"
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                style={{
                                  transformOrigin: (placement = "bottom-end"),
                                }}
                                {...TransitionProps}
                              >
                                <Paper
                                  elevation={5}
                                  className="inventory-daterange-custom"
                                >
                                  <DateRange
                                    color="#1BC5BD"
                                    rangeColors={["#1BC5BD"]}
                                    editableDateInputs={false}
                                    minDate={new Date()}
                                    maxDate={
                                      new Date(
                                        new Date().setDate(
                                          new Date().getDate() + 730
                                        )
                                      )
                                    }
                                    moveRangeOnFirstSelection={false}
                                    onChange={(data) => {
                                      setCurrentDateSelection([
                                        { ...data.range1 },
                                      ]);
                                    }}
                                    ranges={currentDateSelection}
                                  ></DateRange>
                                  <Grid
                                    container
                                    item
                                    sx={{
                                      padding: 1,
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      sx={{ marginRight: 1 }}
                                      onClick={() => {
                                        setShowDatePicker(false);
                                      }}
                                      color="error"
                                    >
                                      {t("Cancel")}
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      onClick={() => {
                                        const text = getDateText();
                                        const check =
                                          text + selectDays.join(",");
                                        if (
                                          currentSelection.findIndex(
                                            (val) => val.id === check
                                          ) === -1
                                        ) {
                                          currentSelection.push({
                                            text: text,
                                            id: check,
                                            datesText: selectDays.join(","),
                                            startDate: moment(
                                              currentDateSelection[0].startDate
                                            ).format(dateFormat),
                                            endDate: moment(
                                              currentDateSelection[0].endDate
                                            ).format(dateFormat),
                                            daysIncluded: selectDays,
                                          });
                                          formik.setFieldValue(
                                            "currentDateSelection",
                                            currentSelection
                                          );
                                          setCurrentSelection(currentSelection);
                                        }
                                        setShowDatePicker(false);
                                      }}
                                      color="primary"
                                    >
                                      {t("Apply")}
                                    </Button>
                                  </Grid>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        )}
                      </div>
                    </ClickAwayListener>
                  </div>
                </Grid>
              )}
              {currentSelection.length > 0 && (
                <Fade in={currentSelection.length > 0}>
                  <Grid item xs={12}>
                    <InputLabel>{t("Current Selections")}</InputLabel>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {currentSelection.map((data) => {
                        return (
                          <Chip
                            sx={{
                              width: "auto",
                              height: "auto",
                              fontWeight: "bold",
                              border: "1px solid black",
                              color: "black",
                            }}
                            variant="outlined"
                            color="primary"
                            onDelete={(eve) => {
                              handleChipDelete(data);
                            }}
                            label={
                              <div>
                                <Typography
                                  sx={{ padding: 0.5, fontWeight: "bold" }}
                                >
                                  {" "}
                                  {data.text}
                                </Typography>
                                <Divider />
                                <Typography
                                  sx={{
                                    padding: 0.5,
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                    fontWeight: "bold",
                                  }}
                                  display="inline-block"
                                >
                                  {" "}
                                  {data.datesText}
                                </Typography>
                              </div>
                            }
                            key={data.id}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                </Fade>
              )}
            </Grid>
          )}
          {!loading && (
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                justifyContent="flex-end"
                container
                className={classes.textField}
              >
                {componentType === "update-ota" && (
                  <Fab
                    disabled={apiLoading}
                    sx={{ marginRight: 1 }}
                    type="submit"
                    onClick={() => {
                      setIsPrev(true);
                    }}
                    variant="extended"
                    color="primary"
                  >
                    {t("Previous Year")}
                  </Fab>
                )}
                <Fab
                  disabled={apiLoading}
                  type="submit"
                  variant="extended"
                  color="primary"
                  onClick={() => {
                    setIsPrev(false);
                  }}
                >
                  {componentType === "sold-out" && `${t("Set Sold Out")}`}
                  {componentType === "update-ota" && `${t("Next Year")}`}
                  {componentType === "edit-room" && `${t("Edit RoomIDs")}`}
                </Fab>
              </Grid>
            </Grid>
          )}
          <Modal
            show={show}
            onHide={handleClose}
            style={{ zIndex: "3000" }}
            dialogClassName={"myModalBodysxsse"}
            contentClassName={"myModalContent"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {t("Inventory, pricing and restriction logs")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  fontSize: "0.9rem",
                  marginTop: "1rem",
                  justifyContent: "space-between",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor:
                      selectedFilter === "Inventory" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Inventory" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Inventory")}
                >
                  {t("Inventory")}
                </div>

                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor: selectedFilter === "Pricing" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Pricing" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Pricing")}
                >
                  {t("Pricing")}
                </div>
                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor:
                      selectedFilter === "Restriction" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Restriction" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Restriction")}
                >
                  {t("Restriction")}
                </div>
              </div>

              {filterLogData?.map((e) => (
                <Card
                  className="mb-3"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  }}
                >
                  <Card.Body>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex flex-column">
                        <h5>{t("Room name")}</h5>
                        <p>{e.roomName}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("Date")}</h5>
                        <p>{e.dateRange}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("OTAs")}</h5>
                        <p>{e.channel}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("Status")}</h5>
                        <p>{e.status}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5> {t("Updated on OTA")}</h5>
                        <p>{e.updatedTime}</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="custom-button">
                {loader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Refresh")}`
                )}
              </Button>
            </Modal.Footer>
          </Modal>
          <ConfirmationModal
            open={showConfirm}
            onClose={() => {
              setShowConfirm(false);
            }}
            onBackdropClick={closeFunc}
            title={
              DropdownOptions.find((item) => item.val === componentType).text
            }
            loading={apiLoading}
            displayData={showConfirm ? getDisplayData() : null}
            confirmAction={performAction}
          ></ConfirmationModal>
        </Grid>
      </form>
    </Grow>
  );
};

export default UpdateRatesView;
