import React, { useState, useEffect } from "react";
import StockWrapper from "./StockWrapper";
import { Card } from "react-bootstrap";
import { DateRange } from "react-date-range";
import { connect } from "react-redux";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Modal,
  ClickAwayListener,
  CircularProgress,
} from "@mui/material";
import BootstrapTable from "react-bootstrap-table-next";
import { OutlinedButton, SecondaryButton } from "../../Utils/ButtonHelper";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import {
  handleSpringDataRequest,
  handleSpringDataMutationRequest,
  handleDataMutationRequest,
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
} from "../../api/index";
import Drawer from "@mui/material/Drawer";
import * as AiIcons from "react-icons/ai";
import moment from "moment";
import { toast } from "material-react-toastify";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import "material-react-toastify/dist/ReactToastify.css";
import { get } from "jquery";
import "./Stock.css";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  paddingTop: "10px",
  paddingBottom: "10px",
};

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  // border: "1px solid",
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

function StockDashboard(props) {
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const hotelCurrency = props?.data.accessControl.hotel.hotel_currency;
  const { SearchBar } = Search;
  const [selectedCategory, setselectedCategory] = useState([]);
  const [selectedCategoryForConsumption, setselectedCategoryForConsumption] =
    useState([]);
  const [filterConsumptionType, setfilterConsumptionType] =
    useState("Raw Material wise");
  const [selectedCategoryForWastage, setselectedCategoryForWastage] = useState(
    []
  );
  const [filterWastageType, setfilterWastageType] =
    useState("Raw Material wise");

  // Click away listener tin order to toggle calendar state
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  const [openForNotification, setOpenForNotification] = useState(false);
  const handleClickforNotification = () =>
    setOpenForNotification((prev) => !prev);
  const handleClickAwayForNotification = () => {
    getNotificationData();
    setOpenForNotification(false);
  };

  // Getting the start date as well as the end date to load the data
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [dateRangeForNotification, setDateRangeForNotification] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [categories, setCategories] = useState([
    "Bakery products",
    "Beverages",
    "Bones",
    "Cereals",
    "Chemicals",
    "Dairy products",
    "Electronic components",
    "Flowers",
    "Flour",
    "Fruits",
    "Grains",
    "Latex",
    "Leather",
    "Meat",
    "Metals",
    "Milk",
    "Non Veg",
    "Nuts",
    "Oils and fats",
    "Others",
    "Packaging materials",
    "Petroleum",
    "Poultry",
    "Proteins products",
    "Resin",
    "Salads",
    "Salts",
    "Sauces",
    "Seafood",
    "Seeds",
    "Silk",
    "Snacks",
    "Soups",
    "Spices",
    "Sweets",
    "Sweeteners",
    "Textiles and fabrics",
    "Veg",
    "Vegetables",
    "Wood",
    "Wood and paper",
    "Wool",
  ]);

  const [wastageData, setwastageData] = useState([]);
  const [copyWastageData, setcopyWastageData] = useState([]);
  const [consumptionData, setconsumptionData] = useState([]);
  const [copyConsumptionData, setCopyconsumptionData] = useState([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [copyPurchaseOrderData, setCopyPurchaseOrderData] = useState([]);
  const [currentStockData, setcurrentStockData] = useState([]);
  const [copyCurrentStockData, setcopyCurrentStockData] = useState([]);
  const [newCopyCurrentStockData, setnewCopyCurrentStockData] = useState([]);
  const [purchaseOrderStatus, setpurchaseOrderStatus] = useState("ACTIVE");
  const [currentStockType, setcurrentStockType] = useState("All");
  const [notificationData, setnotificationData] = useState([]);
  const [outletType, setoutletType] = useState("single");

  function handleGetAllKitchenConfig() {
    handleSpringDataRequest("core/api/v1/kitchen/get-all")
      .then((res) => {
        if (res?.length <= 1) {
          setoutletType("single");
        } else {
          setoutletType("multi");
        }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetAllKitchenConfig();
  }, []);

  useEffect(() => {
    getDashWastage();
  }, [outletType]);
  useEffect(() => {
    getDashConsumption();
  }, [outletType]);

  useEffect(() => {
    getPurchaseOrderData();
  }, [dateRange, outletType]);

  useEffect(() => {
    getCurrentStock();
  }, []);

  useEffect(() => {
    getNotificationData();
  }, []);

  const getNotificationData = () => {
    djangoHandleDataMutationRequest("POST", `pos/dash-notification/`, {
      hotel_id: hotelId,
      start_date: moment(dateRangeForNotification[0].startDate).format(
        "YYYY-MM-DD 00:00:00"
      ),
      end_date: moment(dateRangeForNotification[0].endDate).format(
        "YYYY-MM-DD 23:59:59"
      ),
    })
      .then((res) => {
        setnotificationData(res);
      })
      .catch((err) => console.log(err));
  };

  const getCurrentStock = () => {
    djangoHandleDataRequests(`pos/warehouse-current-stock/`)
      .then((res) => {
        setcopyCurrentStockData(res);
        setnewCopyCurrentStockData(res);
        setcurrentStockData(res);
      })
      .catch((err) => console.log(err));
  };
  const getPurchaseOrderData = () => {
    djangoHandleDataMutationRequest("POST", `pos/dash-purchase/`, {
      hotel_id: hotelId,
      start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD 00:00:00"),
      end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD 23:59:59"),
      outlet_type: outletType,
    })
      .then((res) => {
        setPurchaseOrderData(res);
        setCopyPurchaseOrderData(res);
      })
      .catch((err) => console.log(err));
  };

  const getDashWastage = () => {
    djangoHandleDataMutationRequest("POST", `pos/dash-wastage/`, {
      hotel_id: hotelId,
      today_start_date: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      today_end_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
      yest_start_date: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD 00:00:00"),
      yest_end_date: moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),
      prev_week_start_date: moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD 00:00:00"),
      prev_month_start_date: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD 00:00:00"),
      outlet_type: outletType,
    })
      .then((res) => {
        setwastageData(res);
        setcopyWastageData(res);
      })
      .catch((err) => console.log(err));
  };

  const getDashConsumption = () => {
    djangoHandleDataMutationRequest("POST", `pos/dash-consumption/`, {
      hotel_id: hotelId,
      today_start_date: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      today_end_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
      yest_start_date: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD 00:00:00"),
      yest_end_date: moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),
      prev_week_start_date: moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD 00:00:00"),
      prev_month_start_date: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD 00:00:00"),
      outlet_type: outletType,
    })
      .then((res) => {
        setconsumptionData(res);
        setCopyconsumptionData(res);
      })
      .catch((err) => console.log(err));
  };

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;

    setselectedCategoryForConsumption(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangesForWastage = (event) => {
    const {
      target: { value },
    } = event;

    setselectedCategoryForWastage(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangesForCurrentStock = (event) => {
    const {
      target: { value },
    } = event;

    setselectedCategory(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (filterWastageType === "Raw Material wise") {
      setwastageData(copyWastageData);
    } else {
      if (selectedCategoryForWastage.length > 0) {
        const data = copyWastageData?.filter((item) =>
          selectedCategoryForWastage.includes(item.category)
        );
        setwastageData(data);
      }
    }
  }, [copyWastageData, selectedCategoryForWastage, filterWastageType]);

  useEffect(() => {
    setcurrentStockData(copyCurrentStockData);
    // if (selectedCategory.length > 0) {
    //   const data = copyCurrentStockData?.filter((item) =>
    //     selectedCategory.includes(item.rm_category)
    //   );
    //   setcurrentStockData(data);
    // }
    if (currentStockType === "All") {
      setcurrentStockData(newCopyCurrentStockData);
    } else if (currentStockType === "At Par") {
      const data = newCopyCurrentStockData
        .filter((item) => item.quantity <= item.at_par_stock_level)
        ?.sort((a, b) => {
          return (
            parseFloat(b.at_par_stock_level) - parseFloat(a.at_par_stock_level)
          );
        });
      setcurrentStockData(data);
    } else if (currentStockType === "Min") {
      const data = newCopyCurrentStockData
        .filter((item) => item.quantity <= item.min_stock_level)
        ?.sort((a, b) => {
          return parseFloat(b.min_stock_level) - parseFloat(a.min_stock_level);
        });

      setcurrentStockData(data);
    } else if (currentStockType === "Negative") {
      const data = copyCurrentStockData?.filter((e) => e.quantity < 0);
      setcurrentStockData(data);
    }
  }, [copyCurrentStockData, currentStockType]);

  useEffect(() => {
    if (filterConsumptionType === "Raw Material wise") {
      setconsumptionData(copyConsumptionData);
    } else {
      if (selectedCategoryForConsumption.length > 0) {
        const data = copyConsumptionData?.filter((item) =>
          selectedCategoryForConsumption.includes(item.category)
        );
        setconsumptionData(data);
      }
    }
  }, [
    copyConsumptionData,
    selectedCategoryForConsumption,
    filterConsumptionType,
  ]);

  useEffect(() => {
    if (purchaseOrderStatus === "ACTIVE") {
      const data = copyPurchaseOrderData?.filter(
        (item) => item?.status === "ACTIVE"
      );
      setPurchaseOrderData(data);
    } else {
      const data = copyPurchaseOrderData?.filter(
        (item) => item?.status === "RECEIVED"
      );
      setPurchaseOrderData(data);
    }
  }, [copyPurchaseOrderData, purchaseOrderStatus]);

  return (
    <>
      <StockWrapper
        backBtn={false}
        hideDrawer={true}
        stockTypeName={t("Dashboard")}
        contentArea={
          <Stack className="position-relative mt-8">
            <div className="d-flex flex-column">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  // overflowY: "auto",
                  height: "25.5rem",
                }}
              >
                <Card.Body style={{ padding: "1%" }}>
                  <h4 className="mb-4">{t("Consumption Report")}</h4>
                  <div className="d-flex mb-4">
                    <FormControl fullWidth sx={{ width: "50%" }}>
                      <InputLabel
                        style={{ width: "6rem" }}
                        id="demo-simple-select-label"
                      >
                        {t("Type")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => {
                          setfilterConsumptionType(e.target.value);
                          if (e.target.value === "Raw Material wise") {
                            setselectedCategoryForConsumption([]);
                          }
                        }}
                        className="mr-4"
                        size="small"
                        label={t("Type")}
                        value={filterConsumptionType}
                      >
                        <MenuItem value={"Raw Material wise"}>
                          {t("Raw Material wise")}
                        </MenuItem>
                        <MenuItem value={"Category wise"}>
                          {t("Category wise")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {filterConsumptionType === "Category wise" && (
                      <FormControl fullWidth sx={{ width: "50%" }}>
                        <InputLabel id="demo-simple-select-label">
                          {t("Raw Material Category")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedCategoryForConsumption}
                          size="small"
                          multiple
                          onChange={handleChanges}
                          label={t("Raw Material Category")}
                        >
                          {categories?.map((category, index) => (
                            <MenuItem
                              value={category}
                              key={index}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <table class="table table-hover table-fixed">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "15rem" }}>
                            {t("Item")}
                          </th>
                          <th scope="col" style={{ width: "15rem" }}>
                            {t("Category")}
                          </th>
                          <th scope="col" style={{ width: "10rem" }}>
                            {t("Unit")}
                          </th>
                          <th scope="col" style={{ width: "15rem" }}>
                            {t("Today")}
                          </th>
                          <th scope="col" style={{ width: "15rem" }}>
                            {t("Yesterday")}
                          </th>
                          <th scope="col" style={{ width: "15rem" }}>
                            {t("Last 7 days")}
                          </th>
                          <th scope="col" style={{ width: "15rem" }}>
                            {t("Last 30 days")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {consumptionData?.map((item) => (
                          <tr>
                            <td scope="row" style={{ width: "15rem" }}>
                              {item.name}
                            </td>
                            <td style={{ width: "15rem" }}>{item.category}</td>
                            <td style={{ width: "10rem" }}>
                              {item.stock_unit}
                            </td>
                            <td style={{ width: "15rem" }}>
                              {item.consumption1.toFixed(2)}
                            </td>
                            <td style={{ width: "15rem" }}>
                              {item.consumption2.toFixed(2)}
                            </td>
                            <td style={{ width: "15rem" }}>
                              {item.consumption7.toFixed(2)}
                            </td>
                            <td style={{ width: "15rem" }}>
                              {item.consumption30.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
              <div className="d-flex w-100 mt-4 mb-4">
                <Card
                  style={{
                    // overflowY: "auto",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    width: "50%",
                    marginRight: "1rem",
                    height: "25rem",
                  }}
                >
                  <Card.Body style={{ padding: "1%" }}>
                    <h4 className="mb-4"> {t("Current Stock Report")}</h4>
                    <div className="d-flex mb-4">
                      <FormControl fullWidth sx={{ width: "50%" }}>
                        <InputLabel
                          style={{ width: "6rem" }}
                          id="demo-simple-select-label"
                        >
                          {t("Type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(e) => setcurrentStockType(e.target.value)}
                          className="mr-4"
                          size="small"
                          label={t("Type")}
                          value={currentStockType}
                        >
                          <MenuItem value={"All"}>{t("All")}</MenuItem>
                          <MenuItem value={"At Par"}>{t("At Par")}</MenuItem>
                          <MenuItem value={"Min"}>{t("Min")}</MenuItem>
                          <MenuItem value={"Negative"}>
                            {t("Negative")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {/* <FormControl fullWidth sx={{ width: "50%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Raw Material Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedCategory}
                          size="small"
                          label="Raw Material Category"
                          multiple
                          onChange={handleChangesForCurrentStock}
                        >
                          {categories?.map((category, index) => (
                            <MenuItem
                              value={category}
                              key={index}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                    </div>
                    <div className="d-flex align-items-center">
                      <table class="table table-hover table-fixed">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "12rem" }}>
                              {t("Item")}
                            </th>
                            <th scope="col" style={{ width: "12rem" }}>
                              {t("Category")}
                            </th>
                            <th scope="col" style={{ width: "12rem" }}>
                              {t("Unit")}
                            </th>
                            <th scope="col" style={{ width: "15rem" }}>
                              {t("Quantity")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentStockData?.map((item) => (
                            <tr>
                              <td scope="row" style={{ width: "12rem" }}>
                                {item.name}
                              </td>
                              <td style={{ width: "12rem" }}>
                                {item.rm_category}
                              </td>
                              <td style={{ width: "12rem" }}>
                                {item.purchase_unit}
                              </td>

                              <td style={{ width: "15rem" }}>
                                {item.quantity}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
                <Card
                  style={{
                    // overflowY: "auto",
                    height: "25.5rem",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    width: "50%",
                  }}
                >
                  <Card.Body style={{ padding: "1%" }}>
                    <h4 className="mb-4">{t("Purchase Order Report")}</h4>
                    <div className="d-flex mb-4">
                      <FormControl fullWidth sx={{ width: "30%" }}>
                        <InputLabel
                          style={{ width: "6rem" }}
                          id="demo-simple-select-label"
                        >
                          {t("Type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(e) =>
                            setpurchaseOrderStatus(e.target.value)
                          }
                          className="mr-4"
                          size="small"
                          label={t("Type")}
                          value={purchaseOrderStatus}
                        >
                          <MenuItem value={"ACTIVE"}>{t("Pending")}</MenuItem>
                          <MenuItem value={"RECEIVED"}>
                            {t("Received")}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Box sx={{ position: "relative" }}>
                          <button
                            onClick={handleClick}
                            className="border border-3 border-dark rounded p-2"
                            style={{ backgroundColor: "transparent" }}
                          >
                            {moment(dateRange[0].startDate).format(
                              "DD MMM YYYY"
                            )}{" "}
                            {" to "}
                            {moment(dateRange[0].endDate).format("DD MMM YYYY")}
                          </button>
                          {open ? (
                            <Box sx={styles}>
                              <Stack spacing={3}>
                                <DateRange
                                  editableDateInputs={true}
                                  onChange={(item) =>
                                    setDateRange([item.selection])
                                  }
                                  moveRangeOnFirstSelection={false}
                                  ranges={dateRange}
                                  hotelCurrency={hotelCurrency}
                                />
                                <Stack
                                  className="position-relative w-100 d-flex justify-content-between"
                                  direction="row"
                                >
                                  <SecondaryButton
                                    text={t("Cancel")}
                                    onClick={() => setOpen(false)}
                                  />
                                  <Button
                                    variant="custom-button"
                                    onClick={() => handleClickAway()}
                                  >
                                    {t("Apply")}
                                  </Button>
                                </Stack>
                              </Stack>
                            </Box>
                          ) : null}
                        </Box>
                      </ClickAwayListener>
                    </div>
                    <div className="d-flex align-items-center">
                      <table class="table table-hover table-fixed">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "12rem" }}>
                              {purchaseOrderStatus === "ACTIVE" ? "To" : "From"}
                            </th>
                            <th scope="col" style={{ width: "11rem" }}>
                              {t("Request No.")}
                            </th>
                            <th scope="col" style={{ width: "11rem" }}>
                              {t("Total Amount")}
                            </th>
                            <th scope="col" style={{ width: "14rem" }}>
                              {t("Date")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchaseOrderData?.map((item) => (
                            <tr>
                              <td scope="row" style={{ width: "12rem" }}>
                                {item?.supplier_name}
                              </td>
                              <td style={{ width: "11rem" }}>
                                {item?.invoice_number}
                              </td>
                              <td style={{ width: "11rem" }}>{item?.total}</td>
                              <td style={{ width: "14rem" }}>{item?.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="d-flex w-100 mt-3">
                {" "}
                <Card
                  style={{
                    overflowY: "auto",
                    height: "25.5rem",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    width: "50%",
                  }}
                >
                  <Card.Body style={{ padding: "1%" }}>
                    <h4 className="mb-4">Wastage Report</h4>
                    <div className="d-flex mb-4">
                      <FormControl fullWidth sx={{ width: "50%" }}>
                        <InputLabel
                          style={{ width: "6rem" }}
                          id="demo-simple-select-label"
                        >
                          {t("Type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(e) => {
                            setfilterWastageType(e.target.value);
                            if (e.target.value === "Raw Material wise") {
                              setselectedCategoryForWastage([]);
                            }
                          }}
                          className="mr-4"
                          size="small"
                          label={t("Type")}
                          value={filterWastageType}
                        >
                          <MenuItem value={"Raw Material wise"}>
                            {t("Raw Material wise")}
                          </MenuItem>
                          <MenuItem value={"Category wise"}>
                            {t("Category wise")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {filterWastageType === "Category wise" && (
                        <FormControl fullWidth sx={{ width: "50%" }}>
                          <InputLabel id="demo-simple-select-label">
                            {t("Raw Material Category")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCategoryForWastage}
                            size="small"
                            label={t("Raw Material Category")}
                            multiple
                            onChange={handleChangesForWastage}
                          >
                            {categories?.map((category, index) => (
                              <MenuItem
                                value={category}
                                key={index}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      <table class="table table-hover table-fixed">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "7rem" }}>
                              {t("Item")}
                            </th>
                            <th scope="col" style={{ width: "7rem" }}>
                              {t("Category")}
                            </th>
                            <th scope="col" style={{ width: "5rem" }}>
                              {t("Unit")}
                            </th>
                            <th scope="col" style={{ width: "7rem" }}>
                              {t("Today")}
                            </th>
                            <th scope="col" style={{ width: "7rem" }}>
                              {t("Yesterday")}
                            </th>
                            <th scope="col" style={{ width: "8rem" }}>
                              {t("Last 7 days")}
                            </th>
                            <th scope="col" style={{ width: "8rem" }}>
                              {t("Last 30 days")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {wastageData?.map((item) => (
                            <tr>
                              <td scope="row" style={{ width: "7rem" }}>
                                {item.name}
                              </td>
                              <td style={{ width: "7rem" }}>{item.category}</td>
                              <td style={{ width: "5rem" }}>
                                {item.stock_unit}
                              </td>
                              <td style={{ width: "7rem" }}>
                                {item.wastage1.toFixed(2)}
                              </td>
                              <td style={{ width: "7rem" }}>
                                {item.wastage2.toFixed(2)}
                              </td>
                              <td style={{ width: "8rem" }}>
                                {item.wastage7.toFixed(2)}
                              </td>
                              <td style={{ width: "8rem" }}>
                                {item.wastage30.toFixed(2)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
                <Card
                  style={{
                    // overflowY: "auto",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    height: "25.5rem",
                    width: "50%",
                    marginLeft: "1rem",
                  }}
                >
                  <Card.Body style={{ padding: "1%" }}>
                    <h4 className="mb-4">{t("Notifications")}</h4>
                    <ClickAwayListener
                      onClickAway={handleClickAwayForNotification}
                    >
                      <Box sx={{ position: "relative" }}>
                        <button
                          onClick={handleClickforNotification}
                          className="border border-3 border-dark rounded p-2"
                          style={{ backgroundColor: "transparent" }}
                        >
                          {moment(dateRangeForNotification[0].startDate).format(
                            "DD MMM YYYY"
                          )}{" "}
                          {" to "}
                          {moment(dateRangeForNotification[0].endDate).format(
                            "DD MMM YYYY"
                          )}
                        </button>
                        {openForNotification ? (
                          <Box sx={styles}>
                            <Stack spacing={3}>
                              <DateRange
                                editableDateInputs={true}
                                onChange={(item) =>
                                  setDateRangeForNotification([item.selection])
                                }
                                moveRangeOnFirstSelection={false}
                                ranges={dateRangeForNotification}
                                hotelCurrency={hotelCurrency}
                              />
                              <Stack
                                className="position-relative w-100 d-flex justify-content-between"
                                direction="row"
                              >
                                <SecondaryButton
                                  text={t("Cancel")}
                                  onClick={() => setOpenForNotification(false)}
                                />
                                <Button
                                  variant="custom-button"
                                  onClick={() =>
                                    handleClickAwayForNotification()
                                  }
                                >
                                  {t("Apply")}
                                </Button>
                              </Stack>
                            </Stack>
                          </Box>
                        ) : null}
                      </Box>
                    </ClickAwayListener>

                    <div className="d-flex align-items-center">
                      <table class="table table-hover table-fixed">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "38.4rem" }}>
                              {t("Message")}
                            </th>
                            <th scope="col">{t("Date")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {notificationData?.map((item) => (
                            <tr>
                              <td scope="row" style={{ width: "38.4rem" }}>
                                {item?.message}
                              </td>
                              <td>{item?.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Stack>
        }
      />
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(StockDashboard);
