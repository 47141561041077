import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
} from "@mui/material";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import "./Payment.css";
import { getCardType, cardNumberCVVFormat } from "../../Utils/cardType";
import { toast } from "material-react-toastify";
import { getcustomPGinfo } from "../RevenueManagement/Promotions/apis/PromotionApis";
import { connect } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";

const PaymentMethods = (props) => {
  const [groupId, setGroupId] = useState("");
  const [resp, setResp] = useState(null);

  useEffect(() => {
    setGroupId(props?.groupId);
    setResp(props?.res);
  }, []);

  const [accountInfo, setAccountInfo] = useState(null);
  const [accoundDetails, setAccoundDetails] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [billingEmail, setBillingEmail] = useState("");
  const [billingCCEmails, setBillingCCEmails] = useState("");
  const [billingStreetAddress, setBillingStreetAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingAddressZipcode, setBillingAddressZipcode] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingCountry, setBillingCountry] = useState("");

  const setCompanyData = () => {
    setAccountInfo(resp);
    setAccoundDetails(resp?.account_details);
    setSubscriptionInfo(resp?.subscription_info);
    setBillingEmail(resp?.account_details?.account_billing_email);
    setBillingCCEmails(resp?.account_details?.account_billing_cc_emails);
    setBillingStreetAddress(resp?.account_details?.billing_address_street);
    setBillingCity(resp?.account_details?.billing_address_city);
    setBillingState(resp?.account_details?.billing_address_state);
    setBillingCountry(resp?.account_details?.billing_address_country);
    setBillingAddressZipcode(resp?.account_details?.billing_address_zipcode);
  };

  useEffect(() => {
    resp && setCompanyData();
  }, [resp]);

  const [paymentMethods, setPaymentMethods] = useState(null);

  useEffect(() => {
    groupId && getAllPaymentMethods();
  }, [groupId]);

  const getAllPaymentMethods = () => {
    handleSpringDataRequest(
      `core/api/v1/account-payment-method/get-all/?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setPaymentMethods(res);
      })
      .catch((err) => {
        console.log("err is : ", err);
      });
  };

  const [addNewCard, setAddNewCard] = useState(false);
  const openAddNewCard = () => {
    setAddNewCard(true);
  };

  const hideAddNewCard = () => {
    setAddNewCard(false);
    setAddCardNumber("");
    setAddCardExpiry("");
    setAddCardCVV("");
    setIsActive(false);
    setAddAccountNumber();
    setAddRoutingNumber();
    setAddAccountHolderName();
    setIsActive(false);
    setAccountType("savings");
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [addCardFirstName, setAddCardFirstName] = useState("");
  const [addCardLastName, setAddCardLastName] = useState("");
  const [addCardNumber, setAddCardNumber] = useState("");
  const [addAccountNumber, setAddAccountNumber] = useState();
  const [addAccountHolderName, setAddAccountHolderName] = useState();
  const [addRoutingNumber, setAddRoutingNumber] = useState();
  const [accountType, setAccountType] = useState("savings");
  const [addCardType, setAddCardType] = useState();
  const [addCardCVV, setAddCardCVV] = useState("");
  const [addCardExpiry, setAddCardExpiry] = useState("");
  const [saveCardloader, setsaveCardloader] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("account");
  const [cardType, setCardType] = useState("credit");
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    number: false,
    cvv: false,
    expiry: false,
    accountNumber: false,
    routingNumber: false,
    accountHolderName: false,
  });

  const checkExpiry = () => {
    const currentYear = new Date()?.getFullYear();
    const currentMonth = new Date()?.getMonth();
    const minExpiry =
      (currentMonth > 8 ? `${currentMonth + 1}` : `0${currentMonth + 1}`) +
      `${currentYear}`.slice(2, 4);
    const expiryValidity =
      addCardExpiry?.length === 4 &&
      (addCardExpiry?.slice(2, 4) > minExpiry?.slice(2, 4)
        ? true
        : addCardExpiry?.slice(2, 4) === minExpiry?.slice(2, 4)
        ? addCardExpiry?.slice(0, 2) >= minExpiry?.slice(0, 2)
        : false);
    return expiryValidity;
  };

  const checkLuhn = () => {
    let whitelist = [
      "4111111111111111",
      "4012888888881881",
      "4005562231212149",
      "5454545454545454",
      "5405222222222226",
      "6011000995500000",
      "371449635398431",
    ];
    if (whitelist.includes(addCardNumber)) {
      return true;
    }
    let s = 0;
    let doubleDigit = false;
    for (let i = addCardNumber?.length - 1; i >= 0; i--) {
      let digit = +addCardNumber[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    let flag = s % 10 === 0;
    if (flag) {
    }
    return flag;
  };

  const saveCardDetails = async () => {
    let last4 =
      addCardNumber &&
      addCardNumber?.length > 0 &&
      addCardNumber?.replace(/\s/g, "")?.slice(-4);
    let length = addCardNumber.length;
    let issuer =
      addCardNumber &&
      addCardNumber?.length > 0 &&
      getCardType(addCardNumber?.slice(0, length)) !== undefined &&
      getCardType(addCardNumber?.slice(0, length))?.type;
    const paymentPayload = {
      group_id: groupId,
      card_last4: addCardNumber,
      card_exp_date: addCardExpiry,
      card_cvv: addCardCVV,
      payment_method_type: paymentMethod,
      card_token: "",
      card_type: cardType,
      card_issuer: issuer,
      ach_account_type: "",
      ach_account_last4: "",
      ach_account_token: "",
      ach_routing_number: "",
      active: isActive ? "YES" : "NO",
      account_holder_name: "",
    };

    if (
      checkExpiry() &&
      checkLuhn() &&
      addCardNumber.length > 12 &&
      addCardCVV?.length > 0 &&
      addCardCVV?.length <= 4 &&
      getCardType(addCardNumber?.slice(0, length)) !== undefined
    ) {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/account-payment-method/create/?groupId=${groupId}&hotelId=${groupId}`,
        paymentPayload
      )
        .then((res) => {
          toast.success("Payment method saved successfully");
          getAllPaymentMethods();
          hideAddNewCard();
          setAddCardNumber("");
          setAddCardExpiry("");
          setAddCardCVV("");
          setIsActive(false);
        })
        .catch((err) => {
          toast.error("Something went wrong");
          hideAddNewCard();
          setAddCardNumber("");
          setAddCardExpiry("");
          setAddCardCVV("");
          setIsActive(false);
        });
    }

    if (getCardType(addCardNumber?.slice(0, length)) === undefined) {
      toast.error("Invalid card number");
      error["number"] = true;
    }

    if (addCardCVV?.length < 3) {
      error["cvv"] = true;
    }

    if (addCardFirstName?.length === 0) {
      error["firstName"] = true;
    }

    if (addCardLastName?.length === 0) {
      error["lastName"] = true;
    }

    if (!checkLuhn() || addCardNumber.length < 12) {
      error["number"] = true;
    }

    if (!checkExpiry()) {
      error["expiry"] = true;
    }

    setError({ ...error });
  };

  const saveAccountDetails = async () => {
    let last4 = addAccountNumber?.replace(/\s/g, "")?.slice(-4);
    const paymentPayload = {
      group_id: groupId,
      card_last4: "",
      card_exp_date: "",
      card_cvv: "",
      payment_method_type: paymentMethod,
      card_token: "",
      card_type: "",
      card_issuer: "",
      ach_account_type: accountType,
      ach_account_last4: addAccountNumber,
      ach_account_token: "",
      ach_routing_number: addRoutingNumber,
      active: isActive ? "YES" : "NO",
      account_holder_name: addAccountHolderName,
    };
    if (
      addAccountNumber &&
      addAccountNumber?.length > 0 &&
      addRoutingNumber &&
      addRoutingNumber?.length > 0 &&
      addAccountHolderName &&
      addAccountHolderName?.length > 0
    ) {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/account-payment-method/create/?groupId=${groupId}&hotelId=${groupId}`,
        paymentPayload
      )
        .then((res) => {
          toast.success("Payment method saved successfully");
          getAllPaymentMethods();
          hideAddNewCard();
          setAddAccountNumber();
          setAddRoutingNumber();
          setAddAccountHolderName();
          setIsActive(false);
          setAccountType("savings");
        })
        .catch((err) => {
          console.log("err is : ", err);
          toast.error("Something went wrong");
          hideAddNewCard();
          setAddAccountNumber();
          setAddRoutingNumber();
          setAddAccountHolderName();
          setIsActive(false);
          setAccountType("savings");
        });
    }

    if (addAccountNumber && addAccountNumber?.length === 0) {
      error["accountNumber"] = true;
    }

    if (addRoutingNumber && addRoutingNumber?.length === 0) {
      error["routingNumber"] = true;
    }

    if (addAccountHolderName && addAccountHolderName?.length === 0) {
      error["accountHolderName"] = true;
    }

    setError({ ...error });
  };

  useEffect(() => {
    setError({
      number: false,
      cvv: false,
      expiry: false,
    });
  }, [addCardNumber, addCardExpiry, addCardCVV]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const deletePaymentMethod = (id) => {
    handleSpringDataMutationRequest(
      "DELETE",
      `core/api/v1/account-payment-method/delete-payment-method/?id=${id}&hotelId=${groupId}`
    )
      .then((res) => {
        toast.success("Payment method deleted successfully");
        getAllPaymentMethods();
      })
      .catch((err) => {
        console.log("err is : ", err);
        toast.error("Something went wrong");
      });
  };

  const setAsActive = (id) => {
    handleSpringDataMutationRequest(
      "PUT",
      `core/api/v1/account-payment-method/update-active-status/?id=${id}&groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        toast.success("Payment method set to active");
        getAllPaymentMethods();
      })
      .catch((err) => {
        console.log("err is : ", err);
        toast.error("Something went wrong");
      });
  };

  const cardsMap = {
    visa: "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/visa.png",
    mastercard:
      "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/mastercard.png",
    amex: "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/amex-card.png",
    discover:
      "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/discover.png",
  };
  const cardsGetStartMap = {
    visa: "VI...",
    mastercard: "MC...",
    amex: "AX...",
    discover: "DC...",
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h4"
          style={{ fontWeight: "400", marginBottom: "2rem" }}
        >
          Payment Methods
        </Typography>
        <Button
          variant="custom-button"
          style={{ fontWeight: "400", marginBottom: "2rem" }}
          onClick={() => openAddNewCard()}
        >
          Add payment method
        </Button>
      </div>

      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "#F5F8FA" }}>
              <TableRow>
                <TableCell style={{ width: "44.33%" }}>
                  PAYMENT METHOD
                </TableCell>
                {/* <TableCell>BILLING ADDRESS</TableCell> */}
                <TableCell style={{ width: "33.33%" }}>
                  EXPIRATION DATE
                </TableCell>
                <TableCell style={{ width: "33.33%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods &&
                paymentMethods
                  ?.filter((item) => !item?.hotel_id)
                  .map((res) => {
                    let month = res?.card_exp_date.slice(0, 2);
                    let year = res?.card_exp_date.slice(2);
                    let issuer = res?.card_issuer;
                    return (
                      <TableRow
                        key="xyz"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: "44.33%" }}
                        >
                          {res?.payment_method_type === "card" ? (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "4px",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={cardsMap[issuer]}
                                  style={{
                                    width: "4rem",
                                    height:
                                      issuer === "visa" ? "1.5rem" : "2rem",
                                    marginTop: issuer === "visa" && "6px",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "4px",
                                }}
                              >
                                <p
                                  style={{ fontSize: "18px" }}
                                  className="text-muted"
                                >
                                  {cardsGetStartMap[issuer] + res?.card_last4}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {/* <div
                              style={{
                                display: "flex",
                                marginTop: "4px",
                                marginRight: "10px",
                              }}
                            >
                              <p
                                style={{ fontSize: "18px" }}
                                className="text-muted"
                              >
                                {res?.payment_method_type.toUpperCase()}
                              </p>
                            </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "4px",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src="https://prodimagesbucket.blob.core.windows.net/accounts-assets/images/payment-methods/bank_account.png"
                                  style={{
                                    width: "4rem",
                                    // height: issuer === "visa" ? "1.5rem" : "2rem",
                                    // marginTop: issuer === "visa" && "6px",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "4px",
                                }}
                              >
                                <p
                                  style={{ fontSize: "18px" }}
                                  className="text-muted"
                                >
                                  {"AC..." + res?.ach_account_last4}
                                </p>
                              </div>
                            </div>
                          )}
                        </TableCell>
                        {/* <TableCell>
                        {billingStreetAddress +
                          ", " +
                          billingCity +
                          ", " +
                          billingState +
                          ", " +
                          billingCountry +
                          ", " +
                          billingAddressZipcode}
                      </TableCell> */}
                        <TableCell style={{ width: "33.33%" }}>
                          {res?.card_exp_date !== "" && month + "/" + year}
                        </TableCell>
                        <TableCell style={{ width: "33.33%" }}>
                          {res?.active === "NO" ? (
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "1.3rem",
                              }}
                            >
                              {/*
                            <div
                              onClick={() => {
                                deletePaymentMethod(res?.id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <DeleteForeverIcon sx={{ color: "#FF0000" }} />
                            </div>
                            */}
                              <div style={{ width: "12px" }}></div>
                              <div
                                onClick={() => {
                                  setAsActive(res?.id);
                                }}
                                className="badge"
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  background:
                                    "linear-gradient(-150deg, #1ab394, #1a91ae)",
                                }}
                              >
                                Set as primary
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "1.7rem",
                              }}
                            >
                              <div
                                className="badge"
                                style={{
                                  width: "8.2rem",
                                  color: "white",
                                  background:
                                    "linear-gradient(-150deg, #1ab394, #1a91ae)",
                                }}
                              >
                                Primary
                              </div>
                            </div>
                          )}
                          {/* <Button
                          style={{ fontWeight: "400", marginBottom: "2rem" }}
                        >
                          Actions
                        </Button> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Modal
        show={addNewCard}
        onHide={hideAddNewCard}
        dialogClassName={"myModalBodyeee"}
        // animation={false}
        contentClassName={"myModalContent"}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <Button variant="white" onClick={hideAddNewCard}>
              {" "}
              X{" "}
            </Button>
          </div>
          <div
            style={{ display: "flex", marginLeft: "2rem" }}
            className="d-flex align-items-center"
          >
            <h3 style={{ marginRight: "3rem", marginBottom: "0px" }}>
              Select payment method
            </h3>
            <div>
              <FormControl fullWidth sx={{ width: "12rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Payment method
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  label="Payment method"
                >
                  <MenuItem value="account">Bank Account</MenuItem>
                  <MenuItem value="card">Card</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </ModalTitle>
        <ModalBody>
          {paymentMethod === "card" ? (
            <div>
              <div className="amountWrapper" style={{ marginBottom: "2rem" }}>
                <div className="fieldWrapper d-flex justify-content-between">
                  <FormControl
                    fullWidth
                    sx={{ width: "30%", marginRight: "0.5rem" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Card type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={cardType}
                      onChange={(e) => setCardType(e.target.value)}
                      label="Card type"
                    >
                      <MenuItem value="credit">Credit</MenuItem>
                      <MenuItem value="debit">Debit</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <div className="fieldWrapper d-flex mt-4"> */}
                  <TextField
                    required
                    id="outlined-name"
                    label="Card Number"
                    name="cardNumber"
                    style={{ width: "68%", marginRight: "0.2rem" }}
                    type={"number"}
                    value={addCardNumber}
                    // onChange={(e) =>
                    //   e.target.value.length <= 16 &&
                    //   setAddCardNumber(e.target.value)
                    // }
                    // error={
                    //   (!checkLuhn() && addCardNumber?.length > 0) ||
                    //   error.number
                    //     ? true
                    //     : false
                    // }
                    // helperText={
                    //   (!checkLuhn() && addCardNumber?.length > 0) ||
                    //   error.number
                    //     ? "invalid card number"
                    //     : ""
                    // }
                    onChange={(e) =>
                      e.target.value.length <= 16 &&
                      (/^[0-9]*$/.test(e.target.value)
                        ? setAddCardNumber(e.target.value)
                        : setAddCardNumber(addCardNumber))
                    }
                    error={error.number || !checkLuhn() ? true : false}
                    helperText={
                      error.number || !checkLuhn() ? "invalid card number" : ""
                    }
                    // sx={{ width: "100%" }}
                  />
                  {getCardType(addCardNumber?.slice(0, 16)) && (
                    <img
                      style={{
                        width: "60px",
                        height: "40px",
                        marginTop: "5px",
                        marginLeft: "-80px",
                        marginRight: "9px",
                        objectFit: "contain",
                      }}
                      src={getCardType(addCardNumber?.slice(0, 16))?.imageUrl}
                      alt=" "
                    />
                  )}
                  {/* </div> */}
                  {/* <TextField
                    required
                    id="outlined-name"
                    type={"text"}
                    label="First name"
                    name="firstName"
                    value={addCardFirstName}
                    onChange={(e) =>
                      /^[a-zA-Z ]*$/.test(e.target.value) &&
                      setAddCardFirstName(e.target.value)
                    }
                    error={error.firstName}
                    helperText={
                      error.firstName
                        ? addCardFirstName?.length === 0
                          ? "Enter first name"
                          : "Enter correct name"
                        : ""
                    }
                    sx={{ width: "48%" }}
                  />
                  <TextField
                    required
                    id="outlined-name"
                    label="Last name"
                    name="lastName"
                    type={"text"}
                    value={addCardLastName}
                    onChange={(e) =>
                      /^[a-zA-Z ]*$/.test(e.target.value)
                        ? setAddCardLastName(e.target.value)
                        : setAddCardLastName(addCardLastName)
                    }
                    error={error.lastName}
                    helperText={
                      error.lastName
                        ? addCardLastName?.length === 0
                          ? "Enter last name"
                          : "Enter correct name"
                        : ""
                    }
                    sx={{ width: "48%" }}
                  /> */}
                </div>
                <div className="fieldWrapper d-flex justify-content-between mt-4">
                  <TextField
                    // required
                    id="outlined-name"
                    label="Card CVV"
                    name="cardCVV"
                    type={"number"}
                    value={cardNumberCVVFormat(addCardCVV)}
                    onChange={(e) => setAddCardCVV(e.target.value)}
                    sx={{ width: "48%" }}
                    error={addCardCVV?.length > 4 || error.cvv}
                    helperText={
                      addCardCVV?.length > 4 || error.cvv ? "invalid cvv" : ""
                    }
                  />
                  <TextField
                    required
                    id="outlined-name"
                    label="Card Expiry (MMYY)"
                    name="cardExpiry"
                    type={"number"}
                    value={addCardExpiry}
                    onChange={(e) =>
                      e.target.value?.length < 5 &&
                      (/^[0-9]*$/.test(e.target.value)
                        ? setAddCardExpiry(e.target.value)
                        : setAddCardExpiry(addCardExpiry))
                    }
                    error={
                      (!checkExpiry() && addCardExpiry?.length > 0) ||
                      error.expiry
                    }
                    helperText={
                      (!checkExpiry() && addCardExpiry?.length > 0) ||
                      error.expiry
                        ? "invalid expiry"
                        : ""
                    }
                    sx={{ width: "48%" }}
                  />
                </div>
                <div style={{ display: "flex", marginTop: "0.5rem" }}>
                  <h6 className="mr-3 mb-0 mt-3">
                    Set as active payment method
                  </h6>
                  <Checkbox
                    onChange={(e) => {
                      setIsActive(e.target.checked);
                    }}
                  />
                </div>
              </div>
              <Button
                variant="custom-button"
                disabled={addCardNumber?.length > 12 ? false : true}
                onClick={saveCardDetails}
              >
                {saveCardloader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          ) : (
            <div>
              <div className="amountWrapper" style={{ marginBottom: "2rem" }}>
                {/* <div className="fieldWrapper d-flex justify-content-between">
                  <TextField
                    required
                    id="outlined-name"
                    type={"text"}
                    label="First name"
                    name="firstName"
                    value={addCardFirstName}
                    onChange={(e) =>
                      /^[a-zA-Z ]*$/.test(e.target.value) &&
                      setAddCardFirstName(e.target.value)
                    }
                    error={error.firstName}
                    helperText={
                      error.firstName
                        ? addCardFirstName?.length === 0
                          ? "Enter first name"
                          : "Enter correct name"
                        : ""
                    }
                    sx={{ width: "48%" }}
                  />
                  <TextField
                    required
                    id="outlined-name"
                    label="Last name"
                    name="lastName"
                    type={"text"}
                    value={addCardLastName}
                    onChange={(e) =>
                      /^[a-zA-Z ]*$/.test(e.target.value)
                        ? setAddCardLastName(e.target.value)
                        : setAddCardLastName(addCardLastName)
                    }
                    error={error.lastName}
                    helperText={
                      error.lastName
                        ? addCardLastName?.length === 0
                          ? "Enter last name"
                          : "Enter correct name"
                        : ""
                    }
                    sx={{ width: "48%" }}
                  />
                </div> */}
                <div className="fieldWrapper d-flex mt-4">
                  <TextField
                    required
                    id="outlined-name"
                    label="Account holder name"
                    name="accountHolderName"
                    type={"text"}
                    value={addAccountHolderName}
                    onChange={(e) =>
                      // e.target.value.length <= 18 &&
                      setAddAccountHolderName(e.target.value)
                    }
                    error={
                      addAccountHolderName?.length === 0 ||
                      error.accountHolderName
                        ? true
                        : false
                    }
                    helperText={
                      addAccountHolderName?.length === 0 ||
                      error.accountHolderName
                        ? "invalid account holder name"
                        : ""
                    }
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="fieldWrapper d-flex justify-content-between mt-4">
                  <FormControl
                    fullWidth
                    sx={{ width: "12rem", marginRight: "0.5rem" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Account type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={accountType}
                      onChange={(e) => {
                        setAccountType(e.target.value);
                      }}
                      label="Account type"
                    >
                      <MenuItem value={"savings"}>Savings</MenuItem>
                      <MenuItem value={"checking"}>Checking</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    required
                    id="outlined-name"
                    label="Routing Number"
                    name="routingNumber"
                    type={"number"}
                    value={addRoutingNumber}
                    onChange={(e) =>
                      e.target.value.length <= 20 &&
                      setAddRoutingNumber(e.target.value)
                    }
                    error={
                      addRoutingNumber?.length < 7 || error.routingNumber
                        ? true
                        : false
                    }
                    helperText={
                      addRoutingNumber?.length < 7 || error.routingNumber
                        ? "invalid routing number"
                        : ""
                    }
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="fieldWrapper d-flex mt-4">
                  <TextField
                    required
                    id="outlined-name"
                    label="Account Number"
                    name="accountNumber"
                    type={"number"}
                    value={addAccountNumber}
                    onChange={(e) =>
                      e.target.value.length <= 100 &&
                      setAddAccountNumber(e.target.value)
                    }
                    error={
                      addAccountNumber?.length < 7 || error.accountNumber
                        ? true
                        : false
                    }
                    helperText={
                      addAccountNumber?.length < 7 || error.accountNumber
                        ? "invalid account number"
                        : ""
                    }
                    sx={{ width: "100%" }}
                  />

                  {/* {getCardType(addCardNumber?.slice(0, 16)) && (
                    <img
                      style={{
                        width: "60px",
                        height: "40px",
                        marginTop: "5px",
                        marginLeft: "-80px",
                        objectFit: "contain",
                      }}
                      src={getCardType(addCardNumber?.slice(0, 16))?.imageUrl}
                      alt=" "
                    />
                  )} */}
                </div>

                <div style={{ display: "flex", marginTop: "0.5rem" }}>
                  <h6 className="mr-3 mb-0 mt-3">
                    Set as active payment method
                  </h6>
                  <Checkbox
                    onChange={(e) => {
                      setIsActive(e.target.checked);
                    }}
                  />
                </div>
              </div>
              <Button
                disabled={
                  addRoutingNumber?.length >= 7 &&
                  addAccountNumber?.length >= 7 &&
                  addAccountHolderName?.length >= 6
                    ? false
                    : true
                }
                variant="custom-button"
                onClick={saveAccountDetails}
              >
                {saveCardloader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentMethods;

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
