import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import BusinessIcon from "@mui/icons-material/Business";
import { BASE_ENDPOINT, BASE_SPRING_ENDPOINT } from "../../api/config";
import {
  CardHeader,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
  Autocomplete,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import company from "./sss.svg";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import moment from "moment";
import { getCountriesAndStates } from "../CalendarPage/constants/Calendar-constants";
import { isNull, isUndefined } from "lodash";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import Currencies from "../../Utils/Currencies.json";

const CompanyInfo = (props) => {
  const [groupId, setGroupId] = useState(null);
  const [resp, setResp] = useState(null);
  const [hotelCurr, setHotelCurr] = useState(null);

  useEffect(() => {
    // console.log("props : ", props);
    let currency =
      props?.res?.account_details?.billing_address_country?.toLowerCase() ===
      "india"
        ? "INR"
        : "USD";
    setGroupId(props?.groupId);
    setResp(props?.res);
    setHotelCurr(currency);
  }, [props]);
  // const style = {
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   // progress: undefined,
  // };

  function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(mail)) {
      setvalidEmail(true);
      return true;
    }
    setvalidEmail(false);
    return false;
  }

  const [currSymbol, setCurrSymbol] = useState(null);
  useEffect(() => {
    let curr = hotelCurr === "INR" ? "Rs." : "$";
    // const datass = Currencies?.filter((item) => {
    //   if (item.code === hotelCurr) {
    //     curr = item.symbol;
    //   }
    // });
    setCurrSymbol(curr);
  }, [hotelCurr]);

  const [accountInfo, setAccountInfo] = useState(null);
  const [accoundDetails, setAccoundDetails] = useState(null);
  const [paymentMethods, setpaymentMethods] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [billingEmail, setBillingEmail] = useState("");
  const [billingCCEmails, setBillingCCEmails] = useState("");
  const [billingStreetAddress, setBillingStreetAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingAddressZipcode, setBillingAddressZipcode] = useState("");
  const [accountBillingPan, setAccountBillingPan] = useState("");
  const [accountBillingPhone, setAccountBillingPhone] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [accountSuspend, setaccountSuspend] = useState("NO");
  const countries = getCountriesAndStates();

  const setCompanyData = () => {
    setAccountInfo(resp);
    setAccoundDetails(resp?.account_details);
    setpaymentMethods(resp?.payment_methods);
    setSubscriptionInfo(resp?.subscription_info);
    setBillingEmail(resp?.account_details?.account_billing_email);
    setBillingCCEmails(resp?.account_details?.account_billing_cc_emails);
    setBillingStreetAddress(resp?.account_details?.billing_address_street);
    setBillingCity(resp?.account_details?.billing_address_city);
    setBillingState(resp?.account_details?.billing_address_state);
    setBillingCountry(resp?.account_details?.billing_address_country);
    setBillingAddressZipcode(resp?.account_details?.billing_address_zipcode);
    setAccountBillingPan(resp?.account_details?.account_billing_pan);
    setAccountBillingPhone(resp?.account_details?.account_billing_phone);
    setaccountSuspend(resp?.account_details?.freezed);
  };

  useEffect(() => {
    resp && setCompanyData();
  }, [resp]);

  const saveAccountDetails = () => {
    const accountInfo = accoundDetails;
    accountInfo.account_billing_email = billingEmail;
    accountInfo.billing_address_street = billingStreetAddress;
    accountInfo.billing_address_city = billingCity;
    accountInfo.billing_address_state = billingState;
    accountInfo.billing_address_country = billingCountry;
    accountInfo["billing_address_zipcode"] = billingAddressZipcode;
    accountInfo.account_billing_pan = accountBillingPan;
    accountInfo.account_billing_phone = accountBillingPhone;
    accountInfo.freezed = accountSuspend;

    if (billingEmail !== "") {
      if (billingStreetAddress !== "") {
        if (billingCity !== "") {
          if (billingAddressZipcode !== "") {
            if (validEmail) {
              handleSpringDataMutationRequest(
                "PUT",
                `core/api/v1/account/update/?groupId=${groupId}&hotelId=${groupId}`,
                accountInfo
              )
                .then((res) => {
                  toast.success("Billing info updated successfully");
                })
                .catch((err) => {
                  toast.error("Something wrong happened");
                });
            } else {
              toast.error("Please enter correct email");
            }
          }
        }
      }
    } else {
      toast.error("Please enter all fields");
    }
  };

  const downloadContract = () => {
    handleSpringDataRequest(
      `core/api/v1/account/download-account-contract/${groupId}?hotelId=${groupId}`
    )
      .then((res) => {
        // setAccountInfo(res);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        // toast.error(err);
      });
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [validEmail, setvalidEmail] = useState(true);

  const [firstAddress, setfirstAddress] = useState(null);
  const [secondAddress, setsecondAddress] = useState(null);

  useEffect(() => {
    if (accoundDetails) {
      let first =
        accoundDetails.billing_address_street !== ""
          ? accoundDetails.billing_address_street
          : "";
      if (first.length > 0)
        first =
          accoundDetails.billing_address_city !== ""
            ? first + ", " + accoundDetails.billing_address_city
            : first;
      else {
        first =
          accoundDetails.billing_address_city !== ""
            ? accoundDetails.billing_address_city
            : first;
      }

      let second =
        accoundDetails.billing_address_state !== ""
          ? accoundDetails.billing_address_state
          : "";
      if (second.length > 0)
        second =
          accoundDetails.billing_address_country !== ""
            ? second + ", " + accoundDetails.billing_address_country
            : second;
      else {
        second =
          accoundDetails.billing_address_country !== ""
            ? accoundDetails.billing_address_country
            : second;
      }
      setfirstAddress(first);
      setsecondAddress(second);
    }
  }, [accoundDetails]);

  return (
    <div>
      <Typography
        variant="h4"
        style={{ fontWeight: "400", marginBottom: "2rem" }}
      >
        {/* Company Information */}
        Account Information
      </Typography>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <div
              style={{
                display: "flex",
                direction: "row",
                width: "50%",
              }}
              spacing={2}
            >
              <div style={{ padding: "3rem" }}>
                <img src={company} style={{ height: "12rem" }} />
              </div>
              <div>
                <Typography variant="h5" style={{ fontWeight: "400" }}>
                  {/* Company Name */}
                  Account Name
                </Typography>
                {accoundDetails && (
                  <Typography>{accoundDetails.account_name}</Typography>
                )}
                <Typography
                  variant="h5"
                  style={{ marginTop: "4rem", fontWeight: "400" }}
                >
                  Primary company address
                </Typography>
                {firstAddress && <Typography>{firstAddress}</Typography>}
                {secondAddress && <Typography>{secondAddress}</Typography>}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                marginRight: "3rem",
              }}
            >
              {accoundDetails &&
                accoundDetails.contract_upload &&
                accoundDetails.contract_upload !== null &&
                accoundDetails.contract_upload !== "" && (
                  // <button
                  //   className="btn btn-light-primary btn-bold"
                  //   style={{
                  //     height: "fit-content",
                  //     fontSize: "20px",
                  //     paddingTop: "5px",
                  //     paddingBottom: "5px",
                  //   }}
                  //   // variant="custom_button custom-button"
                  //   onClick={() => {
                  //     downloadContract();
                  //   }}
                  // >
                  //   Download contract
                  // </button>
                  <a
                    type="button"
                    style={{ fontSize: "1.3rem", color: "#1BC5BD" }}
                    href={
                      BASE_SPRING_ENDPOINT +
                      "core/api/v1/account/download-account-contract/" +
                      groupId +
                      "?hotelId=" +
                      groupId
                    }
                  >
                    Download contract
                  </a>
                  // <a
                  //   type="button"
                  //   style={{ fontSize: "1.3rem", color: "#1BC5BD" }}

                  //   onClick={() => downloadContract()}
                  //   // href={
                  //   //   "https://stayflexi.com/media/" +
                  //   //   accoundDetails.contract_upload
                  //   // }
                  // >
                  //   Download contract
                  // </a>
                )}
            </div>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "400",
            marginTop: "1rem",
            marginLeft: "2rem",
          }}
        >
          Basic Info
        </Typography>
        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <Grid container spacing={2}>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={4}>
                  <Typography className="mt-4" variant="h6">
                    Group id
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accoundDetails?.group_id}
                    // onChange={(e) => setbookingFolioPrefix(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Account name
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accoundDetails?.account_name}
                    // onChange={(e) => setbookingFolioSeqStart(e.target.value)}
                  />
                  <p className="text-muted">
                    Email used when onboarding the property(s)
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="mt-4" variant="h6">
                    Account billing timezone
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accoundDetails?.account_billing_timezone}
                    // onChange={(e) => setbookingFolioPrefix(e.target.value)}
                  />
                  <p className="text-muted">
                    Timezone to be used for generating invoices
                  </p>
                </Grid>
                {/* <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Account CSM
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accoundDetails?.account_csm}
                    // onChange={(e) => setbookingFolioSuffix(e.target.value)}
                  />
                  <p className="text-muted">
                    Who is the customer success manager for this account?
                  </p>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Support Level
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accoundDetails?.support_level}
                    // onChange={(e) => setbookingFolioSuffix(e.target.value)}
                  />
                  <p className="text-muted">Support level for this account?</p>
                </Grid> */}
              </Grid>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Account tax id
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accoundDetails?.account_tax_id}
                    // onChange={(e) => setbookingFolioSeqStart(e.target.value)}
                  />
                  <p className="text-muted">
                    Account Tax ID. For e.g, GST for India
                  </p>
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Signup date
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={moment(accoundDetails?.account_signup_date).format(
                      "DD-MM-YYYY"
                    )}
                    // onChange={(e) => setbookingFolioSuffix(e.target.value)}
                  />
                  <p className="text-muted">When was this account onboarded?</p>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <Typography className="mt-4" variant="h6">
                    Account Notes
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    id="outlined-basic"
                    name="aboutUsFullText"
                    label="hello"
                    multiline
                    rows={5}
                    maxRows={25}
                    variant="outlined"
                    value={accoundDetails?.account_status_log}
                    // onChange={handleEditDetails("aboutUsFullText")}
                    sx={{ width: "100.5rem" }}
                    type="text"
                  />
                  <p className="text-muted">Log any notes for the account</p>
                </Grid>
              </Grid> */}
            </Grid>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "400",
            marginTop: "1rem",
            marginLeft: "2rem",
          }}
        >
          Billing Information
        </Typography>
        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <Grid container spacing={2}>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={4}>
                  <Typography className="mt-4" variant="h6">
                    Billing interval
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="Billing Interval"
                    className="mt-3"
                    fullWidth
                    value={
                      accoundDetails?.account_billing_interval_months +
                      " Months"
                    }
                    // onChange={(e) => setbookingFolioPrefix(e.target.value)}
                  />
                  <p className="text-muted">Billing interval in months</p>
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Billing email
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    notched={false}
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingEmail}
                    error={!validEmail}
                    onChange={(e) => {
                      ValidateEmail(e.target.value);
                      setBillingEmail(e.target.value);
                    }}
                  />
                  {!validEmail && (
                    <FormHelperText error>Please enter email!</FormHelperText>
                  )}
                  <p className="text-muted">
                    Email address to which the invoice will be sent
                  </p>
                </Grid>
                {/* <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Billing CC emails
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingCCEmails}
                    onChange={(e) => setBillingCCEmails(e.target.value)}
                  />
                  <p className="text-muted">
                    Comma seperated email addresses to which invoice email
                    should be copied to
                  </p>
                </Grid> */}
                <Grid item xs={4}>
                  <Typography className="mt-4" variant="h6">
                    Billing street address
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    notched={false}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingStreetAddress}
                    error={billingStreetAddress === "" ? true : false}
                    onChange={(e) => setBillingStreetAddress(e.target.value)}
                  />
                  {billingStreetAddress === "" && (
                    <FormHelperText error>Please enter address!</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={4}>
                  <Typography
                    className="mt-4"
                    variant="h6"
                    style={{ marginBottom: "9px" }}
                  >
                    Billing country
                  </Typography>{" "}
                  <Autocomplete
                    fullWidth
                    onChange={(eve, val) => {
                      setBillingCountry(val);
                    }}
                    options={Object.keys(countries)}
                    value={billingCountry}
                    renderInput={(params) => {
                      params.inputProps.autoComplete = "new-password";
                      return (
                        <TextField {...params} label="Country" size="small" />
                      );
                    }}
                  ></Autocomplete>
                  {/* <OutlinedInput
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingCountry}
                    onChange={(e) => setBillingCountry(e.target.value)}
                  /> */}
                </Grid>

                <Grid item xs={4}>
                  {" "}
                  <Typography
                    className="mt-4"
                    variant="h6"
                    style={{ marginBottom: "9px" }}
                  >
                    Billing state
                  </Typography>{" "}
                  <Autocomplete
                    fullWidth
                    renderInput={(params) => {
                      params.inputProps.autoComplete = "new-password";
                      return (
                        <TextField {...params} label="State" size="small" />
                      );
                    }}
                    onChange={(eve, val) => {
                      setBillingState(val);
                    }}
                    options={
                      isNull(billingCountry) ||
                      isUndefined(countries[billingCountry])
                        ? []
                        : countries[billingCountry]
                    }
                    value={billingState}
                  ></Autocomplete>
                  {/* <OutlinedInput
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingState}
                    onChange={(e) => setBillingState(e.target.value)}
                  /> */}
                </Grid>

                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Billing city
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    notched={false}
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingCity}
                    error={billingCity === "" ? true : false}
                    onChange={(e) => setBillingCity(e.target.value)}
                  />
                  {billingCity === "" && (
                    <FormHelperText error>Please enter city!</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Billing address zipcode
                  </Typography>{" "}
                  <OutlinedInput
                    /// disabled={true}
                    size="small"
                    notched={false}
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingAddressZipcode}
                    error={billingAddressZipcode === "" ? true : false}
                    onChange={(e) => setBillingAddressZipcode(e.target.value)}
                  />
                  {billingAddressZipcode === "" && (
                    <FormHelperText error>Please enter zipcode!</FormHelperText>
                  )}
                </Grid>
                {props?.res?.account_details?.billing_address_country?.toLowerCase() ===
                  "india" && (
                  <Grid item xs={4}>
                    {" "}
                    <Typography className="mt-4" variant="h6">
                      Account billing Pan
                    </Typography>{" "}
                    <OutlinedInput
                      /// disabled={true}
                      size="small"
                      notched={false}
                      label="hello"
                      className="mt-3"
                      fullWidth
                      value={accountBillingPan}
                      error={accountBillingPan === "" ? true : false}
                      onChange={(e) => setAccountBillingPan(e.target.value)}
                    />
                    {accountBillingPan === "" && (
                      <FormHelperText error>
                        Please enter Pan number!
                      </FormHelperText>
                    )}
                  </Grid>
                )}
                {props?.res?.account_details?.billing_address_country?.toLowerCase() ===
                  "india" && (
                  <Grid item xs={4}>
                    {" "}
                    <Typography className="mt-4" variant="h6">
                      Account billing phone
                    </Typography>{" "}
                    <OutlinedInput
                      /// disabled={true}
                      size="small"
                      notched={false}
                      label="hello"
                      className="mt-3"
                      fullWidth
                      value={accountBillingPhone}
                      error={accountBillingPhone === "" ? true : false}
                      onChange={(e) => setAccountBillingPhone(e.target.value)}
                    />
                    {accountBillingPan === "" && (
                      <FormHelperText error>
                        Please enter phone number!
                      </FormHelperText>
                    )}
                  </Grid>
                )}

                {/* <Grid item xs={4}>
                  <Typography className="mt-4" variant="h6">
                    Account suspend
                  </Typography>
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Account suspend
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={accountSuspend}
                      label="Account suspend"
                      onChange={(e) => {
                        setaccountSuspend(e.target.value);
                      }}
                      size="small"
                    >
                      <MenuItem value={"YES"}>Yes</MenuItem>
                      <MenuItem value={"NO"}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <p className="text-muted">
                    Account will be be suspend if select yes
                  </p>
                </Grid> */}
              </Grid>
            </Grid>
          </div>

          <div style={{ marginTop: "2rem" }}>
            <Button
              variant="custom-button"
              onClick={() => saveAccountDetails()}
            >
              Save
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "400",
            marginTop: "1rem",
            marginLeft: "2rem",
          }}
        >
          Account Subscription Information
        </Typography>
        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "#F5F8FA" }}>
                  <TableRow>
                    <TableCell>Property ID</TableCell>
                    <TableCell style={{ width: "50rem" }}>
                      Property name
                    </TableCell>
                    <TableCell>{"Description"}</TableCell>
                    <TableCell>{"Start date"}</TableCell>
                    <TableCell>{"Status"}</TableCell>
                    <TableCell>{"Amount"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionInfo &&
                    subscriptionInfo?.line_items?.length > 0 &&
                    subscriptionInfo.line_items.map((res) => (
                      <TableRow
                        key="xyz"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ width: "20rem" }}
                          component="th"
                          scope="row"
                          // onClick={() => {
                          //   toggleHotelId(res.hotelId);
                          // }}
                        >
                          {res.hotel_id}
                        </TableCell>
                        <TableCell style={{ width: "30rem" }}>
                          {res.line_item_name}
                        </TableCell>
                        <TableCell style={{ width: "50rem" }}>
                          {res.description}
                        </TableCell>
                        <TableCell style={{ width: "50rem" }}>
                          {res.start_date}
                        </TableCell>
                        <TableCell style={{ width: "50rem" }}>
                          {res.status}
                        </TableCell>
                        <TableCell style={{ width: "10rem" }}>
                          {res.amount && true && res.amount > 0 && (
                            <span>{currSymbol + "" + res.amount}</span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  {subscriptionInfo &&
                    subscriptionInfo.total_account_subscription_amount !==
                      undefined &&
                    subscriptionInfo.line_items.length > 0 && (
                      <TableRow
                        key="xyz"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          // onClick={() => {
                          //   toggleHotelId(res.hotelId);
                          // }}
                        >
                          Total
                        </TableCell>
                        <TableCell style={{ width: "50rem" }}></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {currSymbol +
                            "" +
                            subscriptionInfo.total_account_subscription_amount}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </CardContent>
      </Card>
      {/* <Typography
        variant="h4"
        style={{ fontWeight: "400", marginTop: "2rem", marginBottom: "2rem" }}
      >
        Points of Contact
      </Typography>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Card
          sx={{ minWidth: 350 }}
          style={{ marginRight: "4rem", width: "50%" }}
        >
          <CardContent>
            <div
              style={{
                display: "flex",
                direction: "row",
              }}
              spacing={2}
            >
              <div>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "400", marginBottom: "2rem" }}
                >
                  Primary Account Contact
                </Typography>
                <Typography>
                  The main person working with the account on a daily basis.
                  This user will receive all important account notifications
                  (including invoices, upgrades, renewals etc.)
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }} style={{ width: "50%" }}>
          <CardContent>
            <div
              style={{
                display: "flex",
                direction: "row",
              }}
              spacing={2}
            >
              <div>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "400", marginBottom: "2rem" }}
                >
                  Billing Contacts
                </Typography>
                <Typography>
                  Send copies of invoices, receipts, orders and other renewal
                  notifications to these Platform users.
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </div> */}
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(CompanyInfo);
// export default CompanyInfo;
