// ** React Imports
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { cloneDeep } from "lodash";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Chip,
  ClickAwayListener,
  Divider,
  Fab,
  FormControl,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  TextField,
  Button,
  Typography,
  Switch,
  InputAdornment,
  Checkbox,
  ListItemText,
} from "@mui/material";
import * as AiIcons from "react-icons/ai";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { makeStyles, useTheme } from "@mui/styles";
import moment from "moment";
import { weekdays } from "../constants/InventoryConstants";
import {
  applyRestrictions,
  getConditionalChannels,
  getConditionalChannelsWithHotelId,
  getCtaAccountForRatePlan,
  getCtaAccountForRatePlanWithHotelId,
  getCtaNamesNew,
  getCtaNamesNewWithHotelId,
  getRoomInformation,
} from "../api/InventoryApi";
import { dateFormat } from "../../CalendarPage/constants/Calendar-constants";
import { shallowEqual, useSelector } from "react-redux";
import { isArray } from "lodash";
import { ENV } from "../../CalendarPage/constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import { toast } from "material-react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import "../Views/OtASync.css";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(2),
    float: "right",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const GroupOpenCloseViews = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const {
    selectedRoom,
    rooms,
    setLineInvisible,
    setLineVisible,
    refresh,
    componentType,
    roomAndPlans,
    selectedLanguage,
    hotelId,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(
        new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
    },
  ]);

  const [bulkAllRooms, setbulkAllRooms] = useState(false);
  const [minAdvOffSetValue, setminAdvOffSetValue] = useState(null);
  const [maxAdvOffSetValue, setmaxAdvOffSetValue] = useState(null);
  const [maxLosValue, setmaxLosValue] = useState(null);
  const [minLosValue, setminLosValue] = useState(null);

  //Amplitude check on Bulk Operations page
  const amplitudeEventProperties = {
    hotel_id: hotel?.hotelId,
    environment: ENV,
    email: hotel?.accessControl?.email,
    role: hotel?.accessControl?.ADMINRIGHTS,
  };
  const [selectDays, setSelectDays] = useState(weekdays);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ratePlans, setRatePlans] = useState([]);
  const [selectedRatePlan, setSelectedRatePlan] = useState("");
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedCTA, setSelectedCTA] = useState([]);
  const [ctaAccounts, setCta] = useState([]);
  const [lengthOfStay, setLengthOfStay] = useState(null);
  const [cutOff, setcutOff] = useState(null);
  const [maxAdvOffset, setmaxAdvOffset] = useState(null);
  const [closedOnDepaturevalue, setclosedOnDepaturevalue] = useState(null);
  const [closeOnArrivalValue, setcloseOnArrivalValue] = useState(null);
  const [closeOpenRatePlanValue, setcloseOpenRatePlanValue] = useState(null);
  const [closeRoomTypevlaue, setcloseRoomTypevlaue] = useState(null);

  const applyRestrictionsOptions = {
    hotelId: "",
    roomType: "",
    ratePlanId: "",
    fromDate: "",
    toDate: "",
    daysIncluded: [],
    stopSell: null,
    stopRTSell: null,
    closedOnArrival: null,
    closedOnDeparture: null,
    minLos: null,
    maxLos: null,
    channelList: [],
    ctas: [],
    cutoff: null,
    isCta: null,
  };

  const [selectedFilter, setSelectedFilter] = useState("Inventory");
  const [copyBulkEditPayload, setcopyBulkEditPayload] = useState([]);
  const [filterLogData, setfilterLogData] = useState([]);
  const [show, setShow] = useState(false);
  const [cmData, setcmData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setloader] = useState(false);
  const getAllDatas = () => {};
  useEffect(() => {
    if (selectedFilter === "Inventory") {
      setfilterLogData(cmData["inventoryLogs"]);
    } else if (selectedFilter === "Pricing") {
      setfilterLogData(cmData["rateLogs"]);
    } else if (selectedFilter === "Restriction") {
      setfilterLogData(cmData["restrictionLogs"]);
    }
  }, [selectedFilter, cmData]);

  const closeOpenRatePlan = async (openClose) => {
    let params = Object.assign(applyRestrictionsOptions);
    params.hotelId = hotelId;
    params.roomType = rooms[selectedRoom];
    params.ratePlanId = selectedRatePlan;
    params.fromDate = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    params.toDate = moment(currentDateSelection[0].endDate).format(dateFormat);
    params.daysIncluded = selectDays;
    params.stopSell = openClose ? true : false;
    params.channelList = selectedChannel;
    params.ctas = selectedCTA.map((ct) => ct.cta_id);
    // await applyRestrictions(params);
    // getAllDatas();
  };
  const closeOpenRoomType = async (openClose) => {
    let params = Object.assign(applyRestrictionsOptions);
    params.hotelId = hotelId;
    params.roomType = rooms[selectedRoom];
    params.ratePlanId = null;
    params.fromDate = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    params.toDate = moment(currentDateSelection[0].endDate).format(dateFormat);
    params.daysIncluded = selectDays;
    params.stopRTSell = openClose ? true : false;
    params.channelList = selectedChannel;
    params.ctas = selectedCTA.map((ct) => ct.cta_id);
    // await applyRestrictions(params);
    // getAllDatas();
  };

  const closeOnArrival = async (openClose) => {
    let params = Object.assign(applyRestrictionsOptions);
    params.hotelId = hotelId;
    params.roomType = rooms[selectedRoom];
    params.ratePlanId = selectedRatePlan;
    params.fromDate = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    params.toDate = moment(currentDateSelection[0].endDate).format(dateFormat);
    params.daysIncluded = selectDays;
    params.closedOnArrival = openClose ? true : false;
    params.channelList = selectedChannel;
    params.ctas = selectedCTA.map((ct) => ct.cta_id);
    // await applyRestrictions(params);
    // getAllDatas();
  };

  const closeOnDeparture = async (openClose) => {
    let params = Object.assign(applyRestrictionsOptions);
    params.hotelId = hotelId;
    params.roomType = rooms[selectedRoom];
    params.ratePlanId = selectedRatePlan;
    params.fromDate = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    params.toDate = moment(currentDateSelection[0].endDate).format(dateFormat);
    params.daysIncluded = selectDays;
    params.closedOnDeparture = openClose ? true : false;
    params.channelList = selectedChannel;
    params.ctas = selectedCTA.map((ct) => ct.cta_id);
    // await applyRestrictions(params);
    // getAllDatas();
  };

  const setMinLengthOfStay = async () => {
    let params = Object.assign(applyRestrictionsOptions);
    params.hotelId = hotelId;
    params.roomType = rooms[selectedRoom];
    params.ratePlanId = selectedRatePlan;
    params.fromDate = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    params.toDate = moment(currentDateSelection[0].endDate).format(dateFormat);
    params.daysIncluded = selectDays;
    params.minLos = lengthOfStay;
    params.channelList = selectedChannel;
    params.ctas = selectedCTA.map((ct) => ct.cta_id);
    // await applyRestrictions(params);
    // getAllDatas();
  };

  const setMaxLengthOfStay = async () => {
    let params = Object.assign(applyRestrictionsOptions);
    params.hotelId = hotelId;
    params.roomType = rooms[selectedRoom];
    params.ratePlanId = selectedRatePlan;
    params.fromDate = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    params.toDate = moment(currentDateSelection[0].endDate).format(dateFormat);
    params.daysIncluded = selectDays;
    params.maxLos = lengthOfStay;
    params.channelList = selectedChannel;
    params.ctas = selectedCTA.map((ct) => ct.cta_id);
    // await applyRestrictions(params);
    // getAllDatas();
  };

  const setCutOffValue = async () => {
    let params = Object.assign(applyRestrictionsOptions);
    params.hotelId = hotelId;
    params.roomType = rooms[selectedRoom];
    params.ratePlanId = selectedRatePlan;
    params.fromDate = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    params.toDate = moment(currentDateSelection[0].endDate).format(dateFormat);
    params.daysIncluded = selectDays;
    params.cutoff = cutOff;
    params.channelList = selectedChannel;
    params.ctas = selectedCTA.map((ct) => ct.cta_id);
    // const resp = await applyRestrictions(params);
    // if (resp === "success") {
    //   toast.success("Successfully applied min advance offset");
    // } else {
    //   toast.error(`${t("Something went wrong")}`);
    // }
  };

  const [bulkEditPayload, setbulkEditPayload] = useState([
    {
      hotelId: hotelId,
      roomType: "",
      ratePlanId: "",
      fromDate: moment(currentDateSelection[0].startDate).format(dateFormat),
      toDate: moment(currentDateSelection[0].endDate).format(dateFormat),
      daysIncluded: selectDays,
      stopSell: null,
      stopRTSell: null,
      closedOnArrival: null,
      closedOnDeparture: null,
      minLos: null,
      maxLos: null,
      channelList: selectedChannel,
      ctas: selectedCTA.map((ct) => ct.cta_id),
      cutoff: null,
      isCta: null,
      maxAdvancedOffset: null,
    },
  ]);

  const addMaxOffset = () => {
    let payload = {
      hotelId: hotelId,
      roomType: "",
      ratePlanId: "",
      fromDate: moment(currentDateSelection[0].startDate).format(dateFormat),
      toDate: moment(currentDateSelection[0].endDate).format(dateFormat),
      daysIncluded: selectDays,
      stopSell: null,
      stopRTSell: null,
      closedOnArrival: null,
      closedOnDeparture: null,
      minLos: null,
      maxLos: null,
      channelList: selectedChannel,
      ctas: selectedCTA.map((ct) => ct.cta_id),
      cutoff: null,
      isCta: null,
      maxAdvancedOffset: null,
    };
    let data = [...bulkEditPayload];
    data.push(payload);
    setbulkEditPayload(data);
  };

  // Function to update the raw material
  function updateMaxOffset(idx, e) {
    const { name, value } = e.target;
    let data = bulkEditPayload[idx];

    data[name] = value;
    data[`channelList`] = selectedChannel;
    data[`ctas`] = selectedCTA.map((ct) => ct.cta_id);
    data[`daysIncluded`] = selectDays;
    data[`fromDate`] = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    data[`toDate`] = moment(currentDateSelection[0].endDate).format(dateFormat);

    const updatedBulkEdit = bulkEditPayload.map((bulkEdit, index) => {
      if (index === idx) {
        return data;
      } else {
        return bulkEdit;
      }
    });
    setbulkEditPayload(updatedBulkEdit);
  }

  function removeMaxOffset(idx) {
    let data = [...bulkEditPayload];
    data.splice(idx, 1);
    setbulkEditPayload(data);
  }

  const setMaxAdvOffset = async () => {
    let params = Object.assign(applyRestrictionsOptions);
    params.hotelId = hotelId;
    params.roomType = rooms[selectedRoom];
    params.ratePlanId = selectedRatePlan;
    params.fromDate = moment(currentDateSelection[0].startDate).format(
      dateFormat
    );
    params.toDate = moment(currentDateSelection[0].endDate).format(dateFormat);
    params.daysIncluded = selectDays;
    params.maxAdvancedOffset = maxAdvOffset;
    params.channelList = selectedChannel;
    params.ctas = selectedCTA.map((ct) => ct.cta_id);
    // const resp = await applyRestrictions(params);
    // if (resp === "success") {
    //   toast.success(`${t("Successfully applied max advance offset")}`);
    //   // getAllDatas();
    // } else {
    //   toast.error(`${t("Something went wrong")}`);
    // }
  };

  // useEffect(() => {
  //   setcopyBulkEditPayload(bulkEditPayload);
  // }, [bulkEditPayload]);

  const closeButtonFunctions = async (open) => {
    let tempPayload = [];
    if (bulkAllRooms && componentType === "Close Room") {
      const data = Object.values(roomAndPlans)?.map((item) => {
        let tempObj = cloneDeep(bulkEditPayload[0]);
        tempObj["ratePlanId"] = null;
        tempObj["roomType"] = item?.roomTypeName;
        tempObj["cutoff"] = minAdvOffSetValue;
        tempObj["fromDate"] = moment(currentDateSelection[0].startDate).format(
          dateFormat
        );
        tempObj["toDate"] = moment(currentDateSelection[0].endDate).format(
          dateFormat
        );
        tempObj[`channelList`] = selectedChannel;
        tempObj[`ctas`] = selectedCTA.map((ct) => ct.cta_id);
        tempObj[`daysIncluded`] = selectDays;
        tempObj["maxAdvancedOffset"] = maxAdvOffSetValue;
        tempObj["minLos"] = minLosValue;
        tempObj["maxLos"] = maxLosValue;
        tempObj["closedOnArrival"] = closeOnArrivalValue;
        tempObj["closedOnDeparture"] = closedOnDepaturevalue;
        tempObj["stopSell"] = closeOpenRatePlanValue;
        tempObj["stopRTSell"] = closeRoomTypevlaue;
        tempPayload.push(tempObj);
      });
    } else {
      const data = Object.values(roomAndPlans)?.map((item) => {
        let roomTypeId = item?.roomTypeName;
        if (item?.pricingInfoMap) {
          Object.keys(item.pricingInfoMap).map((ratePlanId) => {
            let tempObj = cloneDeep(bulkEditPayload[0]);
            tempObj["ratePlanId"] = ratePlanId;
            tempObj["roomType"] = roomTypeId;
            tempObj["cutoff"] = minAdvOffSetValue;
            tempObj["fromDate"] = moment(
              currentDateSelection[0].startDate
            ).format(dateFormat);
            tempObj["toDate"] = moment(currentDateSelection[0].endDate).format(
              dateFormat
            );
            tempObj[`channelList`] = selectedChannel;
            tempObj[`ctas`] = selectedCTA.map((ct) => ct.cta_id);
            tempObj[`daysIncluded`] = selectDays;
            tempObj["maxAdvancedOffset"] = maxAdvOffSetValue;
            tempObj["minLos"] = minLosValue;
            tempObj["maxLos"] = maxLosValue;
            tempObj["closedOnArrival"] = closeOnArrivalValue;
            tempObj["closedOnDeparture"] = closedOnDepaturevalue;
            tempObj["stopSell"] = closeOpenRatePlanValue;
            tempPayload.push(tempObj);
          });
        }
      });
    }

    let tempData = cloneDeep(bulkEditPayload);
    const guest = tempData?.map((data) => {
      let temp = data;
      temp["roomType"] = rooms[data?.roomType];
      return temp;
    });

    // setApiLoading(true);
    setLineVisible();
    try {
      let status;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/pricing/bulk-apply-restriction-noauth?hotelId=${hotelId}&hotel_id=${hotelId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bulkAllRooms === false ? guest : tempPayload), // Convert the payload to JSON
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Get error message if the response is not ok
          throw new Error(errorText || "An error occurred while updating.");
        }

        const res = await response.text(); // Parse the response JSON
        toast.success("Successfully updated");
        debugger;
        setbulkEditPayload([
          {
            hotelId: hotelId,
            roomType: "",
            ratePlanId: "",
            fromDate: moment(currentDateSelection[0].startDate).format(
              dateFormat
            ),
            toDate: moment(currentDateSelection[0].endDate).format(dateFormat),
            daysIncluded: selectDays,
            stopSell: null,
            stopRTSell: null,
            closedOnArrival: null,
            closedOnDeparture: null,
            minLos: null,
            maxLos: null,
            channelList: selectedChannel,
            ctas: selectedCTA.map((ct) => ct.cta_id),
            cutoff: null,
            isCta: null,
            maxAdvancedOffset: null,
          },
        ]);
      } catch (err) {
        console.log(err);
      } finally {
        setApiLoading(false); // Ensure loading state is updated
      }
      switch (componentType) {
        case "Close Rate":
          status = open ? "CLOSE RATE PLAN" : "OPEN RATE PLAN";
          await closeOpenRatePlan(open).then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     `USER PERFORMED ${status} OPERATION IN BULK OPERATIONS`,
            //     amplitudeEventProperties
            //   );
          });
          break;

        case "Close Room":
          status = open ? "CLOSE ROOM TYPE" : "OPEN ROOM TYPE";
          await closeOpenRoomType(open).then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     `USER PERFORMED ${status} OPERATION IN BULK OPERATIONS`,
            //     amplitudeEventProperties
            //   );
          });
          break;

        case "Close On Arrival":
          status = open ? "CLOSE ON ARRIVAL" : "OPEN ON ARRIVAL";
          await closeOnArrival(open).then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     `USER PERFORMED ${status} OPERATION IN BULK OPERATIONS`,
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "Close On Departure":
          status = open ? "CLOSE ON DEPARTURE" : "OPEN ON DEPARTURE";
          await closeOnDeparture(open).then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     `USER PERFORMED ${status} OPERATION IN BULK OPERATIONS`,
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "Set Min Los":
          await setMinLengthOfStay().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED SET MIN LENGTH OF STAY OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "Set Max Los":
          await setMaxLengthOfStay().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED SET MAX LENGTH OF STAY OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "Set Cutoff":
          await setCutOffValue().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED SET Cutoff OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;

        case "Set Max Adv Offset":
          await setMaxAdvOffset().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED Set Max Adv Offset OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCurrentDateSelection([
        {
          startDate: new Date(new Date().setHours(0, 0, 0, 0)),
          endDate: new Date(
            new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
              0,
              0,
              0,
              0
            )
          ),
        },
      ]);
      setLineInvisible();
      setApiLoading(false);
      //   refresh();
    }
  };

  useEffect(() => {
    setInitailData();
  }, [selectedRoom]);

  const setInitailData = async () => {
    setLineVisible();
    setLoading(true);
    try {
      if (componentType !== "Close Room") {
        // await setRoomData();
        await setCloseRoomData();
      } else {
        await setCloseRoomData();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLineInvisible();
      setLoading(false);
    }
  };

  const setCloseRoomData = async () => {
    const channels = await getConditionalChannelsWithHotelId(
      {
        roomType: selectedRoom,
        restrictionName: componentType,
        roomById: true,
      },
      hotelId
    );
    let updated_channels = [];
    if (
      componentType === "Set Cutoff" ||
      componentType === "Set Max Adv Offset"
    ) {
      if (channels?.length > 0) {
        updated_channels = channels.filter((channel) => {
          if (["booking engine", "bookflexi", "google"].includes(channel)) {
            return true;
          } else {
            return false;
          }
        });
        setChannels(updated_channels);
        setSelectedChannel([updated_channels[0]]);
      }
    } else {
      setChannels(channels);
      setSelectedChannel([channels[0]]);
    }

    const cta = await getCtaNamesNewWithHotelId(hotelId);
    if (isArray(cta)) setCta(cta);
    else setCta([]);
  };

  const setChannelNames = async (ratePlanId) => {
    const channels = await getConditionalChannelsWithHotelId(
      {
        ratePlanId: ratePlanId,
        roomType: selectedRoom,
        restrictionName: componentType,
        roomById: true,
      },
      hotelId
    );
    let updated_channels = [];
    if (
      componentType === "Set Cutoff" ||
      componentType === "Set Max Adv Offset"
    ) {
      if (channels?.length > 0) {
        updated_channels = channels.filter((channel) => {
          if (["booking engine", "bookflexi", "google"].includes(channel)) {
            return true;
          } else {
            return false;
          }
        });
        setChannels(updated_channels);
      }
    } else {
      setChannels(channels);
    }

    setSelectedChannel([]);
  };

  const setCtaNames = async (ratePlanId) => {
    const cta = await getCtaAccountForRatePlanWithHotelId(ratePlanId, hotelId);
    if (isArray(cta)) setCta(cta);
    else setCta([]);
    setSelectedCTA([]);
  };

  const [ratePlanArrayWithOrder, setRatePlanArrayWithOrder] = useState([]);

  useEffect(() => {
    if (Object.keys(ratePlans).length > 0) {
      const arr = [];
      Object.entries(ratePlans).map(([key, value]) => {
        const obj = { id: key };
        Object.entries(value).map(([k, v]) => {
          if (k === "ratePlanName") {
            obj.ratePlanName = v;
          } else if (k === "orderId") {
            obj.orderId = parseInt(v);
          }
        });
        arr.push(obj);
      });

      arr.sort((first, second) => {
        // console.log("first.orderId : ", first.orderId, " second.orderId : ", second.orderId)
        return first.orderId - second.orderId;
      });

      setRatePlanArrayWithOrder(arr);
    }
  }, [ratePlans]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 0) {
      return;
    }
    setSelectDays(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const classes = useStyles();
  const theme = useTheme();

  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const [isDisabled, setisDisabled] = useState(false);
  useEffect(() => {
    if (bulkAllRooms === false) {
      if (componentType === "Close Room") {
        const data = bulkEditPayload?.filter((item) => {
          if (item?.roomType === "") {
            setisDisabled(true);
            setApiLoading(true);
          } else {
            setisDisabled(false);
            setApiLoading(false);
          }
        });
      } else {
        const data = bulkEditPayload?.filter((item) => {
          if (item?.roomType === "" || item?.ratePlanId === "") {
            setisDisabled(true);
            setApiLoading(true);
          } else {
            setisDisabled(false);
            setApiLoading(false);
          }
        });
      }
    } else {
      setApiLoading(false);
    }
  }, [bulkEditPayload, bulkAllRooms]);

  const getDisabled = (roomType, ratePlan) => {
    let flag = false;
    for (let i = 0; i < bulkEditPayload?.length; i++) {
      if (
        bulkEditPayload[i].ratePlanId === ratePlan &&
        bulkEditPayload[i].roomType === roomType
      ) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  const newweekdays = [
    { id: "Sunday", value: `${t("Sunday")}` },
    { id: "Monday", value: `${t("Monday")}` },
    { id: "Tuesday", value: `${t("Tuesday")}` },
    { id: "Wednesday", value: `${t("Wednesday")}` },
    { id: "Thursday", value: `${t("Thursday")}` },
    { id: "Friday", value: `${t("Friday")}` },
    { id: "Saturday", value: `${t("Saturday")}` },
  ];
  const getValue = (val) => {
    const res = newweekdays.filter((item) => item.id === val)[0];
    return res.value;
  };

  const handleSelectionChange = (event, value, reason) => {
    if (
      reason === "selectOption" &&
      value.find((v) => v.cta_name === "Select All")
    ) {
      setSelectedCTA(ctaAccounts);
    } else if (
      reason === "removeOption" &&
      value.find((v) => v.cta_name === "Select All")
    ) {
      setSelectedCTA([]);
    } else if (
      reason === "selectOption" &&
      value.find((v) => v.cta_name === "Deselect All")
    ) {
      setSelectedCTA([]);
    } else {
      setSelectedCTA(
        value.filter(
          (v) => v.cta_name !== "Select All" && v.cta_name !== "Deselect All"
        )
      );
    }
  };

  const extendedOptions = [
    { cta_name: "Select All" },
    { cta_name: "Deselect All" },
    ...ctaAccounts,
  ];

  // Add "Select All" and "Deselect All" options to the list
  const extendedOptionsChannels = ["Select All", "Deselect All", ...channels];

  const handleChangeChannels = (event, value) => {
    if (value.includes("Select All")) {
      // If "Select All" is selected, select all channels and remove "Select All" from the selection
      setSelectedChannel(channels);
    } else if (value.includes("Deselect All")) {
      // If "Deselect All" is selected, keep at least one channel selected
      setSelectedChannel([channels[0]]);
    } else if (value.length === 0) {
      // Ensure at least one option remains selected
      setSelectedChannel([channels[0]]);
    } else {
      // Normal selection
      setSelectedChannel(value);
    }
  };

  return (
    <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
      <Grid container>
        {!loading && (
          <Grid container className={classes.textField} spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="days-multiple-chip-label">
                  {t("Days")}
                </InputLabel>
                <Select
                  labelId="days-multiple-chip-label"
                  id="days-multiple-chip"
                  multiple
                  value={selectDays}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="day-multiple-chip" label={t("Days")} />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          key={value}
                          label={getValue(value)}
                          style={{ fontWeight: "bold", fontSize: "0.9rem" }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {newweekdays.map((name) => (
                    <MenuItem
                      key={name.id}
                      value={name.id}
                      style={getStyles(name, selectDays, theme)}
                    >
                      {name.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div>
                <ClickAwayListener
                  onClickAway={() => {
                    setShowDatePicker(false);
                    setAnchorEl(null);
                  }}
                >
                  <div>
                    <TextField
                      fullWidth
                      focused={showDatePicker}
                      required
                      InputProps={{ readOnly: true }}
                      value={getDateText()}
                      label={t("Date range")}
                      onClick={(eve) => {
                        setAnchorEl(eve.currentTarget);
                        setShowDatePicker(true);
                      }}
                    ></TextField>
                    {showDatePicker && (
                      <Popper
                        style={{ zIndex: 2 }}
                        open={showDatePicker}
                        anchorEl={anchorEl}
                        placement="bottom"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            style={{
                              transformOrigin: (placement = "bottom-end"),
                            }}
                            {...TransitionProps}
                          >
                            <Paper
                              elevation={5}
                              className="inventory-daterange-custom"
                            >
                              <DateRange
                                color="#1BC5BD"
                                rangeColors={["#1BC5BD"]}
                                minDate={new Date()}
                                maxDate={
                                  new Date(
                                    new Date().setDate(
                                      new Date().getDate() + 730
                                    )
                                  )
                                }
                                editableDateInputs={false}
                                moveRangeOnFirstSelection={false}
                                onChange={(data) => {
                                  setCurrentDateSelection([{ ...data.range1 }]);
                                }}
                                ranges={currentDateSelection}
                              ></DateRange>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
            </Grid>
            <Grid item spacing={2} container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Autocomplete
                  fullWidth
                  disableClearable
                  id="combo-box"
                  multiple
                  ChipProps={{
                    variant: "outlined",
                    color: "primary",
                    fontWeight: "bold",
                  }}
                  options={extendedOptionsChannels}
                  renderInput={(params) => (
                    <TextField {...params} label="Source" />
                  )}
                  onChange={handleChangeChannels}
                  value={selectedChannel}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Autocomplete
                  fullWidth
                  id="combo"
                  multiple
                  options={extendedOptions}
                  getOptionLabel={(option) => option.cta_name}
                  isOptionEqualToValue={(option, value) =>
                    option.cta_name === value.cta_name
                  }
                  onChange={handleSelectionChange}
                  value={selectedCTA}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Direct Billing Accounts"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props}>
                      <Checkbox
                        checked={
                          option.cta_name === "Select All"
                            ? selectedCTA.length === ctaAccounts.length
                            : option.cta_name === "Deselect All"
                            ? selectedCTA.length === 0
                            : selected
                        }
                        indeterminate={
                          option.cta_name === "Select All" &&
                          selectedCTA.length > 0 &&
                          selectedCTA.length < ctaAccounts.length
                        }
                      />
                      <ListItemText primary={option.cta_name} />
                    </MenuItem>
                  )}
                />
              </Grid>
            </Grid>
            <Divider />
            <div className="d-flex align-items-center">
              <p
                className="mb-0 mr-3"
                style={{ marginLeft: "1.2rem", fontWeight: "bold" }}
              >
                {" "}
                {t(
                  "Enable this toggle to apply restrictions for all room type and rate plans"
                )}
              </p>
              <Switch
                className="mb-0"
                checked={bulkAllRooms}
                onChange={(event) => setbulkAllRooms(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            {bulkAllRooms === false &&
              bulkEditPayload?.map((item, index) => (
                <Grid item spacing={2} container>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple">
                        {t("Room Types")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple"
                        id="multiple-rateplans"
                        value={item?.roomType}
                        label={t("Room Types")}
                        MenuProps={MenuProps}
                        name="roomType"
                        onChange={(e) => updateMaxOffset(index, e)}
                        // onChange={handleRateplanChange}
                      >
                        {Object.keys(roomAndPlans).map((room) => (
                          <MenuItem key={room} value={room}>
                            {roomAndPlans[room]?.roomTypeName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {item?.roomType !== null &&
                    item?.roomType !== undefined &&
                    item?.roomType !== "" &&
                    componentType !== "Close Room" && (
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple">
                            {t("Rate Plans")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple"
                            id="multiple-rateplans"
                            value={item?.ratePlanId}
                            label={t("Rate Plans")}
                            name="ratePlanId"
                            MenuProps={MenuProps}
                            onChange={(e) => updateMaxOffset(index, e)}
                          >
                            {Object.entries(
                              roomAndPlans[item?.roomType]?.pricingInfoMap
                            )
                              .sort(
                                (a, b) =>
                                  parseInt(a[1].orderId) -
                                  parseInt(b[1].orderId)
                              )
                              ?.map(([key, value]) => (
                                <MenuItem
                                  key={key}
                                  value={key}
                                  disabled={getDisabled(item?.roomType, key)}
                                >
                                  {value["ratePlanName"]}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                  {componentType === "Set Max Adv Offset" && (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <TextField
                        fullWidth
                        type="number"
                        value={item?.maxAdvancedOffset}
                        name="maxAdvancedOffset"
                        onChange={(e) => updateMaxOffset(index, e)}
                        label={t("Max hours")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {" "}
                              {t("hrs")}
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                    </Grid>
                  )}
                  {componentType === "Set Cutoff" && (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <TextField
                        fullWidth
                        type="number"
                        name="cutoff"
                        onChange={(e) => updateMaxOffset(index, e)}
                        label={t("Min hours")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("hrs")}
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                    </Grid>
                  )}
                  {componentType === "Set Max Los" && (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <TextField
                        fullWidth
                        type="number"
                        name="maxLos"
                        onChange={(e) => updateMaxOffset(index, e)}
                        label={
                          componentType === "Set Min Los"
                            ? `${t("Minimum Length of Stay")}`
                            : `${t("Maximum Length of Stay")}`
                        }
                      ></TextField>
                    </Grid>
                  )}
                  {componentType === "Set Min Los" && (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <TextField
                        fullWidth
                        type="number"
                        name="minLos"
                        onChange={(e) => updateMaxOffset(index, e)}
                        label={
                          componentType === "Set Min Los"
                            ? `${t("Minimum Length of Stay")}`
                            : `${t("Maximum Length of Stay")}`
                        }
                      ></TextField>{" "}
                    </Grid>
                  )}

                  {componentType === "Close On Departure" && (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("Type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="closedOnDeparture"
                          onChange={(e) => updateMaxOffset(index, e)}
                          label={t("Type")}
                          value={item?.closedOnDeparture}
                        >
                          <MenuItem value={true}>
                            {t("Close on Departure")}
                          </MenuItem>
                          <MenuItem value={false}>
                            {t("Open on Departure")}
                          </MenuItem>
                        </Select>
                      </FormControl>{" "}
                    </Grid>
                  )}

                  {componentType === "Close On Arrival" && (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("Type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="closedOnArrival"
                          onChange={(e) => updateMaxOffset(index, e)}
                          label={t("Type")}
                          value={item?.closedOnArrival}
                        >
                          <MenuItem value={true}>
                            {t("Close on Arrival")}
                          </MenuItem>
                          <MenuItem value={false}>
                            {t("Open on Arrival")}
                          </MenuItem>
                        </Select>
                      </FormControl>{" "}
                    </Grid>
                  )}

                  {componentType === "Close Room" && (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("Type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="stopRTSell"
                          onChange={(e) => updateMaxOffset(index, e)}
                          label={t("Type")}
                          value={item?.stopRTSell}
                        >
                          <MenuItem value={true}>{t("Close Room")}</MenuItem>
                          <MenuItem value={false}>{t("Open Room")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {componentType === "Close Rate" && (
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("Type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="stopSell"
                          onChange={(e) => updateMaxOffset(index, e)}
                          label={t("Type")}
                          value={item?.stopSell}
                        >
                          <MenuItem value={true}>
                            {t("Close Rate Plan")}
                          </MenuItem>
                          <MenuItem value={false}>
                            {t("Open Rate Plan")}
                          </MenuItem>
                        </Select>
                      </FormControl>{" "}
                    </Grid>
                  )}

                  {index !== 0 && (
                    <Typography
                      onClick={() => removeMaxOffset(index)}
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <AiIcons.AiOutlineClose size="20px" />
                    </Typography>
                  )}
                </Grid>
              ))}
            {bulkAllRooms === false &&
              (componentType === "Set Max Adv Offset" ||
                componentType === "Set Cutoff" ||
                componentType === "Set Min Los" ||
                componentType === "Set Max Los" ||
                componentType === "Close On Departure" ||
                componentType === "Close On Arrival" ||
                componentType === "Close Rate" ||
                componentType === "Close Room") && (
                <Button
                  style={{
                    width: "fit-content",
                    textTransform: "none",
                    marginLeft: "20px",
                  }}
                  disabled={isDisabled}
                  onClick={addMaxOffset}
                  variant="custom-button"
                >
                  {t("Add")}
                </Button>
              )}

            {componentType === "Close Room" && bulkAllRooms && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {" "}
                    {t("Type")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="stopRTSell"
                    onChange={(e) => setcloseRoomTypevlaue(e.target.value)}
                    label={t("Type")}
                    value={closeRoomTypevlaue}
                  >
                    <MenuItem value={true}>{t("Close Room")}</MenuItem>
                    <MenuItem value={false}>{t("Open Room")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {componentType === "Close Rate" && bulkAllRooms && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {" "}
                    {t("Type")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="stopSell"
                    onChange={(e) => setcloseOpenRatePlanValue(e.target.value)}
                    label={t("Type")}
                    value={closeOpenRatePlanValue}
                  >
                    <MenuItem value={true}>{t("Close Rate Plan")}</MenuItem>
                    <MenuItem value={false}>{t("Open Rate Plan")}</MenuItem>
                  </Select>
                </FormControl>{" "}
              </Grid>
            )}

            {componentType === "Close On Arrival" && bulkAllRooms && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {" "}
                    {t("Type")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="closedOnArrival"
                    onChange={(e) => setcloseOnArrivalValue(e.target.value)}
                    label={t("Type")}
                    value={closeOnArrivalValue}
                  >
                    <MenuItem value={true}>{t("Close on Arrival")}</MenuItem>
                    <MenuItem value={false}>{t("Open on Arrival")}</MenuItem>
                  </Select>
                </FormControl>{" "}
              </Grid>
            )}

            {componentType === "Close On Departure" && bulkAllRooms && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t("Type")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="closedOnDeparture"
                    onChange={(e) => setclosedOnDepaturevalue(e.target.value)}
                    label={t("Type")}
                    value={closedOnDepaturevalue}
                  >
                    <MenuItem value={true}>{t("Close on Departure")}</MenuItem>
                    <MenuItem value={false}>{t("Open on Departure")}</MenuItem>
                  </Select>
                </FormControl>{" "}
              </Grid>
            )}

            {componentType === "Set Max Los" && bulkAllRooms && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="maxLos"
                  value={maxLosValue}
                  onChange={(e) => setmaxLosValue(e.target.value)}
                  label={t("Maximum Length of Stay")}
                ></TextField>
              </Grid>
            )}
            {componentType === "Set Min Los" && bulkAllRooms && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="maxLos"
                  value={minLosValue}
                  onChange={(e) => setminLosValue(e.target.value)}
                  label={t("Minimum Length of Stay")}
                ></TextField>
              </Grid>
            )}
            {componentType === "Set Cutoff" && bulkAllRooms && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  type="number"
                  name="cutoff"
                  value={minAdvOffSetValue}
                  onChange={(e) => setminAdvOffSetValue(e.target.value)}
                  label={t("Min hours")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{t("hrs")}</InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
            )}
            {componentType === "Set Max Adv Offset" && bulkAllRooms && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  type="number"
                  value={maxAdvOffSetValue}
                  name="maxAdvancedOffset"
                  onChange={(e) => setmaxAdvOffSetValue(e.target.value)}
                  label={t("Max hours")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{t("hrs")}</InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
            )}
          </Grid>
        )}
        {!loading && (
          <Grid container>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              className={classes.textField}
              spacing={2}
              justifyContent="flex-end"
              container
            >
              <Grid item>
                <Fab
                  onClick={() => {
                    closeButtonFunctions(true);
                  }}
                  disabled={apiLoading}
                  variant="extended"
                  color="primary"
                >
                  {componentType === "Close Room" && `${t("Save")}`}
                  {componentType === "Close Rate" && `${t("Save")}`}
                  {componentType === "Close On Arrival" && `${t("Save")}`}
                  {componentType === "Close On Departure" && `${t("Save")}`}
                  {componentType === "Set Min Los" &&
                    `${t("Set Min Length of Stay")}`}
                  {componentType === "Set Max Los" &&
                    `${t("Set Max Length of Stay")}`}
                  {componentType === "Set Cutoff" && `${t("Set Cutoff")}`}
                  {componentType === "Set Max Adv Offset" &&
                    `${t("Set Max Adv Offset")}`}
                </Fab>
              </Grid>
              {/* {componentType !== "Set Min Los" &&
                componentType !== "Set Max Los" &&
                componentType !== "Set Cutoff" &&
                componentType !== "Set Max Adv Offset" && (
                  <Grid item>
                    <Fab
                      onClick={() => {
                        closeButtonFunctions(false);
                      }}
                      disabled={apiLoading}
                      variant="extended"
                      color="primary"
                    >
                      {componentType === "Close Room" && "Open Room Type"}
                      {componentType === "Close Rate" && "Open Rate Plan"}
                      {componentType === "Close On Arrival" &&
                        "Open On Arrival"}
                      {componentType === "Close On Departure" &&
                        "Open On Departure"}
                    </Fab>
                  </Grid>
                )} */}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grow>
  );
};

export default GroupOpenCloseViews;
