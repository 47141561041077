import React, { useState, useEffect, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Link as DomLink,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from "@mui/icons-material/Info";
import { connect } from "react-redux";
import * as auth from "../../modules/Auth/_redux/authRedux";
import { getHotel } from "../../modules/Auth/_redux/authCrud";
import AccountsDashBoard from "../AccountsAndBilling/AccountsDashBoard";
import GroupDashboard from "../GroupDashboard/GroupDashboard";
import CTAModal from "../BookingDashboard/CTAModal";
import BookingDashboard from "../BookingDashboard/BookingDashboard";
import BookingDataLoader from "../BookingDashboard/BookingDataLoader";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalculateIcon from "@mui/icons-material/Calculate";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import GroupAdmin from "../GroupAdmin/GroupAdmin";
import GroupMasterReport from "../GroupReports/GroupMasterReport";
import GroupSalesReport from "../GroupReports/GroupSalesReport";
import GroupChannelManagerReport from "../GroupReports/GroupChannelManagerReport";
import GroupReportsContainer from "../GroupReports/GroupReportsContainer";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import MuiAppBar from "@mui/material/AppBar";
import {
  Divider,
  Stack,
  Box,
  Breadcrumbs,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Toolbar,
  IconButton,
  Button,
} from "@mui/material";
import { useHtmlClassService } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import KeyIcon from "@mui/icons-material/Key";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { getAllImagesAndUrl } from "../BookingDashboard/apis/BrandingModalApi";
import axios from "axios";
import BulkInventory from "../BulkInventoryFlows/BulkInventory";
import GroupTimeline from "../CalendarPage/GroupTimeline";
import RoleAccess from "../../modules/Auth/pages/GroupAccessControl/RoleAccess";
import GroupUserAccess from "../../modules/Auth/pages/GroupAccessControl/GroupUserAccess";
import { Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupStats from "../GroupDashboard/Stats/GroupStats.js";
import AllGroupUsers from "../../modules/Auth/pages/GroupAccessControl/AllGroupUsers.js";
import { useTranslation } from "react-i18next";
import i18n from "../../modules/Auth/pages/i18n";
import NewGroupTimeline from "../CalendarPage/NewGroupTimeLine.js";
import NewGroup from "../CalendarPage/NewGroup.js";
import GroupBeConfig from "../BookingEngine/GroupBeConfig.js";
import { set } from "lodash";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      backgroundColor: "#ffffff",
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  // top: 64,
  backgroundColor: "white",
  boxShadow: "none",
  zIndex: 1,
  height: "50px",
}));

function GroupSidebar({
  data,
  setAllHotels,
  setAccountingVersion,
  setAccountBalance,
  contentArea = null,
  hideDrawer = false,
  logoPath = null,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const history = useHistory();
  const location = useLocation();
  const { email, token } = data;
  const [env, setEnv] = useState(process.env.REACT_APP_ENV);
  const [state, setState] = useState({
    left: false,
  });
  const [opens, setOpens] = useState(false);
  const handleClick = () => {
    setOpens(!opens);
  };

  // get the user logo
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getLogo(),
    };
  }, [uiService]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [groupState, setgroupState] = useState("Hotels dashboard");

  const [hotelIds, sethotelIds] = useState(null);
  const [groupId, setgroupId] = useState(null);
  const [firstHotelId, setFirstHotelId] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [allHotelIds, setAllHotelIds] = useState([]);
  const [isGroup, setisGroup] = useState(false);
  const [groupEmail, setgroupEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [subUserAccess, setsubUserAccess] = useState("");
  const [showGroupAccess, setshowGroupAccess] = useState(false);

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    setgroupEmail(localStorage.getItem("groupEmail"));
  }, [localStorage.getItem("groupEmail")]);

  const [loader, setloader] = useState(false);
  const getAllData = async () => {
    try {
      setloader(true);
      const data = await getHotel(email, true, token);
      setBookingData(data.data);
      localStorage.setItem(
        "groupEmail",
        data?.data?.filter((item) => item.status === "ACTIVE")[0].emailId
      );
      const tempHotelIds = data.data.map((res) => {
        return parseInt(res.hotelId);
      });
      let alltempHotels = data.data.map((res) => {
        if (res?.status === "ACTIVE" || res?.status === "TEST") {
          return { hotelId: res?.hotelId, hotelName: res?.hotelName };
        }
      });
      setAllHotelIds(alltempHotels);
      setloader(false);
      tempHotelIds.sort();
      sethotelIds(tempHotelIds);
      setFirstHotelId(tempHotelIds[0]);
      setAllHotels(data.data);
      setloader(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (
      userRole !== "" &&
      groupEmail !== "" &&
      groupEmail !== undefined &&
      groupEmail !== null &&
      groupEmail !== "null"
    ) {
      handleSpringDataRequest(
        `core/api/v1/groupAccesscontrol/addUserWizardRender?email=${localStorage.getItem(
          "HotelEmail"
        )}&hotelEmail=${groupEmail}`
      )
        .then((res) => {
          setsubUserAccess(res);
        })
        .catch((err) => console.error(err));
    }
  }, [groupEmail, userRole]);

  const [isAdmin, setisAdmin] = useState(false);
  useEffect(() => {
    if (bookingData?.length > 0) {
      handleSpringDataRequest(
        `core/api/v1/accesscontrol/addUserWizardRender?email=${localStorage.getItem(
          "HotelEmail"
        )}&hotelId=${bookingData[0].hotelId}&hotel_id=${bookingData[0].hotelId}`
      )
        .then((res) => {
          setisAdmin(
            res.ADMINRIGHTS === "ADMIN" || res.ADMINRIGHTS === "SUBADMIN"
          );
          localStorage.setItem(
            "isAdmin",
            res.ADMINRIGHTS === "ADMIN" || res.ADMINRIGHTS === "SUBADMIN"
          );
        })
        .catch((err) => console.error(err));
    }
  }, [bookingData]);

  const [allGroupId, setallGroupId] = useState("");
  useEffect(() => {
    if (
      groupEmail !== "" &&
      groupEmail !== undefined &&
      groupEmail !== null &&
      groupEmail !== "null"
    ) {
      handleSpringDataRequest(
        `core/api/v1/groupAccesscontrol/getGroupId?email=${groupEmail}`
      )
        .then((res) => {
          setallGroupId(res);
          localStorage.setItem("mainGroupId", res);
        })
        .catch((err) => console.error(err));
    }
  }, [groupEmail]);

  const getUserRole = () => {
    handleSpringDataRequest(
      `core/api/v1/groupaccess/getUserRole?userEmail=${email}`
    )
      .then((res) => {
        setUserRole(res);
      })
      .catch((err) => {
        console.log("the err is : ", err);
      });
  };

  useEffect(() => {
    getUserRole();
  }, []);

  const [accountInfo, setAccountInfo] = useState(null);
  const getCompanyData = (actId) => {
    handleSpringDataRequest(
      `core/api/v1/account/get-single/?groupId=${actId}&hotelId=${actId}`
    )
      .then((res) => {
        let vals = Object.keys(res);
        if (vals?.length > 0) {
          setAccountInfo(res);
          setAccountingVersion(res?.account_details?.accounting_version);
        }
      })
      .catch((err) => {
        console.log("the err is : ", err);
      });
  };

  useEffect(() => {
    // groupId && getCompanyData();
  }, [groupId]);

  const getActualGroupId = () => {
    if (groupId === null || groupId === undefined) {
      handleSpringDataRequest(
        `core/api/v1/account/get-accountId/?hotelId=${firstHotelId}&hId=${firstHotelId}`
      )
        .then((res) => {
          setgroupId(res);
          localStorage.setItem("actGroupId", res);
          localStorage.setItem("newGroupid", res);
          if (res !== "") {
            //Add groupId similar to how we added accounting version
            getCompanyData(res);
            handleSpringDataRequest(
              `core/api/v1/account-ledger/get-account-balance/?groupId=${res}&hotelId=${res}`
            )
              .then((resp) => {
                // setAccBalance(res?.message);
                setAccountBalance(resp.message);
              })
              .catch((err) => {
                console.log("the err is : ", err);
                // toast.error(err);
              });
          }
        })
        .catch((err) => {
          console.log("the err is : ", err);
        });
    }
  };

  useEffect(() => {
    firstHotelId && getActualGroupId();
  }, [firstHotelId]);

  // Opening the drawer
  const [open, setOpen] = useState(true);
  const handleCloseDrawer = () => setOpen(false);
  useEffect(() => {
    if (hideDrawer) {
      handleCloseDrawer();
    }
  }, [hideDrawer]);

  // Current selected section
  const [currentSelected, setCurrentSelected] = useState("hotelDashboard");
  useEffect(() => {
    if (location.state && location.state.hasOwnProperty("selectedSection")) {
      setCurrentSelected(location.state.selectedSection);
    } else {
      setCurrentSelected("hotelDashboard");
    }
  }, [location]);

  const [groupBrandingData, setgroupBrandingData] = useState({});
  function getImgData() {
    axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}user/groupBranding?hostUrl=${window.location.hostname}`
      )
      .then((resp) => setgroupBrandingData(resp.data))
      .catch((err) => {
        throw new Error(err);
      });
  }

  useEffect(() => {
    getImgData();
  }, []);

  useEffect(() => {
    if (
      groupEmail !== null &&
      groupEmail !== undefined &&
      groupEmail !== "" &&
      groupEmail !== "null"
    ) {
      handleSpringDataRequest(
        `core/api/v1/reports/check-if-group-reports-enabled?emailId=${groupEmail}`
      )
        .then((res) => setisGroup(res))
        .catch((Err) => console.error(Err));
    }
  }, [groupEmail]);

  // Sections on the group dashboard
  const sections = [
    {
      name: `${t("Group dashboard")}`,
      indexName: "hotelDashboard",
      url: "/hotel-dashboard",
      subSection: [],
      icon: <DashboardIcon />,
      env: ["PROD", "BETA", "DJANGO_LOCAL", "LOCAL"],
      active: true,
      componentRenderer: (
        <BookingDashboard
          bookingData={bookingData}
          freezed={accountInfo?.account_details?.freezed}
          groupBrandingData={groupBrandingData}
          getAllData={getAllData}
          selectedLanguage={selectedLanguage}
        />
      ),
    },
    {
      name: `${t("Group reservation calendar")}`,
      url: "/group-timeline",
      indexName: "groupTimeline",
      subSection: [],
      icon: <TravelExploreIcon />,
      env: ["BETA", "PROD", "DJANGO_LOCAL", "LOCAL"],
      active:
        bookingData?.filter((item) => item.status === "ACTIVE")?.length > 1,
      // subUserAccess !== "" &&
      // Object.entries(subUserAccess?.user_feature_map).length > 0 &&
      // subUserAccess?.user_feature_map?.GroupReservationCalendar !==
      //   undefined &&
      // JSON.parse(subUserAccess?.user_feature_map?.GroupReservationCalendar)
      //   .read === "YES",
      componentRenderer: <NewGroup bookingData={bookingData} />,
    },
    {
      name: `${t("Group Inventory calendar")}`,
      url: "/bulkInventory",
      indexName: "GroupInventoryCalendar",
      subSection: [],
      icon: <KeyIcon />,
      env: ["PROD", "BETA", "DJANGO_LOCAL", "LOCAL"],
      active: false,
      // subUserAccess !== "" &&
      // Object.entries(subUserAccess?.user_feature_map).length > 0 &&
      // subUserAccess?.user_feature_map?.GroupInventoryCalendar !== undefined &&
      // JSON.parse(subUserAccess?.user_feature_map?.GroupInventoryCalendar)
      //   .read === "YES" &&
      // isGroup === "OK",
      componentRenderer: (
        <BulkInventory
          allHotelsData={bookingData}
          selectedLanguage={selectedLanguage}
        />
      ),
    },
    {
      name: `${t("Group reports")}`,
      url: "/group-reports",
      indexName: "groupReports",
      subSection: [],
      icon: <AssessmentIcon />,
      env: ["PROD", "BETA", "DJANGO_LOCAL", "LOCAL"],
      active: [
        "stays@truliv.in",
        "support@mmrhotels.com",
        "arsh@tenpy.co",
        "info@bungalowsinlonavala.com",
        "eldenessaprod@example.com",
        "ankitsinhastayflexitest@gmail.com",
      ].includes(groupEmail),
      // active: false,
      // subUserAccess !== "" &&
      // Object.entries(subUserAccess?.user_feature_map).length > 0 &&
      // subUserAccess?.user_feature_map?.GroupReports !== undefined &&
      // JSON.parse(subUserAccess?.user_feature_map?.GroupReports).read ===
      //   "YES",
      componentRenderer: (
        <GroupReportsContainer
          email={email}
          selectedLanguage={selectedLanguage}
        />
      ),
    },

    {
      name: `${t("Accounts and billing")}`,
      indexName: "accountsAndBilling",
      url: "/accounts-billing",
      subSection: [],
      icon: <CalculateIcon />,
      env: ["PROD", "BETA", "DJANGO_LOCAL", "LOCAL"],
      active:
        accountInfo &&
        accountInfo?.account_details?.accounting_version === 2 &&
        userRole?.toLowerCase() === "admin",
      componentRenderer: (
        <AccountsDashBoard
          bookingData={bookingData}
          hotelIds={hotelIds}
          groupId={groupId}
        />
      ),
    },

    {
      name: `${t("Validate travel agent")}`,
      url: "/travel-agents",
      indexName: "travelAgents",
      subSection: [],
      icon: <TravelExploreIcon />,
      env: ["BETA", "PROD", "DJANGO_LOCAL", "LOCAL"],
      active:
        email === "reservation@maharashtratourism.gov.in" ||
        email === "onlinesales@teamdelight.co.in",
      componentRenderer: <CTAModal allActiveHotels={allHotelIds} />,
    },
    // {
    //   name: "Group Statistics",
    //   url: "/group-dashboard",
    //   indexName: "groupDashboard",
    //   subSection: [],
    //   icon: <DashboardIcon />,
    //   env: ["BETA", "DJANGO_LOCAL", "PROD", "LOCAL"],
    //   active: false,
    //   // subUserAccess !== "" &&
    //   // Object.entries(subUserAccess?.user_feature_map).length > 0 &&
    //   // subUserAccess?.user_feature_map?.GroupStatistics !== undefined &&
    //   // JSON.parse(subUserAccess?.user_feature_map?.GroupStatistics).read ===
    //   //   "YES",
    //   // componentRenderer: (
    //   //   <GroupDashboard
    //   //     bookingData={bookingData && bookingData}
    //   //     hotelIds={hotelIds && hotelIds}
    //   //   />
    //   // ),
    //   componentRenderer: (
    //     <GroupStats
    //       bookingData={bookingData}
    //       hotelIds={hotelIds}
    //       groupEmail={groupEmail}
    //       fullScreen={!open}
    //       selectedLanguage={selectedLanguage}
    //     />
    //   ),
    // },

    {
      name: "Group Booking Engine",
      url: "/groupBeConfig",
      indexName: "groupBeConfig",
      subSection: [],
      icon: <DashboardIcon />,
      env: ["BETA", "DJANGO_LOCAL", "PROD", "LOCAL"],
      active:
        bookingData?.filter((item) => item.status === "ACTIVE")?.length > 1,
      componentRenderer: <GroupBeConfig bookingData={bookingData} />,
    },

    {
      name: `${t("Access Control")}`,
      url: "/travel-agents",
      indexName: "travelAgents",
      subSection: [
        {
          name: "Create Admin User",
          url: "/group-access-control",
          indexName: "groupAccessControl",
          subSection: [],
          icon: <KeyIcon />,
          env: ["PROD", "BETA", "DJANGO_LOCAL", "LOCAL"],
          active:
            userRole?.toLowerCase() === "admin" ||
            userRole?.toLowerCase() === "subadmin",
          // (subUserAccess !== "" &&
          //   Object.entries(subUserAccess?.user_feature_map).length > 0 &&
          //   subUserAccess?.user_feature_map?.GroupUsersAndAccess !==
          //     undefined &&
          //   JSON.parse(subUserAccess?.user_feature_map?.GroupUsersAndAccess)
          //     .read === "YES"),
          componentRenderer: (
            <GroupAdmin
              bookingData={bookingData && bookingData}
              hotelIds={hotelIds && hotelIds}
              groupId={allGroupId && allGroupId}
              selectedLanguage={selectedLanguage}
            />
          ),
        },
        {
          name: `${t("Copy / Assign Users")}`,
          indexName: "access-control",
          url: "/access-control",
          subSection: [],
          icon: <CalculateIcon />,
          env: ["PROD", "BETA", "DJANGO_LOCAL", "LOCAL"],
          active:
            userRole?.toLowerCase() === "admin" ||
            userRole?.toLowerCase() === "subadmin",
          componentRenderer: (
            <AllGroupUsers
              bookingData={bookingData && bookingData}
              hotelIds={hotelIds && hotelIds}
              allGroupId={allGroupId}
              groupEmail={groupEmail}
              env={env}
              selectedLanguage={selectedLanguage}
            />
          ),
        },
        {
          name: `${t("Roles")}`,
          url: "/group-access-role",
          indexName: "GroupAccessRole",
          subSection: [],
          icon: <DashboardIcon />,
          env: ["BETA", "DJANGO_LOCAL", "PROD", "LOCAL"],
          active: false,
          // userRole?.toLowerCase() === "admin" ||
          // userRole?.toLowerCase() === "subadmin",
          componentRenderer: (
            <RoleAccess
              bookingData={bookingData && bookingData}
              hotelIds={hotelIds && hotelIds}
              allGroupId={allGroupId}
              groupEmail={groupEmail}
              selectedLanguage={selectedLanguage}
            />
          ),
        },
        {
          name: `${t("User")}`,
          url: "/group-access-user",
          indexName: "GroupAccessUser",
          subSection: [],
          icon: <DashboardIcon />,
          env: ["BETA", "DJANGO_LOCAL", "PROD", "LOCAL"],
          active: false,
          // userRole?.toLowerCase() === "admin" ||
          // userRole?.toLowerCase() === "subadmin",
          componentRenderer: (
            <GroupUserAccess
              bookingData={bookingData && bookingData}
              hotelIds={hotelIds && hotelIds}
              allGroupId={allGroupId}
              groupEmail={groupEmail}
              selectedLanguage={selectedLanguage}
            />
          ),
        },
      ],
    },
    // {
    //   name: `${t("Access control")}`,
    //   indexName: "access-control",
    //   url: "/access-control",
    //   subSection: [],
    //   icon: <CalculateIcon />,
    //   env: ["PROD", "BETA", "DJANGO_LOCAL", "LOCAL"],
    //   active: false,
    //   // userRole?.toLowerCase() === "admin" ||
    //   // userRole?.toLowerCase() === "subadmin",
    //   componentRenderer: (
    //     <AllGroupUsers
    //       bookingData={bookingData && bookingData}
    //       hotelIds={hotelIds && hotelIds}
    //       allGroupId={allGroupId}
    //       groupEmail={groupEmail}
    //       env={env}
    //       selectedLanguage={selectedLanguage}
    //     />
    //   ),
    // },
  ];

  useEffect(() => {
    const data = sections[7].subSection.filter(
      (section, idx) => section.active === true
    );

    setshowGroupAccess(data.length > 0);
  }, [userRole, subUserAccess]);

  // Handle selected function
  function handleSelected(index) {
    if (index === currentSelected) {
      return true;
    } else {
      return false;
    }
  }

  const list = (
    <Stack
      className="ml-4"
      style={{
        position: "relative",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <List>
        {sections?.map(
          (section, idx) =>
            section.active &&
            section.env.includes(env.toUpperCase()) &&
            section.subSection.length === 0 && (
              <ListItem
                key={idx}
                disablePadding
                selected={handleSelected(section.indexName)}
                onClick={() => {
                  if (accountInfo?.account_details?.freezed !== "YES") {
                    setCurrentSelected(section.indexName);
                    history.push({
                      pathname: "/hotel-dashboard",
                      state: { selectedSection: section.indexName },
                    });
                  }
                }}
                // as={Link}
                // to={{
                //   pathname: section.url,
                //   state: {
                //     bookingData: bookingData && bookingData,
                //     hotelIds: hotelIds && hotelIds,
                //     groupId: groupId && groupId,
                //     allActiveHotels: allHotelIds && allHotelIds,
                //   },
                // }}
                style={{ color: "#757575", fontWeight: "500" }}
              >
                <ListItemButton>
                  <ListItemIcon>{section.icon}</ListItemIcon>
                  <ListItemText>{section.name}</ListItemText>
                </ListItemButton>
              </ListItem>
            )
        )}{" "}
        {showGroupAccess && (
          <ListItem
            style={{ color: "#757575", fontWeight: "500" }}
            button
            onClick={() => {
              if (accountInfo?.account_details?.freezed !== "YES") {
                handleClick();
              }
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              style={{ color: "#757575", fontWeight: "500" }}
              primary={`${t("Access Control")}`}
            />
            {opens ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </ListItem>
        )}
        <Collapse in={opens} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sections[7].subSection?.map(
              (section, idx) =>
                section.active &&
                section.env.includes(env.toUpperCase()) && (
                  <ListItem
                    key={idx}
                    disablePadding
                    selected={handleSelected(section.indexName)}
                    onClick={() => {
                      setCurrentSelected(section.indexName);
                      history.push({
                        pathname: "/hotel-dashboard",
                        state: { selectedSection: section.indexName },
                      });
                    }}
                    style={{
                      color: "#757575",
                      fontWeight: "500",
                      marginLeft: "1rem",
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>{section.icon}</ListItemIcon>
                      <ListItemText>
                        {`${t(section.name)}`}
                        {}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </Collapse>
      </List>
    </Stack>
  );

  // const list = (anchor) => (
  //   <Box
  //     sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
  //     role="presentation"
  //     onClick={toggleDrawer(anchor, false)}
  //     onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     <List>
  //       <ListItem
  //         disablePadding
  //         onClick={(e) => setgroupState("Hotels dashboard")}
  //       >
  //         <ListItemButton>
  //           <ListItemIcon>
  //             <DashboardIcon />
  //           </ListItemIcon>
  //           <ListItemText primary="Hotels dashboard" />
  //         </ListItemButton>
  //       </ListItem>
  //       {accountInfo &&
  //         accountInfo?.account_details?.accounting_version === 2 && (
  //           <ListItem
  //             as={Link}
  //             to={{
  //               pathname: "/accounts-dashboard",
  //               state: {
  //                 bookingData: bookingData && bookingData,
  //                 hotelIds: hotelIds && hotelIds,
  //                 groupId: groupId && groupId,
  //               },
  //             }}
  //             style={{ color: "#757575", fontWeight: "500" }}
  //             disablePadding
  //           >
  //             <ListItemButton>
  //               <ListItemIcon>
  //                 <CalculateIcon />
  //               </ListItemIcon>
  //               <ListItemText
  //                 primary="Account and billing"
  //                 style={{ color: "#202020" }}
  //               />
  //             </ListItemButton>
  //           </ListItem>
  //         )}
  //       {env === "BETA" && (
  //         <ListItem
  //           disablePadding
  //           onClick={(e) => setgroupState("Group dashboard")}
  //         >
  //           <ListItemButton>
  //             <ListItemIcon>
  //               <TrendingUpIcon />
  //             </ListItemIcon>
  //             <ListItemText primary="Group dashboard" />
  //           </ListItemButton>
  //         </ListItem>
  //       )}

  //       {(email === "reservation@maharashtatourism.gov.in" ||
  //         email === "onlinesales@teamdelight.co.in" ||
  //         env === "BETA") && (
  //         <ListItem
  //           disablePadding
  //           onClick={(e) => setgroupState("Validate travel agents")}
  //         >
  //           <ListItemButton>
  //             <ListItemIcon>
  //               <TravelExploreIcon />
  //             </ListItemIcon>
  //             <ListItemText primary="Validate travel agents" />
  //           </ListItemButton>
  //         </ListItem>
  //       )}
  //       {userRole?.toLowerCase() === "admin" && (
  //         <ListItem
  //           disablePadding
  //           onClick={(e) => setgroupState("Group admins")}
  //         >
  //           <ListItemButton>
  //             <ListItemIcon>
  //               <DashboardIcon />
  //             </ListItemIcon>
  //             <ListItemText primary="Access control - Group level" />
  //           </ListItemButton>
  //         </ListItem>
  //       )}
  //     </List>
  //     <List>
  //       <ListItem disablePadding onClick={(e) => setgroupState("grpReports")}>
  //         <ListItemButton>
  //           <ListItemIcon>
  //             <DashboardIcon />
  //           </ListItemIcon>
  //           <ListItemText primary="Group reports" />
  //         </ListItemButton>
  //       </ListItem>
  //     </List>
  //     <Divider />
  //   </Box>
  // );

  const removeToken = () => {
    fetch(
      `${
        process.env.REACT_APP_SPRING_API_URL
      }common/logout-remove-user-token?&email=${localStorage.getItem(
        "HotelEmail"
      )}`,
      {
        method: "GET",
        headers: {
          Token: localStorage.getItem("token"),
        },
      }
    )
      .then((data) => console.log("logout-remove-user-token", data))
      .catch((error) =>
        console.error("Error logout-remove-user-token :", error)
      );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ boxShadow: "none" }}>
          <Stack style={{ position: "relative", width: "100%" }}>
            <Stack
              className="position-relative"
              spacing={2}
              style={{ width: "100%" }}
            >
              <Stack
                className="position-relative w-100 d-flex justify-content-between"
                direction="row"
              >
                <Stack
                  className="position-relative w-90 d-flex justify-content-start"
                  direction={"row"}
                  spacing={2}
                >
                  <Stack
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      onClick={() => setOpen((prev) => !prev)}
                      style={{ marginTop: "-15px", marginBottom: "auto" }}
                    >
                      <BsIcons.BsList size="18px" />
                    </Typography>
                  </Stack>
                  {[null, "DISABLED"].includes(groupBrandingData?.status) ? (
                    <Stack
                      style={{
                        marginTop: "-10px",
                        marginBottom: "auto",
                      }}
                    >
                      <img
                        alt="logo"
                        src={groupBrandingData?.logoUrl}
                        style={{ height: "40px", width: "auto" }}
                      />
                    </Stack>
                  ) : null}
                </Stack>
                <Stack style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Typography
                    style={{ marginTop: "-20px", marginBottom: "auto" }}
                  >
                    <DomLink to="/logout">
                      <Button
                        variant="custom-button"
                        style={{
                          height: "fit-content",
                          fontSize: "10px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        onClick={() => removeToken()}
                      >
                        {" "}
                        {`${t("Sign out")}`}{" "}
                      </Button>
                    </DomLink>
                  </Typography>
                </Stack>
                {/* {backBtn && (
                  <Stack direction="row" onClick={() => history.goBack()}>
                    <Typography
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginRight: "2px",
                      }}
                    >
                      <MdIcons.MdKeyboardArrowLeft size="20px" />
                    </Typography>
                    <Typography
                      style={{
                        cursor: "pointer",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                      className="mr-15"
                    >
                      Back
                    </Typography>
                  </Stack>
                )} */}
              </Stack>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          display: { xs: "none", sm: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            height: "100%",
            // top: 65,
            border: "none",
            boxShadow: 24,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {list}
      </Drawer>
      <Main open={open}>
        {contentArea}
        {location?.state?.selectedSection === currentSelected &&
          !location.state.childSection &&
          (bookingData ? (
            sections
              .filter((section) => section.indexName === currentSelected)
              .map((sec) => (
                <Stack style={{ position: "relative", marginTop: "35px" }}>
                  {sec.componentRenderer}
                </Stack>
              ))
          ) : (
            <BookingDataLoader />
          ))}

        {location?.state?.selectedSection === currentSelected &&
          !location.state.childSection &&
          (bookingData ? (
            sections[7].subSection
              .filter((section) => section.indexName === currentSelected)
              .map((sec) => (
                <Stack style={{ position: "relative", marginTop: "35px" }}>
                  {sec.componentRenderer}
                </Stack>
              ))
          ) : (
            <BookingDataLoader />
          ))}
      </Main>
    </Box>
  );
}

function mapStateToProps(state) {
  return { data: state.auth };
}

export default connect(mapStateToProps, auth.actions)(GroupSidebar);
