import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AgGridWrapper from "../AgGridWrapper.js";
import {
  filterParams,
  cellRenderer,
  currencyFormatter,
} from "../utils/ReportUtils";
import moment from "moment";
import { isValid } from "date-fns";
import { useTranslation } from "react-i18next";

function OTAPaymentReport({ data }) {
  const { t } = useTranslation();

  // getting the hotel id from the params
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;
  const isValidHotelPgId =
    !!data?.accessControl?.hotel?.pg_account_id?.trim()?.length > 0 ?? false;
  const hotelCountry = data?.accessControl?.hotel?.hotel_country?.toUpperCase();
  let settlementBankCodeHeader = "Settlement bank code";
  if (hotelCurrency === "INR") {
    settlementBankCodeHeader = "UTR";
  }

  // column definitions
  // defining the column headers, etc.

  const columns = [
    {
      field: "bookingid_display",
      headerName: `${t("Booking id")}`,
      cellRendererFramework: (params) => cellRenderer(params, hotelId),
      checkboxSelection: true,
      headerCheckboxSelection: true,
      dataType: "varchar",
      printWidth: "10%",
    },
    {
      field: "service_date",
      headerName: `${t("Service date")}`,
      filter: "agDateColumnFilter",
      dataType: "date",
      printWidth: "18%",
    },
    {
      field: "booking_source",
      headerName: `${t("Booking source")}`,
      dataType: "varchar",
      printWidth: "14%",
    },
    // {
    //   field: "bookingid_display",
    //   headerName: `${t("Booking id display")}`,
    //   dataType: "varchar",
    // },
    {
      field: "service_amount",
      headerName: `${t("Service amount")}`,
      dataType: "float",
      printWidth: "14%",
      valueFormatter: (params) => {
        return params.value ? Number(params.value).toFixed(2) : "0.00";
      },
    },
    {
      field: "service_amount_desc",
      headerName: `${t("Service amount description")}`,
      dataType: "varchar",
      printWidth: "38%",
    },
  ];

  // Handle the columns
  // If hotel country is not India, and HOTEL.pg_account_id remove the cols
  // 1. pg_settlement_id, pg_settlement_status, pg_settlement_bank_code

  // useEffect(() => {
  //   handleRemoveCols();
  // }, [hotelCountry, isValidHotelPgId]);

  // Setting the pinned row
  const [pinnedRow, setPinnedRow] = useState(null);
  function createPinnedData(params, rowsData) {
    let result = {
      bookingid_display: "Total",
      service_amount: 0,
    };

    function updateVals(i) {
      result.service_amount += i.service_amount;
    }

    params.api.getSelectedRows().length === 0
      ? params.api.forEachNodeAfterFilter((row) => updateVals(row.data))
      : params.api.getSelectedRows().map((row) => updateVals(row));

    result["service_amount"] = Number(result["service_amount"].toFixed(2));

    setPinnedRow([result]);
    params.api.setPinnedBottomRowData([result]);
  }

  return (
    <AgGridWrapper
      reportName={t("OTA Payment Report")}
      columns={columns}
      reportActualType={"otaPaymentReport"}
      createPinnedData={createPinnedData}
      enableDateTypeFilters={false}
      enableCustomizedReport={false}
      pinnedRow={pinnedRow}
      hideIcon={false}
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(OTAPaymentReport);
