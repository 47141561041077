import { Button, Divider, Switch, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import "./CustomerModal.css";
import axios from "axios";

import { toast } from "material-react-toastify";
import { handleSpringDataMutationRequest } from "../../api";
import { useTranslation } from "react-i18next";
import i18n from "../../modules/Auth/pages/i18n";

const CustomReportModal = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);
  const [customReportName, setRustomReportName] = useState(null);
  const [displayedCols, setDisplayedCols] = useState();
  const [hotelID, setHotelID] = useState("");
  const [rName, setRName] = useState("");
  //   console.log(
  //     "prop.cols : ",
  //     props?.colmap,
  //     "displayed cols = ",
  //     displayedCols
  //   );

  const primaryCheck = (e, item) => {
    const tempmap = displayedCols;
    const tempval = tempmap.get(item.field);
    if (e.target.checked) {
      tempval["hidden"] = true;
    } else {
      tempval["hidden"] = false;
    }
    tempmap.set(item.field, tempval);
    setDisplayedCols(tempmap);
  };

  useEffect(() => {
    setDisplayedCols(props?.colmap);
    setHotelID(props?.hotelID);
    setRName(props?.name);
  }, [props]);

  const saveCustomReport = () => {
    const pattern = /[!@#$%^&*(),.?":{}|<>]/;
    if (customReportName === null || customReportName === "") {
      toast.error(`${t("Please enter the report name to save the report")}`);
      return;
    }
    if (pattern.test(customReportName)) {
      toast.error("Special characters are not allowed in the report name");
      return;
    }
    const iterator = displayedCols.entries();
    let flag = true;
    const obj = {};
    for (const item of iterator) {
      obj[item[0]] = item[1];
      if (item[1].hidden) {
        flag = false;
      }
    }
    if (flag) {
      toast.error(
        `${t("Please select at least one column to create the report")}`
      );
      return;
    }
    const payload = {
      hotelId: hotelID,
      customReportName: customReportName,
      reportType: rName,
      config: obj,
    };
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/pc/configure-report",
      payload
    )
      .then((res) => {
        props.hide();
        toast.success(
          `${t(
            "Customized report created successfully please visit customized report page to view the report"
          )}`
        );
      })
      .catch((err) => {
        toast.error(`${t("Please try again later.")}`);
      });
  };

  //   const [all, setAll] = useState(false);
  //   const SelectAllClicked = (e) => {
  //     const tempAllState = all;
  //     setAll(!all);
  //     console.log("displayed cols are here : ", displayedCols);
  //   };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName={"myModalBodyssss"}
        // animation={false}
        contentClassName={"myModalContentsss"}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <Button variant="white" onClick={props.hide}>
              {" "}
              X{" "}
            </Button>
          </div>
          <div style={{ marginLeft: "22px" }}>{props.heading}</div>
        </ModalTitle>
        <ModalBody>
          <TextField
            required
            id="outlined-required"
            label={t("Enter Report Name")}
            defaultValue=""
            // variant="standard"
            value={customReportName}
            fullWidth
            onChange={(e) => setRustomReportName(e.target.value)}
          />

          <Divider className="my-2" />
          {/* <div className="d-flex w-100 justify-content-between mb-1">
            <div className="d-flex justify-content-between w-75 h-50 mb-0 align-items-center ">
              <div className="d-flex flex-column">
                <p className="mb-0">All</p>
              </div>
              <Switch
                //   checked={true}
                onChange={(e) => SelectAllClicked(e)}
              />
            </div>
          </div> */}
          {props.cols &&
            props?.cols
              ?.filter(
                (elm) =>
                  ![
                    "Booking Time",
                    "Check In Time",
                    "Check Out Time",
                    "Booking made on time",
                    "Check-In-Time",
                    "Check-out-Time",
                  ].includes(elm?.headerName)
              )
              .map((item) => {
                return (
                  <div className="d-flex w-100 justify-content-between mb-1">
                    <div className="d-flex justify-content-between w-75 h-50 mb-0 align-items-center ">
                      <div className="d-flex flex-column">
                        <p className="mb-0">{item.headerName}</p>
                      </div>
                      <Switch
                        //   checked={all}
                        onChange={(e) => primaryCheck(e, item)}
                      />
                    </div>
                  </div>
                );
              })}

          <Divider />
          <Button
            className="float-right mr-4 mt-3"
            variant="custom-button"
            // onClick={saveNotification}
            // disabled={!customReportName || customReportName === ""}
            onClick={saveCustomReport}
          >
            {t("Save")}

            {/* {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              "Save"
            )} */}
          </Button>
          {/* <br />
          <br />
          {(customReportName === null || customReportName === "") && (
            <div style={{ color: "red" }}>Please enter the report name</div>
          )} */}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CustomReportModal;

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
