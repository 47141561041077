// ** React Imports
import React from "react";
import { Drawer, Hidden } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: "45%",
    ["@media (min-width:481px) and (max-width:768px)"]: {
      width: "55%",
    },
    ["@media (min-width:769px)"]: {
      width: "45%",
    },
    ["@media (min-width:320px) and (max-width:480px)"]: {
      width: "70%",
    },
  },
  drawerPaper: {
    width: "45%",
    ["@media (min-width:481px) and (max-width:768px)"]: {
      width: "55%",
    },
    ["@media (min-width:769px)"]: {
      width: "45%",
    },
    ["@media (min-width:320px) and (max-width:480px)"]: {
      width: "70%",
    },
  },
}));

const CommonSlider = (props) => {
  // ** Props
  const classes = useStyles();
  const { onClose, open } = props;

  // Custom handler to prevent closing on backdrop click
  const handleClose = (event, reason) => {
    // Only proceed if reason is not 'backdropClick'
    if (reason !== "backdropClick" && onClose) {
      onClose();
    }
  };

  return (
    <Hidden implementation="css">
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={"right"}
        open={open}
        onClose={handleClose} // Use our custom handler
        disableEscapeKeyDown={true} // Prevent closing with Escape key
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        {props.children}
      </Drawer>
    </Hidden>
  );
};

export default CommonSlider;
