import React, { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CropFreeIcon from "@mui/icons-material/CropFree";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HideImageIcon from "@mui/icons-material/HideImage";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import TodayIcon from "@mui/icons-material/Today";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PrintIcon from "@mui/icons-material/Print";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";

function CardforButtons({
  value,
  heading,
  transform,
  marginLeft,
  cardWidth,
  cardHeight,
  fontSize,
  boxShadow,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  // Define the default and hover colors for icon and heading
  const defaultIconColor = "gray";
  const hoverIconColor = "#1ABBB3";
  const defaultHeadingColor = "black";
  const hoverHeadingColor = "#1ABBB3";

  // Define a function to get the icon based on the heading
  const getIcon = () => {
    switch (heading) {
      case `${t("View folio")}`:
        return (
          <ReceiptIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Upload Back id")}`:
      case `${t("Upload front id")}`:
        return (
          <FileUploadIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Print Reg. Card")}`:
        return (
          <PrintIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case `${t("Confirm booking")}`:
        return (
          <ConfirmationNumberIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case `${t("Send Magic Link")}`:
        return (
          <AutoFixHighIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case `${t("Check-in")}`:
        return (
          <CheckCircleOutlineIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case `${t("Settle dues")}`:
        return (
          <AttachMoneyIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case `${t("Checkout")}`:
        return (
          <CheckCircleIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Hold booking")}`:
        return (
          <TimelapseIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Cancel booking")}`:
        return (
          <CancelIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Set to no show")}`:
        return (
          <HideImageIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Lock booking")}`:
        return (
          <LockIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Unlock booking")}`:
        return (
          <LockOpenIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Unassign room")}`:
        return (
          <HorizontalSplitIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Modify checkin")}`:
        return (
          <EventRepeatIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Modify checkout")}`:
        return (
          <TodayIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Edit reservation")}`:
        return (
          <DateRangeIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Split Room")}`:
        return (
          <AccountTreeIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Move Room")}`:
        return (
          <MoveDownIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Rollback booking")}`:
        return (
          <SettingsBackupRestoreIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Release inventory")}`:
        return (
          <CropFreeIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case `${t("Transfer Reservation")}`:
        return (
          <TransferWithinAStationIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      // Add cases for other headings and icons here
      default:
        return null;
    }
  };
  const headingLength = heading.length;
  const dynamicWidth = `${headingLength * 0.75}rem`;

  return (
    <div
      className="informationCardWrapper"
      style={{
        boxShadow: boxShadow,
        transform: transform,
        marginLeft: marginLeft,
        width: "9.5rem",
        height: "7rem",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="figure" style={{ marginTop: "5px", fontSize: "0" }}>
        {getIcon()}
      </div>
      <div
        className="heading"
        style={{
          width: "100%", // Allow the heading text to wrap if needed
          textAlign: "center",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          color: isHovered ? hoverHeadingColor : defaultHeadingColor,
        }}
      >
        {heading}
      </div>
    </div>
  );
}

export default CardforButtons;
