import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Divider,
  Stack,
  Box,
  Breadcrumbs,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Toolbar,
  IconButton,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as BsIcons from "react-icons/bs";
import { connect } from "react-redux";
import MuiAppBar from "@mui/material/AppBar";
import LockIcon from "@mui/icons-material/Lock";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import Lottie from "react-lottie";
import successAnimationData from "../../../app/pages/CalendarPage/Views/lotties/celebration.json";
import { getCsmDetails } from "../../../_metronic/layout/components/extras/dropdowns/apis/NotificationAPI";
import { getAllImagesAndUrl } from "../BookingDashboard/apis/BrandingModalApi";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import PaymentModal from "../AccessControl/PaymentModal";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  top: 64,
  backgroundColor: "white",
  boxShadow: "none",
  zIndex: 1,
}));

const Card = ({ name, description, index, pathName, parentindex }) => {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId, shopName, shopType } = useParams();
  const history = useHistory();
  const location = useLocation();

  // set the links while creating the drawer
  const constructPathName = () => {
    return `/${pathName}`;
  };

  return (
    <div
      className="reportsTypeCards"
      onClick={() =>
        history.push({
          pathname: constructPathName(),
          search: `?hotelId=${hotelId}`,
          state: { index: index, parentindex: parentindex },
        })
      }
    >
      <div className="reportsTypeBody">
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

function ReportWrapper({
  backBtn = false,
  contentArea = null,
  data,
  showReportTypes = true,
  showReportName = true,
  reportName = "",
  filters = null,
  hideDrawer = false,
  hideIcon,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const history = useHistory();
  const location = useLocation();
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;
  // Opening the drawer
  const [open, setOpen] = useState(true);
  const handleCloseDrawer = () => setOpen(false);
  const [isCustomPg, setIsCustomPg] = useState("DISABLED");
  useEffect(() => {
    handleSpringDataRequest(`core/api/v1/be/get-hotel-content`)
      .then((data) => {
        setIsCustomPg(data?.useCustomPGInfo);
      })
      .catch(() => {});
  }, []);

  // console.log("hideIcon", data?.accessControl?.user_feature_map);

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (hideDrawer) {
      handleCloseDrawer();
    }
  }, [hideDrawer]);

  // Render the tab based upon the clicking of the tab name
  const [tabName, setTabName] = useState(0);
  useEffect(() => {
    if (location.state) {
      setTabName(location.state.parentindex);
    } else {
      setTabName(0);
    }
  }, [location]);

  //PAYMENT
  const [opens, setOpens] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);

  const handleMenuModal = () => {
    setOpens(true);
  };

  const [paymentLoader, setpaymentLoader] = useState(false);
  const startTrial = () => {
    setpaymentLoader(true);
    const payload = {
      hotelId: hotelId,
      groupId: localStorage.getItem("mainGroupId"),
      description: `Advanced reports upgrade`,
      type: "ADDON",
      amount: hotelCurrency?.toUpperCase() === "INR" ? "999" : "49",
      addon_feature_id: 228,
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/feature-upgrade/single-feature-upgrade`,
      payload
    )
      .then(() => {
        setpaymentLoader(false);
        setOpen(false);
        setconfirmModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setpaymentLoader(false);
      });
  };

  const [csmData, setCsmData] = useState(null);
  useEffect(async () => {
    const groupId = data?.accessControl?.hotel?.group_id;
    if (groupId && csmData == null) {
      await getCsmDetails(groupId).then((resp) => {
        if (String(resp?.status) === "200") {
          const data = resp?.data;
          setCsmData(data);
        }
      });
    }
  }, [data?.accessControl?.hotel?.group_id]);

  // Test
  const allReportTypes = [
    {
      parent_report: `${t("Property reports")}`,
      parent_report_index: 0,
      parent_report_link: "property-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.PropertyReports)
          .read === "YES",
      sub_reports: [
        {
          sub_report: `${t("Master report")}`,
          sub_report_link: "reports/master",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.MasterReport)
              .read === "YES",
          description:
            `${t("Get all the details of bookings, customer information,")}` +
            `${t("payments, and taxes in a single report. ")}`,
        },
        {
          sub_report: `${t("Flash manager report")}`,
          sub_report_link: "reports/flash-manager",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.FlashManagerReport)
              .read === "YES",
          description:
            `${t("Summary of today, month-to-date, and year-to-date ")}` +
            `${t("occupancy, ADR, RevPAR, Taxes, and other ")}` +
            `${t("essential metrics.")}`,
        },
        {
          sub_report: `${t("Room revenue report")}`,
          sub_report_link: "reports/room-revenue",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.RoomRevenueReport)
              .read === "YES",
          description:
            `${t("A detailed view of the room revenue, services revenue, ")}` +
            `${t("taxes, payments, and refunds grouped by booking id. ")}` +
            `${t("A helpful report for daily auditing.")}`,
        },

        {
          sub_report: `${t("Sales report")}`,
          sub_report_link: "reports/sales",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.SalesReport)
              .read === "YES",
          description:
            `${t("Date-wise performance report of the hotel. ")}` +
            `${t("Occupancy, ADR, RevPAR, Revenue, payments, ")}` +
            `${t("and other vital metrics.")}`,
        },

        {
          sub_report: `${t("Night audit report")}`,
          sub_report_link: "reports/night-audit",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.NightAuditReport)
              .read === "YES",
          description:
            `${t("Summary and detailed report of house status, ")}` +
            `${t("reservations, payments, and taxes as of any given day. ")}` +
            `${t("We recommend to run this report before closing a day.")}`,
        },
        {
          sub_report: `${t("Folio report")}`,
          sub_report_link: "reports/folio",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.FolioReport)
              .read === "YES",
          description:
            `${t("A quick snapshot of guest folio balances and ")}` +
            `${t("payments made. Useful for end-of-the-day balancing.")}`,
        },
        {
          sub_report: `${t("Room bookings report")}`,
          sub_report_link: "reports/room-booking",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.MasterReport)
              .read === "YES",
          description: `${t(
            "Room booking report provides a comprehensive breakdown of room categories, including room count, nights booked, rate plans, occupancy details along with other details. "
          )}`,
        },

        {
          sub_report: `${t("Room inventory report")}`,
          sub_report_link: "reports/inventory",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.InventoryReport)
              .read === "YES",
          description:
            `${t("Date-wise room inventory metrics like Total rooms, ")}` +
            `${t("available rooms, blocked rooms, and sold rooms.")}`,
        },

        {
          sub_report: `${t("Shift report")}`,
          sub_report_link: "reports/payments-user-report",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.ShiftReport)
              .read === "YES",
          description:
            `${t("Summary of all payment-related transactions performed ")}` +
            `${t("by hotel staff during their shift. ")}`,
        },
        {
          sub_report: `${t("BAR pricing report")}`,
          sub_report_link: "reports/bar-pricing",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.BARPricingReport)
              .read === "YES",
          description: `${t(
            "The BAR Pricing Report provides a detailed view of rates for default occupancy as set at the room level."
          )}`,
        },
        {
          sub_report: `${t("Day use report")}`,
          sub_report_link: "reports/day-use",
          active: true,
          description:
            "All the day use bookings, same day checkin and checkouts for the given date range",
        },

        {
          sub_report: `${t("Pace report")}`,
          sub_report_link: "reports/pace",
          active: false,
          description: `${t("Pace report")}`,
        },
        {
          sub_report: `${t("Market segmentation report")}`,
          sub_report_link: "reports/market-segmentation",
          active: false,
          description: `${t("Market segmentation report")}`,
        },
        {
          sub_report: `${t("Room upsells report")}`,
          sub_report_link: "reports/room-upsells",
          active: false,
          description: `${t("Room upsells report")}`,
        },
        {
          sub_report: `${t("Comparison report")}`,
          sub_report_link: "reports/comparison",
          active: false,
          description: `${t("Comparison report")}`,
        },
        {
          sub_report: `${t("City ledger report")}`,
          sub_report_link: "reports/city-ledger",
          active: false,
          description: `${t("City ledger report")}`,
        },
        {
          sub_report: `${t("Room revenue report(by Source)")}`,
          sub_report_link: "reports/room-revenue-src",
          active: false,
          description:
            `${t("Room revenue report grouped by booking source for the ")}` +
            `${t("given date range. Useful to compare channel-wise ")}` +
            `${t("performance.")}`,
        },
      ],
    },
    {
      parent_report: `${t("Front desk report")}`,
      parent_report_index: 1,
      parent_report_link: "frontdesk-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.FrontDeskReport)
          .read === "YES",
      sub_reports: [
        {
          sub_report: `${t("New bookings report")}`,
          sub_report_link: "reports/new-bookings",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.NewBookingsReport)
              .read === "YES",
          description:
            `${t("All the new reservations from all the sources. ")}` +
            `${t(
              "Including walk-ins, OTA bookings,  etc., for the given date."
            )}`,
        },
        {
          sub_report: `${t("In-house report")}`,
          sub_report_link: "reports/in-house",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.InHouseReport)
              .read === "YES",
          description: `${t("Guests staying in-house for the given date ")}`,
        },
        {
          sub_report: `${t("Arrivals report")}`,
          sub_report_link: "reports/arrivals",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.ArrivalsReport)
              .read === "YES",
          description: `${t("Guests arrivals report for the given date")}`,
        },
        {
          sub_report: `${t("Departures report")}`,
          sub_report_link: "reports/departures",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.DeparturesReport)
              .read === "YES",
          description: `${t("Guests departures report for the given date ")}`,
        },
        {
          sub_report: `${t("On-hold report")}`,
          sub_report_link: "reports/on-hold",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.OnHoldReport)
              .read === "YES",
          description: `${t(
            "Bookings that went on-hold for the given date (based on stay date)"
          )}`,
        },
        {
          sub_report: `${t("Self checkins report")}`,
          sub_report_link: "reports/self-checkin",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.SelfCheckinsReport)
              .read === "YES",
          description: `${t(
            "Self check-ins performed using Magic Link for the given date range"
          )}`,
        },
        {
          sub_report: `${t("Room upgrade report")}`,
          sub_report_link: "reports/room-upgrade",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.RoomUpgradeReport)
              .read === "YES",
          description: `${t(
            "Room upgrades performed using Magic Link for the given date range"
          )}`,
        },
        {
          sub_report: `${t("Early checkin report")}`,
          sub_report_link: "reports/early-cin",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.EarlyCheckinReport)
              .read === "YES",
          description: `${t(
            "Early check-ins performed using Magic Link for the given date range"
          )}`,
        },
        {
          sub_report: `${t("Late checkout report")}`,
          sub_report_link: "reports/late-cout",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.LateCheckoutReport)
              .read === "YES",
          description: `${t(
            "Late check-outs performed using Magic Link for the given date range"
          )}`,
        },
        {
          sub_report: `${t("Booking notes report")}`,
          sub_report_link: "reports/booking-notes",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.BookingNotesReport)
              .read === "YES",
          description: `${t(
            "All the booking notes entered for the given date range"
          )}`,
        },
        {
          sub_report: `${t("Customer notes report")}`,
          sub_report_link: "reports/customer-notes",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.CustomerNotesReport
            ).read === "YES",
          description: `${t(
            "All the customer notes entered for the given date range"
          )}`,
        },
        {
          sub_report: `${t("Rate plan count report")}`,
          sub_report_link: "reports/meal-plan-count",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.RatePlanCountReport
            ).read === "YES"
              ? hotelCurrency?.toUpperCase() === "USD"
                ? false
                : true
              : false,
          description:
            `${t(
              "The rate plan report will give you a count on the number of rate plans opted by guests so "
            )}` +
            `${t("that the hotel can prepare meals accordingly. ")}` +
            `${t(
              "This will also give insight which is the popular rate plan."
            )}`,
        },

        {
          sub_report: `${t("No show report")}`,
          sub_report_link: "reports/no-show",
          active: true,
          // active:
          //   JSON.parse(data?.accessControl?.user_feature_map?.NewBookingsReport)
          //     .read === "YES",

          description: `${t("No show report")}`,
        },
      ],
    },
    {
      parent_report: `${t("Payment reports")}`,
      parent_report_index: 2,
      parent_report_link: "payment-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.PaymentReport).read ===
        "YES",
      sub_reports: [
        {
          sub_report: `${t("Payment gateway report")}`,
          sub_report_link: "reports/payment-gateway",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.PaymentGatewayReport
            ).read === "YES",
          description:
            `${t(
              "All the information about payments processed via payment gateways: "
            )}` +
            `${t(
              "for Stripe, and Razorpay, we do include transfer information. "
            )}`,
        },
        {
          sub_report: `${t("Cash & Counter report")}`,
          sub_report_link: "reports/counter",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.CashCounterReport)
              .read === "YES",
          description: `${t(
            "All the cash and other offline payment transactions can be accessed in this report"
          )}`,
        },
        {
          sub_report: `${t("Refunds report")}`,
          sub_report_link: "reports/refunds",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.RefundsReport)
              .read === "YES",
          description: `${t(
            "This report provides payment gateway and cash refund information for the given date range."
          )}`,
        },
        {
          sub_report: `${t("Transfers report")}`,
          sub_report_link: "reports/transfers",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.TransferReport)
              .read === "YES",
          description: `${t(
            "Payment settlement report for applicable payment gateways: Stripe and Razorpay. "
          )}`,
        },
        {
          sub_report: `${t("Payments report by payment type")}`,
          sub_report_link: "reports/payments-type-report",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.PaymentReportByPaymentType
            ).read === "YES",
          description: `${t(
            "Payments report by payment type, like visa, mastercard..etc "
          )}`,
        },
        {
          sub_report: `${t("Counter report by payment type")}`,
          sub_report_link: "reports/counter-type-report",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.CounterReportByPaymentType
            ).read === "YES",
          description: `${t(
            "Counter report by payment type, like cash, offline card..etc "
          )}`,
        },
        {
          sub_report: `${t("Commission report")}`,
          sub_report_link: "reports/commission",
          active: isCustomPg === "ENABLED",
          description: `${"This report provides the commissions for the payments on direct bookings and magic link processed using your merchant account."}`,
        },
        {
          sub_report: `${t("OTA payment report")}`,
          sub_report_link: "reports/ota-payment-report",
          active: true,
          description: `${t(
            "The OTA payment report provides insight into the amount a guest has paid to the OTA at the time of booking creation."
          )}`,
        },
        {
          sub_report: `${t("Commissions report")}`,
          sub_report_link: "reports/commissions",
          active: false,
          description: `${t("Commissions report")}`,
        },
      ],
    },
    {
      parent_report: `${t("Service reports")}`,
      parent_report_index: 3,
      parent_report_link: "service-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.ServiceReport).read ===
        "YES",
      sub_reports: [
        {
          sub_report: `${t("Service revenue report")}`,
          sub_report_link: "reports/service",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.ServiceRevenueReport
            ).read === "YES",
          description: `${t(
            "Report of all the addons serviced, like folio addons"
          )}`,
        },
        {
          sub_report: `${t("Service sales report")}`,
          sub_report_link: "reports/service-sales",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.ServiceSalesReport)
              .read === "YES",
          description: `${t(
            "Datewise report of sales revenue. Has per day, occupancy%, ADR, REVPAR, payment collected, etc."
          )}`,
        },
        {
          sub_report: `${t("Refunds report")}`,
          sub_report_link: "reports/refunds",
          active: false,
          description: `${t("Refund report")}`,
        },
      ],
    },
    {
      parent_report: `${t("Tax reports")}`,
      parent_report_index: 4,
      parent_report_link: "tax-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.TaxReports).read ===
        "YES",
      sub_reports: [
        {
          sub_report: `${t("Room taxes report")}`,
          sub_report_link: "reports/room-taxes",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.RoomTaxesReport)
              .read === "YES",
          description: `${t("Booking wise tax report")}`,
        },
        {
          sub_report: `${t("Service taxes report")}`,
          sub_report_link: "reports/service-taxes",
          active: false,
          description: `${t(
            "Report describes all the service taxes levied on the particular booking"
          )}`,
        },
        {
          sub_report: `${t("Taxes summary report")}`,
          sub_report_link: "reports/taxes-summary",
          active: false,
          description: `${t("Taxes summary report")}`,
        },
        {
          sub_report: `${t("GST report")}`,
          sub_report_link: "reports/gst",
          active: false,
          description: `${t("GST report for indian properties")}`,
        },
      ],
    },
    {
      parent_report: `${t("POS reports")}`,
      parent_report_index: 5,
      parent_report_link: "pos-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.ShopReports).read ===
        "YES",
      sub_reports: [
        {
          sub_report: `${t("Shop wise report")}`,
          sub_report_link: "reports/shop-wise",
          active: false,
        },
        {
          sub_report: `${t("Shopwise revenue report")}`,
          sub_report_link: "reports/shopwise-rev",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.ShopwiseRevenueReport
            ).read === "YES",
          description: `${t(
            "Report describes the revenue collected across all the outlets for the particular hotel."
          )}`,
        },
        {
          sub_report: `${t("Sales POS report")}`,
          sub_report_link: "reports/pos-sales",
          active: false,
        },

        {
          sub_report: `${t("All outlets Day wise sales summary report")}`,
          sub_report_link: "reports/all-outlets-day-sales-summary",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map
                .AllOutletsDayWiseSalesSummaryReport
            ).read === "YES",
          description: `${t(
            "Consolidated sales of all your restaurant day wise"
          )}`,
        },
        {
          sub_report: `${t("All outlets hourly items sales summary report")}`,
          sub_report_link: "reports/all-outlets-hourly-items-sales",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map
                .AllOutletsHourlyItemsSalesSummaryReport
            ).read === "YES",
          description: `${t(
            "A report of hourly variation in your item sales"
          )}`,
        },
        {
          sub_report: `${t("All outlets itemwise category summary report")}`,
          sub_report_link: "reports/all-outlets-item-category-summary",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map
                .AllOutletsItemWiseCategorySummaryReport
            ).read === "YES",
          description: `${t("Get the summary of categories")}`,
        },
        {
          sub_report: `${t("All outlets order wise sales summary report")}`,
          sub_report_link: "reports/all-outlets-orders-sales-summary",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map
                .AllOutletsOrderWiseSalesSummaryReport
            ).read === "YES",
          description: `${t(
            "Get the summary of order wise sales summary report"
          )}`,
        },
      ],
    },
    {
      parent_report: `${t("Log reports")}`,
      parent_report_index: 6,
      parent_report_link: "log-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.LogReports).read ===
        "YES",
      sub_reports: [
        {
          sub_report: `${t("User log report")}`,
          sub_report_link: "reports/user-log",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.UserLogReport)
              .read === "YES",
          description: `${t(
            "Detailed report of user logs for given operation type and sub-operation type"
          )}`,
        },
        {
          sub_report: `${t("Inventory log report")}`,
          sub_report_link: "reports/inventory-log",
          active: false,
          description: `${t(
            "Report describes the inventory summary of all the items into the respective outlet."
          )}`,
        },
        {
          sub_report: `${t("Prices log report")}`,
          sub_report_link: "reports/prices-log",
          active: false,
          description: `${t("Prices log report")}`,
        },
        {
          sub_report: `${t("Config log report")}`,
          sub_report_link: "reports/config-log",
          active: false,
          description: `${t("Config log report")}`,
        },
        {
          sub_report: `${t("Channels log report")}`,
          sub_report_link: "reports/channels-log",
          active: false,
          description: `${t("Channel log report")}`,
        },
        {
          sub_report: `${t("Payments log report")}`,
          sub_report_link: "reports/payments-log",
          active: false,
          description: `${t("Payment log report")}`,
        },
        {
          sub_report: `${t("Housekeeping log report")}`,
          sub_report_link: "reports/hk-log",
          active: false,
          description: `${t("Housekeeping log report")}`,
        },
      ],
    },
    {
      parent_report: `${t("Booking engine reports")}`,
      parent_report_index: 7,
      parent_report_link: "be-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.BookingEngineReports)
          .read === "YES",
      sub_reports: [
        {
          sub_report: `${t("BE Bookings report")}`,
          sub_report_link: "reports/be-bookings",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.BEBookingsReport)
              .read === "YES",
          description: `${t(
            "Report of all the bookings received via booking engine"
          )}`,
        },
        {
          sub_report: `${t("Traffic report")}`,
          sub_report_link: "reports/traffic",
          active: false,
          description: `${t("Traffic report")}`,
        },
      ],
    },
    {
      parent_report: `${t("Customers reports")}`,
      parent_report_index: 8,
      parent_report_link: "customer-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.CustomerReports)
          .read === "YES",
      sub_reports: [
        {
          sub_report: `${t("Customers report")}`,
          sub_report_link: "reports/customers",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.CustomersReport)
              .read === "YES",
          description: "Report of all the room guests to have every stayed",
        },
        {
          sub_report: `${t("Do not rent list report")}`,
          sub_report_link: "reports/dnr-list",
          active: false,
          description: `${t(
            "Reports of all the customers with the tag of do not rent"
          )}`,
        },
        {
          sub_report: `${t("Customer notes report")}`,
          sub_report_link: "reports/customer-notes",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.CustomersNotesReports
            ).read === "YES",
          description: `${t("Customer notes report")}`,
        },
      ],
    },
    {
      parent_report: `${t("Channel manager reports")}`,
      parent_report_index: 9,
      parent_report_link: "cm-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.ChannelManagerReports)
          .read === "YES",
      sub_reports: [
        {
          sub_report: `${t("CM Bookings report")}`,
          sub_report_link: "reports/cm-bookings",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.CMBookingsReport)
              .read === "YES",
          description: `${t(
            "Report of all the bookings received from various channels"
          )}`,
        },
        {
          sub_report: `${t("Room revenue report (OTA wise)")}`,
          sub_report_link: "reports/room-revenue-ota",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.RoomRevenueReportOTAWise
            ).read === "YES",
          description: `${t(
            "Room revenue from each booking seggregated based on the OTAs"
          )}`,
        },
        {
          sub_report: `${t("Occupancy report")}`,
          sub_report_link: "reports/occupancy",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.OccupancyReport)
              .read === "YES",
          description: `${t(
            "Roomwise occupany report. See who occupied the room on a given day"
          )}`,
        },
        {
          sub_report: `${t("Channel logs report")}`,
          sub_report_link: "reports/channel-logs",
          active: false,
          description: `${t("Channel log report")}`,
        },
        {
          sub_report: `${t("Commissions & taxes report")}`,
          sub_report_link: "reports/commission-taxes",
          active: false,
          description: `${t(
            "Report describe commissions and taxes from Channel Managers"
          )}`,
        },
      ],
    },
    {
      parent_report: `${t("Direct billing reports")}`,
      parent_report_index: 10,
      parent_report_link: "db-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.DirectBillingReports)
          .read === "YES",
      sub_reports: [
        {
          sub_report: `${t("Account receivable balance report")}`,
          sub_report_link: "reports/db-ar-balance-report",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map
                .AccountReceivableBalanceReport
            ).read === "YES",
          description: `${t(
            "Direct billing account receivable balance report"
          )}`,
        },
        // {
        //   sub_report: "Account receivable aging report",
        //   sub_report_link: "reports/db-ar-aging-report",
        //   active:
        //     JSON.parse(
        //       data?.accessControl?.user_feature_map.AccountReceivableAgingReport
        //     ).read === "YES",
        //   description: "Direct billing account receivable aging report",
        // },
      ],
    },
    {
      parent_report: `${t("Customized reports")}`,
      parent_report_index: 11,
      parent_report_link: "customized-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.CustomizedReports)
          .read === "YES",
      sub_reports: [
        {
          sub_report: `${t("Customized report")}`,
          sub_report_link: "reports/custom",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.CustomizedReports)
              .read === "YES",
          description: `${t("View all customized reports")}`,
        },
      ],
    },
    // Dont remove also dont change the index
    {
      parent_report: `${t("Expense reports")}`,
      parent_report_index: 12,
      parent_report_link: "customized-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.ExpenseReports)
          .read === "YES",
      sub_reports: [
        {
          sub_report: `${t("Expense manager report")}`,

          sub_report_link: "reports/expense",
          active:
            JSON.parse(
              data?.accessControl?.user_feature_map.ExpenseManagerReports
            ).read === "YES",
          description: `${t("View all expense reports")}`,
        },
      ],
    },
    {
      parent_report: `${t("Tally reports")}`,
      parent_report_index: 13,
      parent_report_link: "tally-reports",
      parent_report_active:
        JSON.parse(data?.accessControl?.user_feature_map.TallySync).read ===
        "YES",
      sub_reports: [
        {
          sub_report: `${t("Tally sync report")}`,

          sub_report_link: "reports/tally",
          active:
            JSON.parse(data?.accessControl?.user_feature_map.TallySync).read ===
            "YES",
          description: `${t("View all tally sync reports")}`,
        },
      ],
    },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // set the links while creating the drawer
  const constructPathName = (pathName) => {
    return `/${pathName}`;
  };

  const handleSelected = (index) => {
    if (tabName === index) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    handleSelected();
  }, [location?.state?.parentindex]);

  const generateLinkForTabs = (tabName, parentindex) => {
    history.push({
      pathname: "/reports",
      search: `?hotelId=${hotelId}`,
      state: { parentindex: parentindex },
    });
  };

  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };
  const [paymentAmount, setpaymentAmount] = useState(0);
  const [daysDiff, setdaysDiff] = useState(0);

  const [finalDate, setfinalDate] = useState(new Date());

  useEffect(() => {
    if (
      localStorage.getItem("mainGroupId") &&
      localStorage.getItem("mainGroupId") !== undefined &&
      localStorage.getItem("mainGroupId") !== null
    ) {
      handleSpringDataRequest(
        `web-api/get-payment-dues-amount?groupId=${localStorage.getItem(
          "mainGroupId"
        )}`
      ).then((res) => {
        if (
          res.pendingAmount < 0 &&
          Number(res.daysDiff) >= 30 &&
          Number(res.daysDiff) <= 60 &&
          res.showBanner === "YES"
        ) {
          setpaymentAmount(res.pendingAmount);
          setShowPaymentModal(true);
          setdaysDiff(Number(res.daysDiff));
          setfinalDate(res.date);
        }
      });
    }
  }, [localStorage.getItem("mainGroupId")]);

  const drawer = (
    <Stack
      className="ml-4"
      style={{
        position: "relative",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <List
        style={{
          position: "relative",
          height: "fit-content",
          overflow: "hidden",
          overflowY: "scroll",
          marginBottom: "100px",
          marginTop: "8px",
        }}
      >
        {allReportTypes.map((item, index) => (
          <Stack className="mt-2 ml-4 position-relative w-100">
            <Stack
              spacing={1}
              direction="row"
              style={{ cursor: "pointer", position: "relative", width: "100%" }}
            >
              {item?.parent_report_active && (
                <ListItem
                  key={index}
                  disablePadding
                  style={{
                    backgroundColor: "f8f8f8",
                  }}
                  selected={handleSelected(item?.parent_report_index)}
                  onClick={(event) => {
                    setTabName(item?.parent_report_index);
                    generateLinkForTabs(
                      item?.parent_report_link,
                      item?.parent_report_index
                    );
                  }}
                >
                  <ListItemButton
                    style={{
                      backgroundColor: "f8f8f8",
                    }}
                  >
                    <ListItemText
                      style={{ color: "black" }}
                      primary={item?.parent_report}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </Stack>
          </Stack>
        ))}
      </List>
    </Stack>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position={!hideIcon ? "fixed" : "absolute"} open={open}>
        <Toolbar sx={{ boxShadow: "none" }}>
          <Stack style={{ position: "relative", width: "100%" }}>
            <Stack
              className="position-relative"
              spacing={2}
              style={{ width: "100%" }}
            >
              <Stack
                className="position-relative w-100 d-flex justify-content-between"
                direction="row"
              >
                <Stack
                  className="position-relative w-90 d-flex justify-content-start"
                  direction={"row"}
                  spacing={2}
                >
                  {!hideIcon && (
                    <Stack
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => setOpen((prev) => !prev)}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        <BsIcons.BsList size="25px" />
                      </Typography>
                    </Stack>
                  )}
                  <Stack style={{ marginTop: "auto", marginBottom: "auto" }}>
                    {showReportName && (
                      <Typography>
                        <strong
                          style={{
                            fontSize: "15px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {reportName}
                        </strong>
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    style={{
                      position: "relative",
                      marginTop: "auto",
                      marginBottom: "auto",
                      display: filters ? "block" : "none",
                    }}
                  >
                    {filters}
                  </Stack>
                </Stack>
                {backBtn && !hideIcon && (
                  <Stack direction="row" onClick={() => history.goBack()}>
                    <Typography
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginRight: "2px",
                      }}
                    >
                      <MdIcons.MdKeyboardArrowLeft size="20px" />
                    </Typography>
                    <Typography
                      style={{
                        cursor: "pointer",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                      className="mr-15"
                    >
                      Back
                    </Typography>
                  </Stack>
                )}
                {!backBtn && (
                  <Button
                    sx={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: "50px",
                    }}
                    variant="custom-button"
                    startIcon={
                      JSON.parse(
                        data?.accessControl?.user_feature_map.ReportsSchedule
                      )?.read !== "YES" && <LockIcon />
                    }
                    onClick={() => {
                      const resp =
                        JSON.parse(
                          data?.accessControl?.user_feature_map?.ReportsSchedule
                        ).read === "YES"
                          ? history.push({
                              pathname: "/reportScheduler",
                              search: `?hotelId=${hotelId}`,
                              state: { index: -1, parentindex: -1 },
                            })
                          : handleMenuModal();
                    }}
                  >
                    Schedule reports
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          display: { xs: "none", sm: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            height: "100%",
            top: 65,
            border: "none",
            boxShadow: 24,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {drawer}
      </Drawer>
      <Main open={open}>
        {contentArea}
        {showReportTypes &&
          allReportTypes
            .filter(
              (all_reports) => all_reports.parent_report_index === tabName
            )
            .map((report) => (
              <Stack className="mt-4">
                {open === false && (
                  <Typography>
                    <h3>{report.parent_report}</h3>
                  </Typography>
                )}
                <Stack
                  className="position-relative mt-4 d-flex flex-wrap justify-content-start"
                  direction={"row"}
                  style={{}}
                >
                  {report.sub_reports.map(
                    (item, index) =>
                      item.active === true && (
                        <Stack
                          style={{
                            position: "relative",
                            marginLeft: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <Card
                            index={index}
                            name={item?.sub_report}
                            parentindex={report.parent_report_index}
                            description={
                              item?.description ? item?.description : ""
                            }
                            pathName={item?.sub_report_link}
                          />
                        </Stack>
                      )
                  )}
                </Stack>
              </Stack>
            ))}
      </Main>
      <Modal
        open={opens}
        onClose={() => setOpens(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            height: "42rem",
            width: "82rem",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Upgrade for new features</div>
              <div className="close" onClick={() => setOpens(false)}>
                X
              </div>
            </div>

            <div className="d-flex">
              <div className="amountWrapper">
                <h1 style={{ marginTop: "2rem" }}>
                  Unlock the potential of your data. Upgrade to advanced
                  reports.
                </h1>
                <h3 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                  Advanced reports let’s you create custom reports, report
                  schedules and view reports for more than 6 months date range.
                </h3>

                <div className="d-flex justify-content-center">
                  <span
                    class="badge badge-warning"
                    style={{
                      fontWeight: "bold",
                      height: "4rem",
                      width: "30rem",
                      marginTop: "3rem",
                      fontSize: "20px",
                      lineHeight: "28px",
                      marginRight: "18px",
                    }}
                  >
                    {`Limited time offer, ${
                      hotelCurrency?.toUpperCase() === "INR"
                        ? "66.66%"
                        : "75.00%"
                    } discounted`}
                  </span>
                </div>

                <div
                  style={{
                    position: "absolute",
                    bottom: "2rem",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="custom-button"
                    style={{
                      position: "absolute",
                      bottom: "4rem",
                      width: "46%",
                      fontWeight: "bold",
                      height: "4rem",
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      if (data?.accessControl?.ADMINRIGHTS === "ADMIN") {
                        startTrial();
                      } else {
                        toast.warning(
                          "Please contact admin to enable this feature"
                        );
                      }
                    }}
                    disabled={paymentLoader}
                  >
                    {paymentLoader ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : hotelCurrency?.toUpperCase() === "INR" ? (
                      <div>
                        Upgrade for INR{" "}
                        <span style={{ textDecoration: "line-through" }}>
                          3000
                        </span>{" "}
                        999 / month
                      </div>
                    ) : (
                      <div>
                        Upgrade for{" "}
                        <span style={{ textDecoration: "line-through" }}>
                          199
                        </span>{" "}
                        49 / month
                      </div>
                    )}
                  </Button>
                  {String(whiteLabelled) === "DISABLED" && (
                    <p
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginTop: "2rem",
                        color: "#1BC5BD",
                      }}
                      onClick={() =>
                        window.open(
                          Object.entries(csmData).length > 0
                            ? csmData.account_csm_calendar_link
                            : "https://calendly.com/bharath-gowda/30min",
                          "_blank"
                        )
                      }
                    >
                      Talk to sales
                    </p>
                  )}
                </div>
              </div>

              <div>
                <img
                  style={{
                    height: "36rem",
                    width: "40rem",
                  }}
                  src={
                    "https://prodimagesbucket.blob.core.windows.net/misc/SF%20Mobile%20app%20New%20look%20(1).jpg"
                  }
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={confirmModal}
        onClose={() => setconfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            height: "25rem",
            width: "70rem",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Upgrade for new features</div>
              <div className="close" onClick={() => setconfirmModal(false)}>
                X
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <Lottie options={successAnimation} height={150} width={150} />
              <h1>
                Successfully upgraded please logout and login to access this
                feature
              </h1>
            </div>
          </div>
        </Box>
      </Modal>
      <PaymentModal
        showPaymentModal={showPaymentModal}
        paymentAmount={paymentAmount}
        closePaymentModal={closePaymentModal}
        days={daysDiff}
        finalDate={finalDate}
      />
    </Box>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(ReportWrapper);
