import React, { useState, useEffect } from "react";
import { Grid, List, Typography } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemIcon } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Collapse } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { handleSpringDataRequest } from "../../api";

import AccessibilityIcon from "@mui/icons-material/Accessibility";

import SettingsIcon from "@mui/icons-material/Settings";
import { Policy } from "@mui/icons-material";
import WidgetsIcon from "@mui/icons-material/Widgets";
import AddIcon from "@mui/icons-material/Add";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import * as AiIcons from "react-icons/ai";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaidIcon from "@mui/icons-material/Paid";
import SellIcon from "@mui/icons-material/Sell";
import PaymentsSharpIcon from "@mui/icons-material/PaymentsSharp";
import RunNightAudit from "./RunNightAudit";
import NightAuditHistory from "./NightAuditHistory";
import NightAuditConfiguration from "./NightAuditConfiguration";
import PaymentModal from "../AccessControl/PaymentModal";

export default function NightAuditDashboard() {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Night Audit History");
  const [mykeys, setmykeys] = useState(2);
  const [accessControl, setaccessControl] = useState({});
  const handleClick = () => {
    setOpen(!open);
  };
  const handleMenuItemClick = (item, val) => {
    setSelectedItem(item);
    setmykeys(val);
  };

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/accesscontrol/addUserWizardRender?email=${localStorage.getItem(
        "email"
      )}`
    )
      .then((res) => {
        console.log(res);
        setaccessControl(res.user_feature_map);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };
  const [paymentAmount, setpaymentAmount] = useState(0);
  const [daysDiff, setdaysDiff] = useState(0);

  const [finalDate, setfinalDate] = useState(new Date());

  useEffect(() => {
    if (
      localStorage.getItem("mainGroupId") &&
      localStorage.getItem("mainGroupId") !== undefined &&
      localStorage.getItem("mainGroupId") !== null
    ) {
      handleSpringDataRequest(
        `web-api/get-payment-dues-amount?groupId=${localStorage.getItem(
          "mainGroupId"
        )}`
      ).then((res) => {
        if (
          res.pendingAmount < 0 &&
          Number(res.daysDiff) >= 30 &&
          Number(res.daysDiff) <= 60 &&
          res.showBanner === "YES"
        ) {
          setpaymentAmount(res.pendingAmount);
          setShowPaymentModal(true);
          setdaysDiff(Number(res.daysDiff));
          setfinalDate(res.date);
        }
      });
    }
  }, [localStorage.getItem("mainGroupId")]);

  return (
    Object.entries(accessControl).length > 0 && (
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ display: "flex" }}
      >
        <Grid item className="sidebarStatic" style={{ width: "20%" }}>
          <List>
            <ListItem
              button
              onClick={() => handleMenuItemClick("Night Audit History", 2)}
            >
              <ListItemIcon
                style={{
                  color: `${
                    selectedItem === "Night Audit History"
                      ? "#1BC5BD"
                      : "#757575"
                  }`,
                }}
              >
                <PaymentsSharpIcon />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: `${
                    selectedItem === "Night Audit History"
                      ? "#1BC5BD"
                      : "#757575"
                  }`,
                }}
                primary={"Night Audit History"}
              />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                handleMenuItemClick("Night Audit Configuration", 3)
              }
            >
              <ListItemIcon
                style={{
                  color: `${
                    selectedItem === "Night Audit Configuration"
                      ? "#1BC5BD"
                      : "#757575"
                  }`,
                }}
              >
                <PaymentsSharpIcon />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: `${
                    selectedItem === "Night Audit Configuration"
                      ? "#1BC5BD"
                      : "#757575"
                  }`,
                }}
                primary={"Night Audit Configuration"}
              />
            </ListItem>
            {/* <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Configuration" />
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding> */}
            {/* {JSON.parse(accessControl?.ExpenseEConfig).read === "YES" && ( */}
            {/* <ListItem
                  button
                  style={{ marginLeft: "1rem" }}
                  onClick={() =>
                    handleMenuItemClick("Payment configuration", 1)
                  }
                >
                  <ListItemIcon
                    style={{
                      color: `${
                        selectedItem === "Payment configuration"
                          ? "#1BC5BD"
                          : "#757575"
                      }`,
                    }}
                  >
                    <PaymentsSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Payment configuration"} />
                </ListItem> */}
            {/* )} */}
            {/* {JSON.parse(accessControl?.ExpenseWConfig).read === "YES" && (
                  <ListItem
                    button
                    style={{ marginLeft: "1rem" }}
                    // selected={selectedItem === "Introduction"}

                    onClick={() =>
                      handleMenuItemClick("Configure withdrawal", 5)
                    }
                  >
                    <ListItemIcon
                      style={{
                        color: `${
                          selectedItem === "Configure withdrawal"
                            ? "#1BC5BD"
                            : "#757575"
                        }`,
                      }}
                    >
                      <SellIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Configure withdrawal"} />
                  </ListItem>
                )}
                {JSON.parse(accessControl?.ExpenseTConfig).read === "YES" && (
                  <ListItem
                    button
                    style={{ marginLeft: "1rem" }}
                    // selected={selectedItem === "Introduction"}

                    onClick={() => handleMenuItemClick("Configure topup", 6)}
                  >
                    <ListItemIcon
                      style={{
                        color: `${
                          selectedItem === "Configure topup"
                            ? "#1BC5BD"
                            : "#757575"
                        }`,
                      }}
                    >
                      <PaidIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Configure topup"} />
                  </ListItem>
                )}
                {JSON.parse(accessControl?.ExpenseEmpConfig).read === "YES" && (
                  <ListItem
                    button
                    // selected={selectedItem === "Introduction"}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => handleMenuItemClick("Configure employee", 7)}
                  >
                    <ListItemIcon
                      style={{
                        color: `${
                          selectedItem === "Configure employee"
                            ? "#1BC5BD"
                            : "#757575"
                        }`,
                      }}
                    >
                      <AccessibilityIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Configure employee"} />
                  </ListItem>
                )} */}
            {/* </List>
            </Collapse>*/}
          </List>
          {/* </div> */}
        </Grid>
        {/*creating box to hold content */}
        <Grid style={{ width: "80%" }}>
          {mykeys === 1 && <RunNightAudit />}
          {mykeys === 2 && <NightAuditHistory />}
          {mykeys === 3 && <NightAuditConfiguration />}
        </Grid>
        <PaymentModal
          showPaymentModal={showPaymentModal}
          paymentAmount={paymentAmount}
          closePaymentModal={closePaymentModal}
          days={daysDiff}
          finalDate={finalDate}
        />
      </Grid>
    )
  );
}
