import React, { Fragment, useState, useEffect } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import {
  readableTimeConverter,
  validateAddress,
  handleFullAddString,
} from "../../Utils/Utils";
import { getMapForTheRatePlans } from "./FolioUtils";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

const borderColor = "#E5e5e5";

const InvoiceDualText = ({
  boldText,
  normalText,
  color = "black",
  contentSize,
}) => {
  return (
    <View style={styles.dualText}>
      <Text style={[styles.boldText, { color: color, fontSize: contentSize }]}>
        {boldText}:
      </Text>
      <Text
        style={[styles.normalText, { color: color, fontSize: contentSize }]}
      >
        {normalText}
      </Text>
    </View>
  );
};

// Handling customer address
const handleCustomerAddress = (address) => {
  let formattedAddress = "";

  Object.values(address).map(function (add) {
    if (validateAddress(add)) {
      formattedAddress += add + ", ";
    }
  });

  const lastCommaIndex = formattedAddress.lastIndexOf(", ");
  const newFormattedAddress = formattedAddress.substring(0, lastCommaIndex);
  return newFormattedAddress;
};

// Handling the date format
const formatDate = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

// handle invoice header
const handleInvoiceHeader = (customDetails) => {
  if (
    customDetails?.booking_invoice_header &&
    !customDetails?.booking_invoice_header.toLowerCase().includes("null", "n/a")
  ) {
    return customDetails?.booking_invoice_header;
  } else {
    return "Folio";
  }
};

// Check if the data is not null or includes n/a
const checkInvoiceData = (data) => {
  if (data === null) {
    return "";
  } else if (String(data).toLowerCase().startsWith("null")) {
    return "";
  } else if (String(data).toLowerCase().startsWith("n/a")) {
    return "";
  } else {
    return String(data);
  }
};

const handleCustomBookingId = (invoice, customDetails) => {
  if (invoice?.status === "CHECKED_OUT") {
    // return (
    //   String(checkInvoiceData(customDetails.cust_booking_invoice_prefix)) +
    //   String(checkInvoiceData(customDetails?.cust_booking_seq_start)) +
    //   String(checkInvoiceData(customDetails?.cust_booking_invoice_suffix))
    // );
    return String(invoice?.custom_invoice_id);
  } else {
    return "";
  }
};

function PrintVehicleResCard({
  allCompanyDetails,
  invoice,
  hotelData,
  symbol,
  customDetails,
  hotelLogo,
  summaryData,
  hotelType,
  ctaDetails,
  selectedLanguage,
}) {
  const contentSize = customDetails?.res_card_content_size;
  const [headerContentSize, setHeaderContentSize] = useState("12px");
  const [footerContentSize, setFooterContentSize] = useState("8px");
  const [hideOtaPrepaidRates, setHideOtaPrepaidRates] = useState(false);

  const { t } = useTranslation();

  function checkWhitespace(str) {
    return /\s/.test(str);
  }

  const extractNames = (fullName) => {
    // debugger;
    fullName = fullName.trim();
    //Remove more than one space between words
    fullName = fullName.replace(/^\s+|\s+$/g, "");
    let lastName = "";
    let firstName = "";
    if (!checkWhitespace(fullName)) {
      //If name has only 1 word
      firstName = fullName;
    } else {
      const words = fullName.trim().split(" ");
      lastName = words.pop();
      firstName = words.join(" ");
    }
    return { firstName, lastName };
  };

  const { firstName, lastName } = extractNames(invoice?.customer_name);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const CustomerInfoTextView = ({ text }) => {
    return (
      <View style={styles.customerInfoTextViewWrapper}>
        <Text style={[styles.customerInfoText, { fontSize: contentSize }]}>
          {text}
        </Text>
      </View>
    );
  };

  const getHeaderAndFooterSize = () => {
    const res_card_content_size = customDetails?.res_card_content_size;
    console.log("this is the res card content size : ", res_card_content_size);
    const arr = res_card_content_size.split("p");
    const pixelVal = Number(arr[0]);
    const headerVal = pixelVal + 2;
    const formattedHeaderVal = `${headerVal}px`;
    const formattedFooterVal = `8px`;
    setHeaderContentSize(formattedHeaderVal);
    setFooterContentSize(formattedFooterVal);
  };

  useEffect(() => {
    if (customDetails) {
      getHeaderAndFooterSize();
    }
  }, [customDetails]);

  const InlineDivider = () => {
    return <View style={styles.divider2}></View>;
  };

  const RowDivider = () => {
    return <View style={styles.divider3}></View>;
  };

  const HiddenDivider = () => {
    return <View style={styles.hiddenDivider}></View>;
  };

  const ReservationGuestInfo = ({ symbol, invoice, company, hotelType }) => {
    const validRooms = invoice?.reservation_room_info_list?.filter(
      (room) => !invoice?.cancelled_room_ids_list.includes(room.roomId)
    );
    const ratePlansArr = Array.from(
      new Set(validRooms?.map((room) => room.ratePlanName))
    );

    const ratePlans = ratePlansArr?.toString();

    const handleStringValues = (val) => {
      if (["NA", "N/A", "null", null].includes(val)) {
        return "";
      } else {
        return val;
      }
    };

    const getPaymentModes = () => {
      let paymentModes = [];
      if (summaryData?.cash_payment > 0) {
        paymentModes.push("Cash");
      }
      if (summaryData?.bank_transfer_payment > 0) {
        paymentModes.push("Bank transfer");
      }
      if (summaryData?.offline_card_payment > 0) {
        paymentModes.push("Offline card");
      }
      if (summaryData?.offline_check_payment > 0) {
        paymentModes.push("Offline check");
      }
      if (summaryData?.other_payment > 0) {
        paymentModes.push("Other modes");
      }
      if (summaryData?.upi_payment > 0) {
        paymentModes.push("UPI");
      }
      if (summaryData?.total_online_payment > 0) {
        paymentModes.push("Payment gateway");
      }

      return paymentModes.join(",");
    };

    const onHideOtaPrepaidRates = () => {
      if (
        customDetails?.hide_prepaid_ota_res_card_room_charges === "YES" &&
        !invoice?.booking_source?.startsWith("STAYFLEXI")
      ) {
        setHideOtaPrepaidRates(true);
      } else {
        setHideOtaPrepaidRates(false);
      }
    };

    useEffect(() => {
      onHideOtaPrepaidRates();
    }, []);

    return (
      <View style={[styles.guestInfoContainer, { fontSize: "18px" }]}>
        <View style={styles.guestDetailsWrapper}>
          <View style={styles.guestDetailView1}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"Name"} />
              {/* <HiddenDivider />
              <CustomerInfoTextView text={" "} /> */}
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView
                text={invoice?.customer_name}
                // text={" "}
              />
              {/* <InlineDivider />
              <CustomerInfoTextView
                // text={invoice?.customer_name?.split(" ")[1]}
                text={" "}
              /> */}
            </View>
          </View>
          <View style={styles.guestDetailView2}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"# of guest"} />
              {/* <HiddenDivider />
              <CustomerInfoTextView text={" "} /> */}
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView
                text={
                  <CustomerInfoTextView
                    text={`Adult(s): ${invoice?.reservation_room_info_list
                      ?.filter(
                        (room) =>
                          !invoice?.cancelled_room_ids_list.includes(
                            room.roomId
                          )
                      )
                      .reduce((acc, curr) => {
                        return acc + curr.adults;
                      }, 0)}  Child(ren): ${invoice?.reservation_room_info_list
                      ?.filter(
                        (room) =>
                          !invoice?.cancelled_room_ids_list.includes(
                            room.roomId
                          )
                      )
                      .reduce((acc, curr) => {
                        return acc + curr.children;
                      }, 0)}`}
                  />
                }
              />
              {/* <InlineDivider />
              <CustomerInfoTextView text={" "} /> */}
            </View>
          </View>
        </View>
        <RowDivider />
        <View style={styles.guestDetailsWrapper}>
          <View style={styles.guestDetailView1}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"Address"} />
              <HiddenDivider />
              <CustomerInfoTextView text={" "} />
              <HiddenDivider />
              <CustomerInfoTextView text={" "} />
              <HiddenDivider />
              <CustomerInfoTextView text={" "} />
              <HiddenDivider />
              <CustomerInfoTextView text={" "} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Email Id"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Phone No."} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Birth date"} />
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView
                text={handleStringValues(
                  invoice?.customer_address?.customer_address_line1
                )}
              />
              <InlineDivider />
              <CustomerInfoTextView
                text={handleStringValues(
                  invoice?.customer_address?.customer_address_line2
                )}
              />
              <InlineDivider />
              <CustomerInfoTextView
                text={handleStringValues(
                  invoice?.customer_address?.customer_address_city
                )}
              />
              <InlineDivider />
              <CustomerInfoTextView
                text={handleStringValues(
                  invoice?.customer_address?.customer_address_country
                )}
              />
              <InlineDivider />
              <CustomerInfoTextView
                text={handleStringValues(
                  invoice?.customer_address?.customer_address_zip
                )}
              />
              <InlineDivider />
              <CustomerInfoTextView text={invoice?.customer_email} />
              <InlineDivider />
              <CustomerInfoTextView text={invoice?.customer_phone} />
              <InlineDivider />
              <CustomerInfoTextView
                text={
                  moment(invoice?.customer_dob).format("MMM DD, YYYY") ===
                  "Invalid date"
                    ? ""
                    : moment(invoice?.customer_dob).format("MMM DD, YYYY")
                }
              />
            </View>
          </View>
          <View style={styles.guestDetailView2}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"Rate type"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Rate"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Other charges"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Total charges"} />
              <RowDivider />
              <CustomerInfoTextView text={"Payment type"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Credit card info"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Total payments"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Total deposits"} />
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView text={ratePlans} />
              <InlineDivider />
              <CustomerInfoTextView
                text={
                  hideOtaPrepaidRates
                    ? "-"
                    : symbol + " " + summaryData?.total_room_amount?.toFixed(2)
                }
              />
              <InlineDivider />
              <CustomerInfoTextView
                text={
                  hideOtaPrepaidRates
                    ? "-"
                    : symbol +
                      " " +
                      Object.values(summaryData?.fee_breakdown)
                        .reduce(
                          (a, b) => a + b,
                          summaryData?.total_services_amount
                        )
                        ?.toFixed(2)
                }
              />
              <InlineDivider />
              <CustomerInfoTextView
                text={
                  hideOtaPrepaidRates
                    ? "-"
                    : symbol +
                      " " +
                      summaryData?.total_amount_with_services?.toFixed(2)
                }
              />
              <RowDivider />
              <CustomerInfoTextView text={getPaymentModes()} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView
                text={
                  hideOtaPrepaidRates
                    ? "-"
                    : summaryData?.payment_made > 0
                    ? symbol + " " + summaryData?.payment_made?.toFixed(2)
                    : ""
                }
              />
              <InlineDivider />
              <CustomerInfoTextView
                text={
                  summaryData?.deposit_amount > 0
                    ? symbol + " " + summaryData?.deposit_amount?.toFixed(2)
                    : ""
                }
              />
            </View>
          </View>
        </View>
        <RowDivider />
        <View
          style={{
            position: "relative",
            display: "flex",
            width: "100%",
            flexDirection: "row",
            paddingTop: "1px",
            paddingBottom: "1px",
          }}
        >
          <View
            style={{ position: "relative", width: "50%", textAlign: "center" }}
          >
            <Text style={{ fontSize: contentSize }}>ID information</Text>
          </View>
          <View
            style={{ position: "relative", width: "50%", textAlign: "center" }}
          >
            <Text style={{ fontSize: contentSize }}>Vehicle information</Text>
          </View>
        </View>
        <RowDivider />

        <View style={styles.guestDetailsWrapper}>
          <View style={styles.guestDetailView1}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"ID type"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"ID #"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"ID valid"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"ID country"} />
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView
                text={
                  invoice?.govt_id_type === "NA" ? "" : invoice?.govt_id_type
                }
              />
              <InlineDivider />
              <CustomerInfoTextView text={invoice?.govt_id_value} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
            </View>
          </View>
          <View style={styles.guestDetailView2}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"Vehicle make"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Model / Year"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"License plate #"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"State"} />
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView text={" "} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
            </View>
          </View>
        </View>
        <RowDivider />
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "5px",
          }}
        >
          <View style={{ display: "flex", position: "relative" }}>
            <Text style={{ fontSize: contentSize }}>Special requests :</Text>
          </View>
          <View style={{ marginLeft: "10px" }}>
            <Text style={{ fontSize: contentSize }}>
              {invoice?.special_requests?.substring(0, 80)}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const InvoiceRoomHeader = ({ symbol, hotelType, HotelEmail }) => (
    <View style={styles.container}>
      <Text
        style={{ width: "33.33%", fontSize: contentSize, marginTop: "5px" }}
      >
        {t("Room type")}
      </Text>

      <Text
        style={{ width: "33.33%", fontSize: contentSize, marginTop: "5px" }}
      >
        {t("Room Ids.")}
      </Text>

      <Text
        style={{ width: "33.33%", fontSize: contentSize, marginTop: "5px" }}
      >
        {t("Rate plan")}
      </Text>

      {hotelType?.toUpperCase() !== "ISHA" &&
        (HotelEmail === "df@stayflexi.com" ||
          HotelEmail === "bookings@revenuefirst.in") && (
          <Text
            style={{ width: "33.33%", fontSize: contentSize, marginTop: "5px" }}
          >
            {t("Room rate")} ({symbol})
          </Text>
        )}
      <Text
        style={{ width: "33.33%", fontSize: contentSize, marginTop: "5px" }}
      >
        {t("Room occupancy")}
      </Text>
      {/* <Text style={{ width: "25%", fontSize: contentSize, marginTop: "5px" }}>
        Room rate
      </Text> */}
    </View>
  );
  const HotelEmail = localStorage.getItem("HotelEmail");

  const [company, setCompany] = useState([]);
  useEffect(() => {
    if (allCompanyDetails.length > 0) {
      const data = allCompanyDetails.filter(
        (elm) => elm.gst_number === invoice?.company_gst_number
      );

      setCompany(data);
    }
  }, [allCompanyDetails]);
  // Display cancellation policy
  function showNewCancellationPolicy() {
    let ifShowNewCancellationPolicy = true;

    if (invoice?.hasOwnProperty("cancellationPolicies")) {
      for (let policy of invoice?.cancellationPolicies) {
        const policyArr = policy.split(":");
        const policyDesc = policyArr[1].trim();
        if (policyDesc.length === 0) {
          ifShowNewCancellationPolicy = false;
          break;
        }
      }
    } else {
      ifShowNewCancellationPolicy = false;
    }
    return ifShowNewCancellationPolicy;
  }

  // Function to generate the default cancellation policies
  function handleGenerateCancellationPolicies(invoice, customDetails) {
    return (
      <View>
        <Text
          style={{
            fontSize: footerContentSize,
            marginBottom: 5,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_before}%{" "}
          {t("of total booking value when cancelled before")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_within}% {t("if cancelled within")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
      </View>
    );
  }

  return (
    <View
      style={[
        styles.section,
        {
          marginTop: 0,
          paddingTop: String(symbol)?.includes("Rs") ? "45px" : "9px",
        },
      ]}
    >
      <View
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {customDetails &&
          customDetails?.show_logo.toLowerCase() === "yes" &&
          getExtension(hotelLogo) && (
            <View
              style={{
                position: "relative",
                alignSelf: "center",
                width: customDetails?.logo_size,
                marginBottom: "5px",
                paddingRight: "25px",
              }}
            >
              <Image
                style={[styles.image, { width: customDetails?.logo_size }]}
                src={getExtension(hotelLogo)}
              />
            </View>
          )}
        <View
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            width: "70%",
            flexWrap: "wrap",
          }}
        >
          <Text style={{ fontSize: headerContentSize }}>
            {hotelData?.hotel_name}
          </Text>
          <Text style={{ fontSize: contentSize, marginTop: "5px" }}>
            {handleFullAddString(hotelData?.hotel_address)}
          </Text>
          <View style={[styles.dualText, { marginTop: "5px" }]}>
            <Text style={[styles.boldText, { fontSize: contentSize }]}>
              {t("Phone :")}
            </Text>
            <Text style={[styles.boldText, { fontSize: contentSize }]}>
              {customDetails &&
              customDetails?.invoice_contact_phone &&
              !customDetails?.invoice_contact_phone
                .toLowerCase()
                .includes("null", "n/a")
                ? customDetails?.invoice_contact_phone
                : hotelData?.hotel_contact}
            </Text>
          </View>
          {hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.dualText}>
              <Text style={[styles.boldText, { fontSize: contentSize }]}>
                {t("Email :")}{" "}
              </Text>
              <Text style={[styles.boldText, { fontSize: contentSize }]}>
                {customDetails &&
                customDetails?.invoice_contact_email &&
                !customDetails?.invoice_contact_email
                  .toLowerCase()
                  .includes("null", "n/a")
                  ? customDetails?.invoice_contact_email
                  : hotelData?.hotel_email}
              </Text>
            </View>
          )}
        </View>
      </View>

      <View style={styles.dividerWrapper}>
        <View style={styles.divider}></View>
      </View>

      <View
        style={{
          position: "relative",
          display: "flex",
        }}
      >
        <View
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Text style={{ fontSize: contentSize }}>Registration form</Text>
        </View>
        <View
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "1px",
          }}
        >
          <View>
            {customDetails?.hide_stayflexi_booking_id.toLowerCase() ===
              "no" && (
              <Text
                style={{
                  fontSize: contentSize,
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >{`${handleInvoiceHeader(customDetails)}#${
                invoice?.bookingid
              }`}</Text>
            )}
            {customDetails && Number(summaryData?.payment_made) > 0 ? (
              <Text
                style={{
                  fontSize: contentSize,
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >{`${handleInvoiceHeader(customDetails)}#${handleCustomBookingId(
                invoice,
                customDetails
              )}`}</Text>
            ) : (
              <Text></Text>
            )}
            <InvoiceDualText
              boldText={t("Nights")}
              normalText={invoice?.num_nights}
              contentSize={contentSize}
            />
            <InvoiceDualText
              boldText={t("Source")}
              normalText={invoice?.booking_source_displayname}
              contentSize={contentSize}
            />
          </View>
          <View>
            <InvoiceDualText
              boldText={t("Check-in")}
              normalText={formatDate(invoice?.checkin)}
              contentSize={contentSize}
            />
            <InvoiceDualText
              boldText={t("Check-out")}
              normalText={formatDate(invoice?.checkout)}
              contentSize={contentSize}
            />
            {customDetails?.show_actual_cin_cout === "YES" && (
              <>
                <InvoiceDualText
                  boldText={t("Actual Check-in")}
                  normalText={formatDate(invoice?.actual_checkin)}
                  contentSize={contentSize}
                />
                <InvoiceDualText
                  boldText={t("Actual Check-out")}
                  normalText={formatDate(invoice?.actual_checkout)}
                  contentSize={contentSize}
                />
              </>
            )}
            <InvoiceDualText
              boldText={t("Room no.")}
              normalText={
                invoice.hasOwnProperty("confirmed_room_ids_list")
                  ? invoice?.confirmed_room_ids_list.join(",")
                  : invoice?.roomids
              }
              contentSize={contentSize}
            />
          </View>
        </View>
      </View>
      <View style={{ marginTop: "2px" }}>
        {invoice?.items && (
          <ReservationGuestInfo
            symbol={symbol}
            invoice={invoice}
            company={company}
            hotelType={hotelType}
          />
        )}
      </View>

      {/* Displaying Cancellation Policies */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_res_cancellation_policy === "YES" && (
          <View style={{ marginTop: "3px" }}>
            <Text
              style={{
                fontSize: footerContentSize,
                marginTop: 7.5,
                marginBottom: 7.5,
                textDecoration: "underline",
              }}
            >
              {t("Cancellation Policies")}
            </Text>
            {invoice.hasOwnProperty("cancellationPolicies") ? (
              invoice?.cancellationPolicies?.length > 0 &&
              showNewCancellationPolicy() ? (
                <View>
                  {invoice?.cancellationPolicies
                    ?.map((policies) => policies.replace(":", "\n"))
                    .map((policy) => (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 3,
                          flexWrap: "wrap",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: footerContentSize,
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {policy}
                        </Text>
                      </View>
                    ))}
                </View>
              ) : (
                handleGenerateCancellationPolicies(invoice, customDetails)
              )
            ) : (
              handleGenerateCancellationPolicies(invoice, customDetails)
            )}
          </View>
        )}

      {/* Displaying Terms and Conditions */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_res_terms_and_condition.toLowerCase() === "yes" &&
        (localStorage?.getItem("tnc")?.trim()?.length > 0 ||
          localStorage.getItem("hotel_tnc").length > 0) && (
          <View style={{ marginTop: "3px" }}>
            <Text
              style={{
                fontSize: footerContentSize,
                marginBottom: 5,
                textDecoration: "underline",
              }}
            >
              {t("Terms and Conditions")}
            </Text>
            <Text style={{ fontSize: footerContentSize }}>
              {/* {hotelData?.hotel_terms_conditions} */}
              {localStorage
                ?.getItem("hotel_tnc")
                ?.replace(/<br\s*\/?>/gi, "\n") ||
                localStorage?.getItem("tnc")?.replace(/<br\s*\/?>/gi, "\n")}
            </Text>
          </View>
        )}

      {/* Displaying guest and hotel signature */}
      {customDetails?.show_signature && (
        <View style={styles.signature}>
          <View style={styles.signatureData}>
            <Text
              style={{
                fontSize: footerContentSize,
                marginBottom: 3,
                marginTop: "auto",
              }}
            >
              {t("Guest signature :")}
            </Text>
            {Object.keys(customDetails).length > 0 &&
            invoice?.customer_signature_base64.length > 0 ? (
              <Image
                style={[styles.parentImage, { marginLeft: 5 }]}
                src={getExtension(invoice.customer_signature_base64)}
              />
            ) : (
              <Text style={{ marginLeft: 5 }}> </Text>
            )}
          </View>
          <View style={styles.signatureData}>
            {symbol === "$" ? (
              <Text style={{ fontSize: footerContentSize, marginBottom: 5 }}>
                {t("Guest Name :")}
              </Text>
            ) : (
              <Text style={{ fontSize: footerContentSize, marginBottom: 5 }}>
                {t("Hotel signature :")}
              </Text>
            )}
            <Text style={{ marginLeft: 5 }}> </Text>
          </View>
        </View>
      )}
    </View>
  );
}

export default PrintVehicleResCard;

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    width: "45px",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain cover",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    position: "relative",
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "#222",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "left",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    textAlign: "center",
    borderRightWidth: 1,
  },
  amount: {
    textAlign: "center",
    width: "15%",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 4,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    padding: "8px",
    inlineSize: "auto",
    fontStyle: "bold",
    justifyContent: "space-between",
  },
  resCardWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: "10px",
  },
  resCardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flexStart",
    fontSize: "10px",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: "4.5px",
    height: 30,
    inlineSize: "auto",
  },
  resCardLeft: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginTop: "auto",
    marginBottom: "auto",
  },
  resCardHeader: {
    fontSize: "10px",
    fontWeight: "500",
    width: "40%",
    flexWrap: "wrap",
    display: "flex",
  },
  signature: {
    marginTop: 25,
    // marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
  },
  dividerWrapper: {
    position: "relative",
    marginBottom: "5px",
  },
  divider: {
    position: "absolute",
    left: 0,
    right: 0,
    borderBottom: "1px solid black",
  },
  guestInfoContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    border: "1px sold black",
  },
  guestDetailsWrapper: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  guestDetailView1: {
    position: "relative",
    width: "50%",
    borderRight: "1px solid black",
    display: "flex",
    flexDirection: "row",
  },
  guestDetailView2: {
    position: "relative",
    width: "50%",
    display: "flex",
    flexDirection: "row",
  },
  divider2: {
    position: "relative",
    width: "100%",
    marginTop: "1.5px",
    marginBottom: "0.5px",
    borderBottom: "1px solid #e4e4e4",
  },
  hiddenDivider: {
    position: "relative",
    width: "100%",
    marginTop: "1.5px",
    marginBottom: "0.5px",
    borderBottom: "1px solid white",
  },
  keyWrapper: {
    position: "relative",
    width: "30%",
    borderRight: "1px solid black",
    display: "flex",
  },
  valueWrapper: {
    position: "relative",
    width: "70%",
  },
  divider3: {
    position: "relative",
    width: "100%",
    borderBottom: "1px solid black",
  },
  customerInfoTextViewWrapper: {
    position: "relative",
    height: "13px",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    padding: "1px",
    paddingLeft: "2px",
  },
  customerInfoText: {},
});
