// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Fab,
  Fade,
  FormHelperText,
  Grid,
  Grow,
  InputLabel,
  Paper,
  Popper,
  TextField,
  Typography,
  CircularProgress,
  Switch,
} from "@mui/material";
import { Card, Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  blockMultipleRooms,
  blockMultipleRoomsWithHotelId,
  deleteMultipleRooms,
  deleteMultipleRoomsWithHotelId,
  getAvailableRooms,
  getAvailableRoomsWithHotelId,
  getPhysicalRoomIds,
  getPhysicalRoomIdsWithHotelId,
  getRemovedRooms,
  getRemovedRoomsWithHotelId,
  makeRoomsAvailable,
  makeRoomsAvailableWithHotelId,
  makeRoomsUnavailable,
  makeRoomsUnAvailableWithHotelId,
  reAssignRoom,
  reAssignRoomWithHotelId,
  restoreDeleted,
  restoreDeletedWithHotelId,
  unBlockMultipleRooms,
  unBlockMultipleRoomWithHotelId,
} from "../api/InventoryApi";
import { weekdays } from "../constants/InventoryConstants";
import {
  customErrorMessage,
  dateFormat,
} from "../../CalendarPage/constants/Calendar-constants";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useFormik } from "formik";
import * as yup from "yup";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { ENV } from "../../CalendarPage/constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import { handleSpringDataRequest } from "../../../api";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  textDisplay: {
    fontSize: "12px",
    marginRight: 6,
  },
  subText: {
    fontSize: "14px",
  },
}));

const GroupSetOnlineView = (props) => {
  const { componentType, refresh } = props;
  const {
    data,
    selectedRoom,
    rooms,
    setLineInvisible,
    setLineVisible,
    closeFunc,
    selectedLanguage,
    hotelId,
    hoteldata,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const DropdownOptions = [
    { text: `${t("Set Pricing")}`, val: "set-price" },
    { text: `${t("Set Inventory")}`, val: "set-inventory" },
    { text: `${t("Rate Multiplier")}`, val: "rate-multiplier" },
    { text: `${t("Inventory Capping")}`, val: "inventory-cap" },
    { text: `${t("Set Online")}`, val: "set-online" },
    { text: `${t("Set Offline")}`, val: "set-offline" },
    { text: `${t("Block Rooms")}`, val: "block-room" },
    { text: `${t("Unblock Rooms")}`, val: "unblock-room" },
    { text: `${t("Delete Rooms")}`, val: "delete-room" },
    { text: `${t("Restore Deleted Rooms")}`, val: "restore" },
    { text: `${t("Update Rates from OTA")}`, val: "update-ota" },
    { text: `${t("Set Sold Out")}`, val: "sold-out" },
    { text: `${t("Edit Room Ids")}`, val: "edit-room" },
    { text: `${t("Reassign Room Type")}`, val: "reassign" },
    { text: `${t("Close/Open Rate Plan")}`, val: "Close Rate" },
    { text: `${t("Close/Open Room Type")}`, val: "Close Room" },
    { text: `${t("Close/Open On Arrival")}`, val: "Close On Arrival" },
    { text: `${t("Close/Open On Departure")}`, val: "Close On Departure" },
    { text: `${t("Min Length of Stay")}`, val: "Set Min Los" },
    { text: `${t("Max Length of Stay")}`, val: "Set Max Los" },
    { text: `${t("Min Advanced Booking Offset")}`, val: "Set Cutoff" },
    { text: `${t("Max Advanced Booking Offset")}`, val: "Set Max Adv Offset" },
  ];

  const [checked, setchecked] = useState(true);
  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(
        new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
    },
  ]);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectDays = weekdays;
  const [loading, setLoading] = useState(true);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectRoom, setSelectRoom] = useState({});
  const [roomSelected, setRoomsSelected] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [reason, setReason] = useState("");
  const [otherRoomTypes, setOtherRoomTypes] = useState({});
  const [otherRooms, setOtherRooms] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState("Inventory");
  const [filterLogData, setfilterLogData] = useState([]);
  const [show, setShow] = useState(false);
  const [cmData, setcmData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setloader] = useState(false);

  const validationSchema =
    componentType === "delete-room" ||
    componentType === "restore" ||
    componentType === "reassign"
      ? yup.object({
          roomSelected: yup.array().required(`${t("Select atleast one room")}`),
        })
      : yup.object({
          currentDateSelection: yup
            .array()
            .required(
              `${t("Select a range and click on apply for atleast one range")}`
            ),
          roomSelected:
            componentType !== "block-room" &&
            componentType !== "set-online" &&
            componentType !== "set-offline"
              ? yup.array().required(`${t("Select atleast one room")}`)
              : null,
        });

  const formik = useFormik({
    initialValues:
      componentType === "delete-room" ||
      componentType === "restore" ||
      componentType === "reassign"
        ? { roomSelected: [] }
        : { currentDateSelection: [], roomSelected: [] },
    validationSchema: validationSchema,
    onSubmit: () => {
      setShowConfirm(true);
    },
  });

  const getOtherRoomTypes = () => {
    let arr = [];
    let temporary = Object.assign(rooms);
    Object.keys(temporary).forEach((val) => {
      if (val !== selectedRoom) {
        arr.push({ value: val, label: temporary[val] });
      }
    });
    setOtherRooms(arr);
    if (arr.length !== 0) {
      setOtherRoomTypes(arr[0]);
    }
  };

  const reset = () => {
    setCurrentDateSelection([
      {
        startDate: new Date(new Date().setHours(0, 0, 0, 0)),
        endDate: new Date(
          new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(
            0,
            0,
            0,
            0
          )
        ),
      },
    ]);
    setCurrentSelection([]);
    Object.keys(selectRoom).forEach((val, index) => {
      selectRoom[index] = { value: val.value, isSelected: false };
    });
    setSelectRoom({ ...selectRoom });
    setReason("");
  };

  useEffect(() => {
    getApiData();
  }, [selectedRoom]);

  const setOnlineFunc = async () => {
    let temp = [...currentSelection];
    temp.forEach((val) => {
      val.physicalRoomIds = roomSelected;
    });
    await makeRoomsAvailableWithHotelId(temp, hotelId).then(() => {
      toast.success(`${t("Successfully updated online inventory")}`);
    });
    // getAllDatas();
    closeFunc();
  };

  const setUnavailableFunc = async () => {
    let temp = [...currentSelection];
    temp.forEach((val) => {
      val.physicalRoomIds = roomSelected;
    });
    await makeRoomsUnAvailableWithHotelId(temp, hotelId).then(() => {
      toast.success(`${t("Successfully updated offline inventory")}`);
    });
    // getAllDatas();
    closeFunc();
  };

  const setBlockFunc = async () => {
    let temp = [...currentSelection];
    temp.forEach((val) => {
      val.physicalRoomIds = roomSelected;
      val.reason = reason;
    });
    try {
      await blockMultipleRoomsWithHotelId(temp, hotelId).then(() => {
        toast.success(`${t("Successfully blocked the selected inventory")}`);
      });
      // getAllDatas();
      closeFunc();
    } catch (e) {
      toast.error(
        `${t(
          "Selected room could not be blocked since it has future reservations."
        )}`
      );
      console.log(e);
    }
  };

  const setUnblockFunc = async () => {
    let temp = [...currentSelection];
    temp.forEach((val) => {
      val.physicalRoomIds = roomSelected;
      val.reason = reason;
    });
    await unBlockMultipleRoomWithHotelId(temp, hotelId).then(() => {
      toast.success(`${t("Successfully unblocked the selected inventory")}`);
    });
    closeFunc();
    // getAllDatas();
  };

  const deleteFunc = async () => {
    let temp = {
      physicalRoomIds: roomSelected,
      roomType: selectedRoom === "all-rooms" ? "all" : rooms[selectedRoom],
    };
    await deleteMultipleRoomsWithHotelId(temp, hotelId).then(() => {
      toast.success(`${t("Successfully deleted the selected inventory")}`);
    });
    setAvailableRooms(
      availableRooms.filter((val) => !roomSelected.includes(val))
    );
    closeFunc();
    // getAllDatas();
  };

  const restoreFunc = async () => {
    let temp = {
      physicalRoomIds: roomSelected,
      roomTypeName: selectedRoom === "all-rooms" ? "all" : rooms[selectedRoom],
    };
    await restoreDeletedWithHotelId(temp, hotelId).then(() => {
      toast.success(`${t("Successfully restored the selected inventory")}`);
    });
    setAvailableRooms(
      availableRooms.filter((val) => !roomSelected.includes(val))
    );
    closeFunc();
    // getAllDatas();
  };

  const reassignFunc = async () => {
    let obj = {
      physicalRoomId: roomSelected[0],
      roomType: selectedRoom,
      newRoomType: otherRoomTypes.value,
      isId: true,
      hotelId: hotelId,
    };
    await reAssignRoomWithHotelId(obj).then(() => {
      toast.success(`${t("Successfully updated the room types")}`);
    });
    setAvailableRooms(
      availableRooms.filter((val) => !roomSelected.includes(val))
    );
    closeFunc();
    // getAllDatas();
  };

  useEffect(() => {
    if (selectedRoom === "all-rooms") {
      setchecked(true);
    } else {
      setchecked(false);
    }
  }, [selectedRoom]);

  const getDisplayData = () => {
    switch (componentType) {
      case "set-online":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Ids")} :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Date Ranges")} :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "set-offline":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Ids")} :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Date Ranges")} :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "block-room":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Ids")} :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {" "}
                {t("Reason")} :
              </Typography>
              <Typography className={classes.subText}>{reason}</Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Date Ranges")} :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "unblock-room":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Ids")} :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Reason")} :
              </Typography>
              <Typography className={classes.subText}>{reason}</Typography>
            </Grid>
            <Grid paddingBottom={1} display={"grid"} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Date Ranges")} :
              </Typography>
              {currentSelection.map((data) => (
                <Typography
                  className={classes.subText}
                  key={data.id}
                  variant="body"
                >
                  {data.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      case "delete-room":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Ids")} :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
          </Grid>
        );
      case "restore":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Ids")} :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
          </Grid>
        );
      case "reassign":
        return (
          <Grid container>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("Room Ids")} :
              </Typography>
              <Typography className={classes.subText}>
                {roomSelected.join(",")}
              </Typography>
            </Grid>
            <Grid display={"flex"} paddingBottom={1} item xs={12}>
              <Typography className={classes.textDisplay}>
                {t("New room type")} :
              </Typography>
              <Typography className={classes.subText}>
                {otherRoomTypes.label}
              </Typography>
            </Grid>
          </Grid>
        );
    }
  };

  const setActionButton = async () => {
    setApiCall(true);
    try {
      switch (componentType) {
        case "set-online":
          await setOnlineFunc().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED SET ONLINE OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "set-offline":
          await setUnavailableFunc().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED SET OFFLINE OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "block-room":
          await setBlockFunc().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED BLOCK ROOM OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "unblock-room":
          await setUnblockFunc().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED UNBLOCK ROOM OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "delete-room":
          await deleteFunc().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED DELETE ROOM OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "restore":
          await restoreFunc().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED RESTORE DELETED ROOM OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
        case "reassign":
          await reassignFunc().then((res) => {
            // handleShow();
            // amplitude
            //   .getInstance()
            //   .logEvent(
            //     "USER PERFORMED REASSIGN ROOM TYPE OPERATION IN BULK OPERATIONS",
            //     amplitudeEventProperties
            //   );
          });
          break;
      }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
    } finally {
      reset();
      setApiCall(false);
      formik.resetForm();
      setShowConfirm(false);
      //   refresh();
    }
  };
  const getApiData = async () => {
    setLineVisible();
    try {
      switch (componentType) {
        case "set-online":
          await getRoomsForOnline();
          break;
        case "set-offline":
          await getRoomsForOnline();
          break;
        case "block-room":
          await getRoomsForOnline();
          break;
        case "unblock-room":
          await getRoomsForOnline();
          break;
        case "delete-room":
          await getRoomsForOnline();
          break;
        case "restore":
          await getRestoreRooms();
          break;
        case "reassign":
          getOtherRoomTypes();
          await getRoomsForOnline();
          break;
      }
      setLineInvisible();
    } catch (e) {
      console.log(e);
      setLineInvisible();
    }
  };

  const getRestoreRooms = async () => {
    setLoading(true);
    const resp = await getRemovedRoomsWithHotelId(hotelId);

    if (resp === "" || resp.length === 0) {
      setAvailableRooms([]);
    }
    if (typeof resp === "number") {
      setAvailableRooms([resp]);
      setSelectRoom({ 0: { value: resp, isSelected: false } });
    }
    if (resp.length > 1) {
      setAvailableRooms(resp.split(","));
      let val = resp.split(",");
      val.forEach((value, index) => {
        val[index] = { value: value, isSelected: false };
      });
      setSelectRoom(val);
    }
    setLoading(false);
  };

  const getRoomsForOnline = async () => {
    setLoading(true);
    let resp = {};
    if (selectedRoom === "all-rooms") {
      resp = await getPhysicalRoomIdsWithHotelId(hotelId);
      setAvailableRooms(resp);
      let val = [...resp];
      val.forEach((value, index) => {
        val[index] = { value: value, isSelected: false };
      });
      setSelectRoom(val);
    } else {
      resp = await getAvailableRoomsWithHotelId(selectedRoom, hotelId);
      setAvailableRooms(resp.toString().split(","));
      let val = resp.toString().split(",");
      val.forEach((value, index) => {
        val[index] = { value: value, isSelected: false };
      });
      setSelectRoom(val);
    }
    setLoading(false);
  };

  const classes = useStyles();
  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const handleChipDelete = (val) => {
    const arr = currentSelection.filter((chip) => chip.id !== val.id);
    formik.setFieldValue("currentDateSelection", arr);
    setCurrentSelection(arr);
  };

  useEffect(() => {
    if (
      (componentType === "block-room" ||
        componentType === "set-online" ||
        componentType === "set-offline") &&
      selectedRoom === "all-rooms"
    ) {
      setRoomsSelected(availableRooms);
      let arr = [];
      const data = availableRooms?.map((item) => {
        let obj = {};
        obj["value"] = item;
        obj["isSelected"] = true;
        arr.push(obj);
      });
      setSelectRoom(arr);
    } else {
      setRoomsSelected([]);
    }
  }, [availableRooms, selectedRoom]);

  const allRoomsChecked = () => {
    if (checked) {
      if (
        componentType === "block-room" ||
        componentType === "set-online" ||
        componentType === "set-offline"
      ) {
        setRoomsSelected(availableRooms);
        let arr = [];
        const data = availableRooms?.map((item) => {
          let obj = {};
          obj["value"] = item;
          obj["isSelected"] = true;
          arr.push(obj);
        });
        setSelectRoom(arr);
      } else {
        setRoomsSelected([]);
      }
    } else {
      if (
        componentType === "block-room" ||
        componentType === "set-online" ||
        componentType === "set-offline"
      ) {
        setRoomsSelected(availableRooms);
        let arr = [];
        const data = availableRooms?.map((item) => {
          let obj = {};
          obj["value"] = item;
          obj["isSelected"] = false;
          arr.push(obj);
        });
        setSelectRoom(arr);
        setRoomsSelected([]);
      } else {
        setRoomsSelected([]);
      }
    }
  };

  useEffect(() => {
    allRoomsChecked();
  }, [checked]);

  return (
    <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
      <form
        style={{ width: "inherit" }}
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Grid container>
          {!loading && (
            <Grid container className={classes.textField} spacing={2}>
              <Grid item xs={12}>
                {(componentType === "block-room" ||
                  componentType === "set-online" ||
                  componentType === "set-offline") && (
                  <div className="d-flex align-item-center">
                    <p
                      className="mb-0 mr-3"
                      style={{ fontWeight: "bold", marginTop: "10px" }}
                    >
                      {t("Enable the toggle to select all rooms")}
                    </p>
                    <Switch
                      checked={checked}
                      onChange={() => setchecked(!checked)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                )}
                <InputLabel>{t("Select RoomID(s)")}</InputLabel>
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                  id="roomSelected"
                  name="roomSelected"
                >
                  {availableRooms.length <= 0 && (
                    <Typography>
                      {" "}
                      {t("There are no rooms for selection")}
                    </Typography>
                  )}
                  {availableRooms.map((val, index) => {
                    return (
                      <Button
                        key={val}
                        variant={
                          selectRoom[index].isSelected
                            ? "contained"
                            : "outlined"
                        }
                        style={{ fontWeight: "bold" }}
                        onClick={() => {
                          selectRoom[index].isSelected =
                            !selectRoom[index].isSelected;
                          if (componentType === "reassign") {
                            Object.keys(selectRoom).forEach((key) => {
                              if (parseInt(key) !== index) {
                                selectRoom[key].isSelected = false;
                              }
                            });
                          }
                          setSelectRoom({ ...selectRoom });
                          if (selectRoom[index].isSelected) {
                            let tempArray = [...roomSelected];
                            if (componentType === "reassign") {
                              tempArray = [];
                            }
                            tempArray.push(selectRoom[index].value);
                            setRoomsSelected(tempArray);
                            formik.setFieldValue("roomSelected", tempArray);
                          } else {
                            let arr = roomSelected.filter(
                              (item) => item != selectRoom[index].value
                            );
                            setRoomsSelected(arr);
                            formik.setFieldValue("roomSelected", arr);
                          }
                        }}
                        color={
                          Boolean(formik.errors.roomSelected)
                            ? "error"
                            : "primary"
                        }
                      >
                        {" "}
                        {val}
                      </Button>
                    );
                  })}
                </Box>
                <FormHelperText
                  error={
                    formik.touched.roomSelected &&
                    Boolean(formik.errors.roomSelected)
                  }
                >
                  {formik.touched.roomSelected && formik.errors.roomSelected}
                </FormHelperText>
              </Grid>

              {componentType !== "delete-room" &&
                componentType !== "restore" &&
                componentType !== "reassign" &&
                componentType !== "edit-room" && (
                  <Grid item xs={12} sx={{ paddingTop: 2 }}>
                    <div>
                      <ClickAwayListener
                        onClickAway={() => {
                          setShowDatePicker(false);
                          setAnchorEl(null);
                        }}
                      >
                        <div>
                          <TextField
                            fullWidth
                            focused={showDatePicker}
                            required
                            error={
                              formik.touched.currentDateSelection &&
                              Boolean(formik.errors.currentDateSelection)
                            }
                            helperText={
                              formik.touched.currentDateSelection &&
                              formik.errors.currentDateSelection
                            }
                            InputProps={{ readOnly: true }}
                            value={getDateText()}
                            label={t("Date range")}
                            onClick={(eve) => {
                              setAnchorEl(eve.currentTarget);
                              setShowDatePicker(true);
                            }}
                          ></TextField>
                          {showDatePicker && (
                            <Popper
                              style={{ zIndex: 2 }}
                              open={showDatePicker}
                              anchorEl={anchorEl}
                              placement="bottom"
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  style={{
                                    transformOrigin: (placement = "bottom-end"),
                                  }}
                                  {...TransitionProps}
                                >
                                  <Paper
                                    elevation={5}
                                    className="inventory-daterange-custom"
                                  >
                                    <DateRange
                                      color="#1BC5BD"
                                      rangeColors={["#1BC5BD"]}
                                      editableDateInputs={false}
                                      minDate={new Date()}
                                      maxDate={
                                        new Date(
                                          new Date().setDate(
                                            new Date().getDate() + 730
                                          )
                                        )
                                      }
                                      moveRangeOnFirstSelection={false}
                                      onChange={(data) => {
                                        setCurrentDateSelection([
                                          { ...data.range1 },
                                        ]);
                                      }}
                                      ranges={currentDateSelection}
                                    ></DateRange>
                                    <Grid
                                      container
                                      item
                                      sx={{
                                        padding: 1,
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        sx={{ marginRight: 1 }}
                                        onClick={() => {
                                          setShowDatePicker(false);
                                        }}
                                        color="error"
                                      >
                                        {t("Cancel")}
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        onClick={() => {
                                          const text = getDateText();
                                          const check =
                                            text + selectDays.join(",");
                                          if (
                                            currentSelection.findIndex(
                                              (val) => val.id === check
                                            ) === -1
                                          ) {
                                            currentSelection.push({
                                              text: getDateText(),
                                              id: check,
                                              datesText: selectDays.join(","),
                                              startDate: moment(
                                                currentDateSelection[0]
                                                  .startDate
                                              ).format(dateFormat),
                                              endDate: moment(
                                                currentDateSelection[0].endDate
                                              ).format(dateFormat),
                                            });
                                            formik.setFieldValue(
                                              "currentDateSelection",
                                              currentSelection
                                            );
                                            setCurrentSelection(
                                              currentSelection
                                            );
                                          }
                                          setShowDatePicker(false);
                                        }}
                                        color="primary"
                                      >
                                        {t("Apply")}
                                      </Button>
                                    </Grid>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          )}
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Grid>
                )}
              {currentSelection.length > 0 && (
                <Fade in={currentSelection.length > 0}>
                  <Grid item xs={12}>
                    <InputLabel>{t("Current Selections")}</InputLabel>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {currentSelection.map((data) => {
                        return (
                          <Chip
                            sx={{
                              width: "auto",
                              height: "auto",
                              fontWeight: "bold",
                            }}
                            variant="outlined"
                            color="primary"
                            onDelete={(eve) => {
                              handleChipDelete(data);
                            }}
                            label={
                              <div>
                                <Typography
                                  sx={{ padding: 0.5, fontWeight: "bold" }}
                                >
                                  {" "}
                                  {data.text}
                                </Typography>
                                <Divider />
                                <Typography
                                  sx={{
                                    padding: 0.5,
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                    fontWeight: "bold",
                                  }}
                                  display="inline-block"
                                >
                                  {" "}
                                  {data.datesText}
                                </Typography>
                              </div>
                            }
                            key={data.id}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                </Fade>
              )}
              {(componentType === "block-room" ||
                componentType === "unblock-room") && (
                <Grid item xs={12}>
                  <TextField
                    value={reason}
                    label={t("Reason")}
                    fullWidth
                    multiline
                    rows={3}
                    onChange={(eve) => {
                      setReason(eve.target.value);
                    }}
                  ></TextField>
                </Grid>
              )}

              {(componentType === "reassign" ||
                componentType === "edit-room") && (
                <Grid item justifyContent="flex-start" container xs={12}>
                  <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Autocomplete
                      fullWidth
                      id="combo-box-demo"
                      value={otherRoomTypes}
                      onChange={(eve, value) => {
                        setOtherRoomTypes(value);
                      }}
                      disableClearable
                      getOptionLabel={(option) => option.label || ""}
                      isOptionEqualToValue={(opt, val) =>
                        opt.value === val.value
                      }
                      options={otherRooms}
                      renderInput={(params) => (
                        <TextField {...params} label={t("New Room Types")} />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {!loading && (
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                className={classes.textField}
                justifyContent="flex-end"
                container
              >
                <Fab
                  type="submit"
                  disabled={apiCall}
                  variant="extended"
                  color="primary"
                >
                  {componentType === "set-online" && `${t("Set Online")}`}
                  {componentType === "set-offline" && `${t("Set Offline")}`}
                  {componentType === "block-room" && `${t("Block Rooms")}`}
                  {componentType === "unblock-room" && `${t("Unblock Rooms")}`}
                  {componentType === "delete-room" && `${t("Delete Rooms")}`}
                  {componentType === "restore" && `${t("Restore Rooms")}`}
                  {componentType === "reassign" && `${t("Reassign Rooms")}`}
                </Fab>
              </Grid>
            </Grid>
          )}
          <Modal
            show={show}
            onHide={handleClose}
            style={{ zIndex: "3000" }}
            dialogClassName={"myModalBodysxsse"}
            contentClassName={"myModalContent"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {t("Inventory, pricing and restriction logs")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  fontSize: "0.9rem",
                  marginTop: "1rem",
                  justifyContent: "space-between",
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor:
                      selectedFilter === "Inventory" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Inventory" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Inventory")}
                >
                  {t("Inventory")}
                </div>

                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor: selectedFilter === "Pricing" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Pricing" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Pricing")}
                >
                  {t("Pricing")}
                </div>
                <div
                  style={{
                    marginLeft: "1rem",
                    border: "1px solid #DDDDDD",
                    padding: "5px",
                    borderRadius: "25px",
                    width: "9rem",
                    textAlign: "center",
                    backgroundColor:
                      selectedFilter === "Restriction" && "#1AB393",
                    cursor: "pointer",
                    color: selectedFilter === "Restriction" && "#fff",
                  }}
                  onClick={() => setSelectedFilter("Restriction")}
                >
                  {t("Restriction")}
                </div>
              </div>

              {filterLogData?.map((e) => (
                <Card
                  className="mb-3"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  }}
                >
                  <Card.Body>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex flex-column">
                        <h5>{t("Room name")}</h5>
                        <p>{e.roomName}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("Date")}</h5>
                        <p>{e.dateRange}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("OTAs")}</h5>
                        <p>{e.channel}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5>{t("Status")}</h5>
                        <p>{e.status}</p>
                      </div>
                      <div className="d-flex flex-column">
                        <h5> {t("Updated on OTA")}</h5>
                        <p>{e.updatedTime}</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="custom-button">
                {loader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Refresh")}`
                )}
              </Button>
            </Modal.Footer>
          </Modal>
          <ConfirmationModal
            open={showConfirm}
            onClose={() => {
              setShowConfirm(false);
            }}
            onBackdropClick={closeFunc}
            title={
              DropdownOptions.find((item) => item.val === componentType).text
            }
            loading={apiCall}
            displayData={showConfirm ? getDisplayData() : null}
            confirmAction={setActionButton}
          ></ConfirmationModal>
        </Grid>
      </form>
    </Grow>
  );
};

export default GroupSetOnlineView;
